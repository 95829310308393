//Our custom MUI theme (ref - https://material-ui.com/customization/themes/#other-variables)
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

//NOTE: When adjusting colors in this file be sure to keep the
//assets/LESS/variables.less file in sync.
export const tenfourColors = {
    primary500: '#2196F3',
    primary800: '#0e59c1',
    darkBlack: 'rgba(0, 0, 0, 0.87)',
    white: 'white',
    hintText: '#9b9b9b',
    chipBackgroundColor: '#e0e0e0',
    chipDisabledBackgroundColor: '#f8f8f8',
    chipDisabledColor: '#a9a9a9',
    inputBorderColor: '#838383',
    inputLabelColor: '#838383',
    inputTextColor: '#424242',
    borderColor: '#dbdcde',
    iconColor: '#757575',
    iconDisabledColor: '#d8d8d8',
    activeItemBackground: '#f4f9ff',
    hoveredItemBackground: '#f5f5f5',
    successColor: '#4CAF50',
    errorColor: '#F44336',
    disabledColor: 'rgba(0, 0, 0, 0.12)',

    //Statuses
    early: '#0091EA',
    onTime: '#00C853',
    inJeopardy: '#FFAB00',
    late: '#D50000',
    hot: '#FF6D00',
    notTracking: '#DBECF8',
    trackingLost: '#485465',
    incompleteTracking: '#485465',

    //TODO colors will be selected by the user in the future
    userDefinedPrimary: '#002D5B',
    userDefinedSecondary: '#AEAEB6'
};

//Theme generated from primary color - http://mcg.mbitson.com/#!?mcgpalette0=%231976d2&themename=mcgtheme
const themeOptions: any = {
    typography: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        useNextVariants: true
      },
      palette: {
        type: 'light',
        primary: {
            common: {
                black: '#000',
                white: tenfourColors.white,
                transparent: 'rgba(0, 0, 0, 0)',
                fullBlack: 'rgba(0, 0, 0, 1)',
                darkBlack: tenfourColors.darkBlack,
                lightBlack: 'rgba(0, 0, 0, 0.54)',
                minBlack: 'rgba(0, 0, 0, 0.26)',
                faintBlack: tenfourColors.disabledColor,
                fullWhite: 'rgba(255, 255, 255, 1)',
                darkWhite: 'rgba(255, 255, 255, 0.87)',
                lightWhite: 'rgba(255, 255, 255, 0.54)',
                //Tack on extra TF app specific colors
                ...tenfourColors
            },
            50: '#e3effa',
            100: '#bad6f2',
            200: '#8cbbe9',
            300: '#5e9fe0',
            400: '#3c8bd9',
            500: tenfourColors.primary500,
            600: '#166ecd',
            700: '#1263c7',
            800: tenfourColors.primary800,
            900: '#0846b6',
            A100: '#e0eaff',
            A200: '#adc6ff',
            A400: '#7aa3ff',
            A700: '#6191ff',
            contrastDefaultColor: 'light'
        }
    },
    overrides: {
        MuiToolbar: {
            //NOTE: MUI's toolbar minHeight has changed in recent versions to 64. Our original design
            //requires 60. Otherwise, extra scrollbars are added. So we are going to force 60 again
            //and the next version of Nimbles app template wrapper drawer based nav it will be corrected.
            root: {
                'height': 60,
                'minHeight': '60px !important',
                'maxHeight': 60,
                '@media (min-width:0px) and (orientation: landscape)': {
                    height: 60,
                    minHeight: '60px !important',
                    maxHeight: 60
                },
                '@media (min-width:600px)': {
                    height: 60,
                    minHeight: '60px !important',
                    maxHeight: 60
                }
            }
        },
        MuiDialogTitle: {
            /* MUI follows materials dialog guidlines and doesn't have a titlebar in non-fullscreen
            dialogs. We however always want one so override here. */
            root: {
                'backgroundColor': tenfourColors.primary500,
                'fontSize': '16px !important',
                'padding': '15px 0 15px 16px',

                '& h6': {
                    color: `${tenfourColors.white} !important`,
                    fontSize: '16px !important',
                    fontWeight: 300
                }
            }
        },
        //@FIXME: Our original MuI{X}Icon style overrides need reworked now that MUI 1 is out and these components support
        //color & nativeColor properties directly. We'll rework during the custom theming project.
        MuiIcon: {
            root: {
                'color': tenfourColors.iconColor,
                //MUI doesn't add the disabled class, but we can set manually on the icons's root className
                //@TODO: disabled and disabled:hover are identical. Research how to apply as one rule in JSS.
                '&.disabled': {
                    color: tenfourColors.iconDisabledColor,
                    cursor: 'auto'
                },
                '&.disabled:hover': {
                    color: tenfourColors.iconDisabledColor,
                    cursor: 'auto'
                }
            }
        },
        MuiSvgIcon: {
            root: {
                //@FIXME: Undoing this forced color now that the color & nativeColor properties are supported on
                //icons. Forcing here negatively impacts things like checkbox colors, etc.
                //'color': tenfourColors.iconColor,
                //MUI doesn't add the disabled class, but we can set manually on the icons's root className
                //@TODO: disabled and disabled:hover are identical. Research how to apply as one rule in JSS.
                '&.disabled': {
                    color: tenfourColors.iconDisabledColor,
                    cursor: 'auto'
                },
                '&.disabled:hover': {
                    color: tenfourColors.iconDisabledColor,
                    cursor: 'auto'
                }
            }
        },
        MuiChip: {
            root: {
                'backgroundColor': tenfourColors.chipBackgroundColor,
                'fontSize': 14,
                //MUI doesn't add the disabled class, but we can set manually on the chip's root className
                //@TODO: disabled and disabled:hover are identical. Research how to apply as one rule in JSS.
                '&.disabled': {
                    backgroundColor: tenfourColors.chipDisabledBackgroundColor,
                    color: tenfourColors.chipDisabledColor
                },
                '&.disabled:hover': {
                    backgroundColor: tenfourColors.chipDisabledBackgroundColor,
                    color: tenfourColors.chipDisabledColor
                }
            }
        },
        MuiMenuItem: {
            root: {
                'padding': 8,
                'color': 'inherit',

                '&:hover': {
                    color: `${tenfourColors.darkBlack}`,
                    backgroundColor: tenfourColors.hoveredItemBackground
                },
                '&:focus': {
                    color: `${tenfourColors.darkBlack}`,
                    backgroundColor: tenfourColors.hoveredItemBackground
                },
                '&$selected': {
                    color: `${tenfourColors.darkBlack}`,
                    backgroundColor: tenfourColors.activeItemBackground
                  }
            }
        },
        MuiList: {
            root: {
                color: `${tenfourColors.darkBlack}`,
                backgroundColor: tenfourColors.white
            }
        },
        MuiListItem: {
            root: {
                color: 'inherit !important'
            },
            focusVisible: {
                color: `${tenfourColors.darkBlack}`,
                background: tenfourColors.activeItemBackground
            }
        },
        MuiFormLabel: {
            root: {
                color: `${tenfourColors.inputLabelColor}`
            },
            asterisk: {
                color: `${tenfourColors.errorColor}`
            }
        },
        MuiInputLabel: {
            root: {
                color: `${tenfourColors.inputLabelColor}`
            },
            shrink: { //Let shrink pull the label up as normal, but remove the scaling
                fontSize: 12,
                transform: 'translate(0, 1.5px) scale(1)'
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: 12
            },
            error: {
                color: `${tenfourColors.errorColor}`
            }
        },
        MuiInput: {
            root: {
                'borderRadius': '2px !important',
                'border': `1px solid ${tenfourColors.inputBorderColor}`,
                '&$focused': {
                    border: `1px solid ${tenfourColors.primary500}`
                  }
            },
            error: {
                border: `1px solid ${tenfourColors.errorColor}`
            },
            input: {
                paddingLeft: `6px !important`,
                width: '99% !important'
            }
        },
        MuiInputAdornment: {
            root: {
                '& button': {
                    fontSize: 18
                }
            }
        }
    }
};

// More on Colors: https://material-ui.com/style/color/
const mui104Theme = createMuiTheme(themeOptions);

export default mui104Theme;
