import { Notifications } from '../../interfaces/statusInterfaces';

const snackbarNotificationReducer = (notificationsData: Notifications = {
    notificationList: []
}, action: any): Notifications => {
    switch (action.type) {
        case 'ENQUEUE_SNACKBAR':
            return {
                ...notificationsData,
                notificationList: [
                    ...notificationsData.notificationList,
                    {
                        ...action.notification
                    }
                ]
            };
        case 'REMOVE_SNACKBAR':
            return {
                ...notificationsData,
                notificationList: notificationsData.notificationList.filter(
                    (notification: any): boolean => {
                        return notification.key !== action.key;
                    }
                )
            };

        default:
            return notificationsData;
    }
};

export default snackbarNotificationReducer;
