import React, { Fragment } from 'react';
import { TimePicker } from 'material-ui-time-picker';
import moment from 'moment';
import {
    FormControl,
    FormHelperText,
    IconButton,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    InputAdornment,
    TextField,
    withStyles,
    createStyles
} from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import { AccessTime, Close } from '@material-ui/icons';
import { isEmpty, isUndefined } from 'lodash';

interface ITimePickerInputProps {
    /**
     * Input name prop
     */
    name: string;
    /**
     * Input label prop
     */
    label: string;
    /**
     * Input value formatted as HH:MM (24hr mode)
     * Note: Formsy typically manages once initialized
     */
    value: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    onChange?: Function;
    /**
     * Any extra classes that need to be applied to the FormControl
     */
    formControlClass?: string;
    formHelperText?: string;
    /**
     * Any extra classes that need to be applied to the TextField which is wrapped inside the FormControl
     */
    textfieldClass?: string;
    //Props below here are automatically injected by MUI & Formsy
    showRequired?: Function;
    showError?: Function;
    getErrorMessage?: Function;
    changeValue?: Function;
    setValue?: Function;
    getValue?: Function;
    isPristine?: Function;
    classes?: any;
}

interface ITimePickerInputState {
    /**
     * Controls visilibity of the timepicker dialog
     */
    diaglogIsOpen: boolean;
    /**
     * This is the timepicker's internal value (it requires a JS Date obj)
     */
    timePickerValue: Date;
    /**
     * This holds the the selected time value while the dialog is still open.
     * The selected time is not applied until the dialog is submitted.
     */
    unconfirmedTimeValue: string;
    /**
     * When a value is set a clear icon is shown, otherwise the clock icon is displayed
     */
    showClockIcon: boolean;
}

const styles = (theme) => {
    return createStyles({
        iconButton: {
            [theme.breakpoints.between('xs', 'sm')]: {
                padding: '8px'
            }
        },
        icon: {
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: theme.typography.pxToRem(18)
            }
        },
        errorMessage: {
            color: theme.palette.primary.common.errorColor
        }
    });
};

/**
 * This is a general purpose timepicker input. It wraps MUI's TextField component, styles it according to our style
 * guide and integrates it with material-ui-time-picker & Formsy. material-ui-time-picker is a solid 3rd party MUI
 * timepicker. MUI does not currently contain date/time pickers. They simple fallback to the standard HTML 5 controls.
 *
 * Ref: https://teamwertarbyte.github.io/material-ui-time-picker/#timepicker
 *
 * NOTE: It is expected that this component is only used inside of Formsy form.
 */
class TimePickerInput extends React.Component<ITimePickerInputProps, ITimePickerInputState> {
    // Define a ctrls obj to wrap HTML control refs
    ctrls: {
        timePickerTextInput?: HTMLFormElement;
    } = {};

    constructor(props: ITimePickerInputProps) {
        super(props);

        //Validate the input value. Empty string is ok, otherwise it must be HH:mm.
        this.validateIncomingTimeStringFormat(this.props.value);

        this.state = {
            diaglogIsOpen: false,
            timePickerValue: null,
            unconfirmedTimeValue: '',
            showClockIcon: true
        };
    }

    componentDidMount() {
        const showClockIcon = this.getInputIconStateFromProps(this.props.getValue());
        this.setState({ showClockIcon });
    }

    getInputIconStateFromProps(propsToCheck) {
        let showClockIcon = false;
        if (propsToCheck === undefined || propsToCheck === null || propsToCheck === '') {
            showClockIcon = true;
        }
        return showClockIcon;
    }

    toggleDialog = () => {
        const timePickerValue = this.getTimePickerValueFromTime(this.props.getValue());
        const diaglogIsOpen = !this.state.diaglogIsOpen;
        this.setState({ timePickerValue, diaglogIsOpen });
    }

    closeDialog = () => {
        this.toggleDialog();
    }

    validateIncomingTimeStringFormat(timeValue) {
        let isValid = false;

        if (timeValue === '' || (timeValue.indexOf(':') !== -1 && timeValue.length >= 5)) {
            isValid = true;
        }

        if (isValid === false) {
            throw new Error('TimePickerInput value prop must be an empty string or a string in the format HH:mm or HH:mm:ss');
        }
    }

    /**
     * Performs the proper click action based on current state
     */
    handleInputAdornmentClick = () => {
        if (this.state.showClockIcon === true) {
            this.toggleDialog();
        } else {
            this.handleClear();
        }
    }

    /**
     * Resets the input so a new time can be selected
     */
    handleClear = () => {
        this.props.setValue('');
        this.setState({
            showClockIcon: true,
            timePickerValue: null
        });

        // If the parent component has its own onChange handler call it as well
        // Returns the selected object, not just the value.
        if (this.props.onChange) {
            this.props.onChange('');
        }
    }

    /**
     * Dialog confirmation callback
     */
    handleDialogConfirmation = () => {
        this.props.setValue(this.state.unconfirmedTimeValue);
        const timePickerValue = this.getTimePickerValueFromTime(this.state.unconfirmedTimeValue);
        this.setState({
            timePickerValue,
            unconfirmedTimeValue: '', //cleanup
            diaglogIsOpen: false,
            showClockIcon: false
        });

        // If the parent component has its own onChange handler call it as well
        // Returns the selected object, not just the value.
        if (this.props.onChange) {
            this.props.onChange(timePickerValue);
        }
    }

    /**
     * Each time the user selects a different time this method will update state to capture
     * the pending update in HH:mm format.
     */
    handleTimeSelected = (time: Date) => {
        const hours = moment(time).format('HH:mm'); //24hr format
        this.setState({ unconfirmedTimeValue: hours });
    }

    handleInputTimeChange = (e) => {
        const timePickerValue = e.currentTarget.value;
        const showClockIcon = timePickerValue === '';

        this.props.setValue(timePickerValue);

        this.setState({
            showClockIcon
        });

        // If the parent component has its own onChange handler call it as well
        // Returns the selected object, not just the value.
        if (this.props.onChange) {
            this.props.onChange(timePickerValue);
        }
    }

    /**
     * Renders the search icon or the close icon with appropriate click handler
     * based on the current search term state.
     */
    renderClockIcon() {
        return (
            <InputAdornment position='end'>
                <IconButton
                    disabled={!isUndefined(this.props.disabled) ? this.props.disabled : false}
                    onClick={this.handleInputAdornmentClick}
                    className={this.props.classes.iconButton}
                >
                    {this.state.showClockIcon ? <AccessTime className={this.props.classes.icon} /> : <Close className={this.props.classes.icon} />}
                </IconButton>
            </InputAdornment>
        );
    }

    /**
     * The input value and display value are simple HH:mm strings. material-ui-time-picker requires
     * its input value be a JS Date obj. This method handles those conversions as needed.
     */
    getTimePickerValueFromTime(timeValue: string) {
        //The input is a string, but we need a date to do hr and min operations. The date part
        //just uses the current date and will be utlimate be ignored anyway.
        const timeValueAsDate = new Date();
        if (!isEmpty(timeValue) && timeValue.indexOf(':') > -1) {
            const timeParts = timeValue.split(':');
            timeValueAsDate.setHours(parseInt(timeParts[0], 10));
            timeValueAsDate.setMinutes(parseInt(timeParts[1], 10));
        }

        return timeValueAsDate;
    }

    renderFormHelperTextOrErrorMessageIfNeeded() {
        let itemToRender;
        const errorMessage = this.props.getErrorMessage();

        if (errorMessage) {
            itemToRender = <FormHelperText className={this.props.classes.errorMessage}>{errorMessage}</FormHelperText>;
        } else if (this.props.formHelperText) {
            itemToRender = <FormHelperText>{this.props.formHelperText}</FormHelperText>;
        } else {
            itemToRender = null;
        }

        return itemToRender;
    }

    render() {
        //Set a specific className based on the validation state of this component.
        //showRequired() is true when the value is empty and the required prop is
        //passed to the input. showError() is true when the value typed is invalid.
        let formControlClasses = this.props.showRequired() ? 'required' : this.props.showError() ? 'error' : null;

        if (this.props.formControlClass) {
            //Add on any extra parent component classes for the form control
            formControlClasses += ` ${this.props.formControlClass}`;
        }

        return (
            <Fragment>
                <FormControl
                    className={`TimePickerWrapper ${formControlClasses}`}
                    required={this.props.required !== undefined ? this.props.required : false}
                    disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                    //fullWidth={true}
                    //fullWidth ends up being slightly longer than normal when an endAdornment is given so
                    //pull it back a touch here. To control the width wrap TimePickerInput in another div
                    //amd set width on that element as needed. TimePickerInput will be 100% inside it.
                    style={{ width: 'calc(100% - 2px)', display: 'flex' }} //display: flex needed for ie11
                >
                    <TextField
                        className={this.props.textfieldClass || null}
                        label={this.props.label}
                        name={this.props.name}
                        autoComplete='off'
                        required={this.props.required !== undefined ? this.props.required : false}
                        disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                        //Often we get NULLs back from the db and try to restore them into form fields,
                        //but the TextField control does not support null so convert if needed.
                        value={this.props.value !== null ? this.props.getValue() : ''}
                        placeholder={this.props.placeholder !== undefined ? this.props.placeholder : ''}
                        fullWidth={true}
                        onChange={this.handleInputTimeChange}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: this.renderClockIcon()
                        }}
                        InputLabelProps={{
                            disableAnimation: true,
                            shrink: true
                        }}
                    />
                    {this.renderFormHelperTextOrErrorMessageIfNeeded()}
                </FormControl>

                <Dialog
                    maxWidth='md'
                    open={this.state.diaglogIsOpen}
                    onClose={this.closeDialog}
                >
                    <DialogContent
                        style={{ width: 288, padding: 0 }}
                    >
                        <TimePicker
                            mode='24h' //It supports 12hr, but all current fpnext requirements state only 24h is needed
                            value={this.state.timePickerValue}
                            onChange={(time: Date) => this.handleTimeSelected(time)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.closeDialog}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={isEmpty(this.state.unconfirmedTimeValue)}
                            onClick={this.handleDialogConfirmation}
                            color='primary'
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        );
    }
}

export default withFormsy(withStyles(styles)(TimePickerInput));
