import {
    IFixedLocationPoint,
    IFixedLocationDetails,
    IFixedLocationOperatingHours,
    ILoadTimes
} from '../interfaces/locationManagementInterfaces';
import { FixedLocationTypes } from '../metadata/locationManagementMetadata';
import { isNull, isUndefined, isObject } from 'lodash';

class FixedLocation implements IFixedLocationDetails {
    fixedLocationCode: string = null;
    fixedLocationType: any = FixedLocationTypes[1]; //number | IFixedLocationTypeOption (default to Generic)
    fixedLocationName: string = null;
    streetAddressLine1: string = null;
    streetAddressLine2: string = null;
    cityName: string = null;
    stateCode: string = null;
    postalCode: string = null;
    countryCode: string = null;
    organizationReferenceCode: string = null;
    geofenceRadiusInFeet: number = null;
    geofencePolygon: GeoJSON.Feature<any> = null;
    overrideDeliveryStatusFlag: boolean = null;
    earlyShipmentStatusMinutes: number = null;
    onTimeShipmentStatusMinutes: number = null;
    inJeopardyShipmentStatusMinutes: number = null;
    lateShipmentStatusMinutes: number = null;
    hasEarlyStatusDefined: boolean = null;
    hasInJeopardyStatusDefined: boolean = null;
    dwellTimeMinutes: number = null;
    fixedLocationPoint: IFixedLocationPoint = {
        latitude: null,
        longitude: null
    };
    operatingHours: IFixedLocationOperatingHours[] = [];
    dwellTimeBufferMinutes: number = null;
    loadTimes: ILoadTimes[] = [];
    palletLoadTotalMinutes: number = null;

    //A object comforming to IFixedLocationDetails can be passed in to initialize a
    //new object here (e.g.) restoring a FL form for example
    constructor(fixedLocationDetails?: IFixedLocationDetails) {
        //If provided restore existing fixedLocationDetails, otherwise it defaults to new (NULLed) fixedLocation
        if (!isUndefined(fixedLocationDetails) && !isNull(fixedLocationDetails)) {

            for (const key of Object.keys(fixedLocationDetails)) {
                //Skip any properties not defined on the object
                if (this[key] !== undefined) {
                    this[key] = fixedLocationDetails[key];
                }
            }
        }
    }

    //The API always expects a full IFixedLocationDetails obj for POST & PUT calls. The UI is chunked up
    //into a number of forms that allow partial obj creation/update. To support this we allow the forms
    //to pass back partial IFixedLocationDetails-like objects which we in turn use to update just the
    //necessary props on editableFixedLocation.
    partialPropUpdate(partialFixedLocationObj) {
        for (const key of Object.keys(partialFixedLocationObj)) {
            if (!isUndefined(this[key])) {
                this[key] = partialFixedLocationObj[key];
            }
        }
    }

    //Dump the obj as JSON to easily hand off to API methods like updateFixedLocation, createFixedLocation, etc
    toJSON(): IFixedLocationDetails {
        const fixedLocationDetails: any = {};
        for (const [key, val] of Object.entries(this)) {
            //Formsy expects form inputs to be empty strings not nulls so these values have
            //potentially been converted and used in one of the forms. Reverse here for the API as needed.
            fixedLocationDetails[key] = (val === null || val.toString().trim() === '') ? null : val;
        }

        //If the user has updated the fixedLocationType dropdown the new value will be the select obj and
        //not just the number as returned from the db. Convert back here for API requests to save properly.
        if (isObject(fixedLocationDetails.fixedLocationType)) {
            fixedLocationDetails.fixedLocationType = fixedLocationDetails.fixedLocationType.value;
        }

        return fixedLocationDetails as IFixedLocationDetails;
    }
}

export {
    FixedLocation
};
