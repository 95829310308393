import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import {
    DetailViewModes,
    CreateModeStepDirections
} from '../../../metadata/locationManagementMetadata';
import {
    IFixedLocationDetails,
    ICreateModeFormStepDetails
} from '../../../interfaces/locationManagementInterfaces';
import LocationFormContainer from './locationFormContainer';
import OperatingHoursFormContainer from './operatingHoursFormContainer';

const styles = () => ({
    detailsFormWrapper: {
        paddingTop: 20,
        paddingRight: 4,
        paddingLeft: 4,
        width: '100%' //Needed for ie11
    }
});

interface DetailsTabProps {
    detailViewMode: DetailViewModes;
    createModeCrtStepFormStates: ICreateModeFormStepDetails; //CreateModeFormStatesByStep[stepX]
    createModeLatestStepDirection: CreateModeStepDirections;
    createModeChangeStep: Function;
    isSavingFixedLocation: boolean;
    updateEditableFixedLocation: Function;
    editableFixedLocation: IFixedLocationDetails;
    formDirtyStatuses: any;
    setFormIsDirty: Function;
    cancelForm: Function;
    changeTab: Function;
    isMobile: boolean;
    classes?: any;
}

class DetailsTab extends React.Component<DetailsTabProps, {}> {

    render() {
        return (
            <Grid
                className='fill-height'
                container={true}
                alignItems='center'
            >
                <Grid
                    className={`${this.props.classes.detailsFormWrapper} fill-height`}
                    item={true}
                    xs={12}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={6}
                >
                    <LocationFormContainer
                        isSavingFixedLocation={this.props.isSavingFixedLocation}
                        editableFixedLocation={this.props.editableFixedLocation}
                        detailViewMode={this.props.detailViewMode}
                        createModeCrtStepFormStates={this.props.createModeCrtStepFormStates}
                        createModeLatestStepDirection={this.props.createModeLatestStepDirection}
                        createModeChangeStep={this.props.createModeChangeStep}
                        updateEditableFixedLocation={this.props.updateEditableFixedLocation}
                        formDirtyStatuses={this.props.formDirtyStatuses}
                        setFormIsDirty={this.props.setFormIsDirty}
                        cancelForm={this.props.cancelForm}
                        changeTab={this.props.changeTab}
                        isMobile={this.props.isMobile}
                    />

                    <OperatingHoursFormContainer
                        isSavingFixedLocation={this.props.isSavingFixedLocation}
                        editableFixedLocation={this.props.editableFixedLocation}
                        detailViewMode={this.props.detailViewMode}
                        createModeCrtStepFormStates={this.props.createModeCrtStepFormStates}
                        createModeLatestStepDirection={this.props.createModeLatestStepDirection}
                        createModeChangeStep={this.props.createModeChangeStep}
                        updateEditableFixedLocation={this.props.updateEditableFixedLocation}
                        setFormIsDirty={this.props.setFormIsDirty}
                        cancelForm={this.props.cancelForm}
                        changeTab={this.props.changeTab}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(DetailsTab);
