import React from 'react';
import Formsy from 'formsy-react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ExpansionPanelActions,
    Typography,
    Grid,
    Divider,
    Button,
    FormControl,
    withStyles
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, AddLocation as AddLocationIcon } from '@material-ui/icons';
import isValidCoordinates from 'is-valid-coordinates';

import SelectInput from '../../nimble/inputs/selectInput';
import TextInput from '../../nimble/inputs/textInput';
import { restoreFormValuesFromObject, determineIfFormIsDirty } from '../../../custom_modules/formUtils';

import {
    IFixedLocationDetails,
    ICreateModeFormStepDetails
} from '../../../interfaces/locationManagementInterfaces';
import {
    DetailViewModes,
    FormNames,
    CreateModeStepDirections,
    FixedLocationTypes
} from '../../../metadata/locationManagementMetadata';
import PolygonResetDialog from './polygonResetDialog';
import AddressLookupDialog from '../listPane/addressLookupDialog';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 16,
        paddingRight: 16
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.primary.common.inputLabelColor,
        marginLeft: 10
    },
    formControl: {
        marginBottom: 32
    },
    formControlSplitRowClassBottom: {
        marginBottom: 40
    },
    addressLookupText: {
        fontSize: theme.typography.pxToRem(18)
    },
    outlinedAddressLookupButton: {
        marginTop: 20,
        border: `2px solid ${theme.palette.primary[500]}`
    },
    outlinedAddressLookupButtonLeftIcon: {
        marginRight: theme.spacing.unit,
        color: `${theme.palette.primary[500]}`
    }
});

interface LocationFormContainerProps {
    isSavingFixedLocation: boolean;
    editableFixedLocation: IFixedLocationDetails;
    detailViewMode: DetailViewModes;
    createModeCrtStepFormStates: ICreateModeFormStepDetails; //CreateModeFormStatesByStep[stepX]
    createModeLatestStepDirection: CreateModeStepDirections;
    createModeChangeStep: Function;
    updateEditableFixedLocation: Function;
    formDirtyStatuses: any;
    setFormIsDirty: Function;
    cancelForm: Function;
    changeTab: Function;
    isMobile: boolean;
    classes?: any;
}

interface LocationFormContainerState {
    canSubmitForm: boolean;
    formIsExpanded: boolean;
    addressLookupDialogIsOpen: boolean;
    polygonResetDialogIsOpen: boolean;
}

class LocationFormContainer extends React.Component<LocationFormContainerProps, LocationFormContainerState> {
    static formName = FormNames.fixedLocationDetailsForm; //Must exist in redux locationManagement.formDirtyStatuses

    // Define a ctrls obj to wrap HTML control refs
    ctrls: {
        fixedLocationDetailsForm?: HTMLFormElement;
        fixedLocationCode?: HTMLFormElement;
        fixedLocationType?: HTMLFormElement;
        fixedLocationName?: HTMLFormElement;
        streetAddressLine1?: HTMLFormElement;
        streetAddressLine2?: HTMLFormElement;
        cityName?: HTMLFormElement;
        stateCode?: HTMLFormElement;
        postalCode?: HTMLFormElement;
        countryCode?: HTMLFormElement;
        fixedLocationPoint?: {
            latitude?: HTMLFormElement;
            longitude?: HTMLFormElement;
        }
    } = {};

    constructor(props: LocationFormContainerProps) {
        super(props);

        this.state = {
            canSubmitForm: false,
            formIsExpanded: true,
            addressLookupDialogIsOpen: false,
            polygonResetDialogIsOpen: false
        };

        //We need to init this one ctrl or the ref assignment in the form will fail because
        //fixedLocationPoint doesn't exist when trying to directly assign into a sub prop.
        this.ctrls.fixedLocationPoint = {};
    }

    componentDidMount() {
        //componentDidMount will fire when they first load a FL for editing or creating. It will be fired again if they come
        //back from a previous step in the create flow.
        if (this.props.editableFixedLocation !== null) {
            restoreFormValuesFromObject(this.ctrls.fixedLocationDetailsForm, this.ctrls, this.props.editableFixedLocation);
        }

        if (this.props.detailViewMode === DetailViewModes.CREATE && this.props.createModeLatestStepDirection === CreateModeStepDirections.previous) {
            //By default the form is pristine and not submittable. We want the user to be allowed to advance again
            //using the next button without requiring them to change the form.
            this.setState({ canSubmitForm: true });
            this.props.setFormIsDirty(LocationFormContainer.formName, true);
        }

        if (this.props.detailViewMode === DetailViewModes.CREATE) {
            this.setCreateModeExpandedStateFromProps(this.props);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.detailViewMode !== DetailViewModes.CREATE) {
            //Only reset the form and restore if the newly selected FLC doesn't match the previous one
            if (nextProps.editableFixedLocation !== null && (this.props.editableFixedLocation === null
                || (nextProps.editableFixedLocation.fixedLocationCode !== this.props.editableFixedLocation.fixedLocationCode))) {
                this.ctrls.fixedLocationDetailsForm.resetModel();
                restoreFormValuesFromObject(this.ctrls.fixedLocationDetailsForm, this.ctrls, nextProps.editableFixedLocation);
            }
        }

        if (nextProps.detailViewMode === DetailViewModes.CREATE) {
            this.setCreateModeExpandedStateFromProps(nextProps);
        }
    }

    getFixedLocationTypeSelectOptions() {
        let filteredFixedLocationTypeSelectOptions;
        if (this.props.detailViewMode === DetailViewModes.CREATE) {
            //The "Unknown" option is needed for existing records, but should not be something the user can select
            filteredFixedLocationTypeSelectOptions = FixedLocationTypes.filter((fixedLocationTypeSelectOption) => {
                if (fixedLocationTypeSelectOption.value !== 'Unknown') {
                    return { value: fixedLocationTypeSelectOption.value, label: fixedLocationTypeSelectOption.label };
                } else {
                    return false;
                }
            });
        } else {
            filteredFixedLocationTypeSelectOptions = FixedLocationTypes.map((fixedLocationType) => {
                return { value: fixedLocationType.value, label: fixedLocationType.label };
            });
        }

        return filteredFixedLocationTypeSelectOptions;
    }

    toggleFormSubmitButton = (isEnabled: boolean) => {
        const formIsDirty = this.props.formDirtyStatuses[LocationFormContainer.formName];
        this.setState({ canSubmitForm: isEnabled && formIsDirty });
    }

    submitForm(formModel) {
        this.props.setFormIsDirty(LocationFormContainer.formName, false);

        //If in "edit" mode updateEditableFixedLocation also does updates to the API. When
        //in "create" mode we only update editableFixedLocation. A sep call will be made to
        //do the initial FL save/create once the create flow is complete.

        //Lat/lng will come out of the form as strings, but must be submitted to the API as numbers.
        formModel.fixedLocationPoint.latitude = (formModel.fixedLocationPoint.latitude === null) ? null : Number(formModel.fixedLocationPoint.latitude);
        formModel.fixedLocationPoint.longitude = (formModel.fixedLocationPoint.longitude === null) ? null : Number(formModel.fixedLocationPoint.longitude);

        this.props.updateEditableFixedLocation(formModel);
        if (this.props.detailViewMode === DetailViewModes.CREATE) {
            this.props.createModeChangeStep(CreateModeStepDirections.next);
            this.setState({ formIsExpanded: false });
        }
    }

    handleFormChange = (currentValues, isChanged) => {
        if (isChanged === true) {
            //We need to manage the form isDirty flag here so that the Prompt component can
            //warn users before they lose any unsaved form data.
            const isDirty = determineIfFormIsDirty(currentValues, this.props.editableFixedLocation);
            this.props.setFormIsDirty(LocationFormContainer.formName, isDirty);
        }
    }

    toggleAddressLookupDialog(isOpen: boolean) {
        this.setState({ addressLookupDialogIsOpen: isOpen });
    }

    handleAddressLookupButtonClick = () => {
        this.toggleAddressLookupDialog(true);
    }

    handleAddressLookupDialogClose = () => {
        this.toggleAddressLookupDialog(false);
    }

    handleAddressLookupDialogConfirmation = (selectedAddressDetails) => {
        //We'll use the selected address to autopopulate the form. If previous values
        //existing in the address fields they will be cleared and replaced now.
        this.ctrls.streetAddressLine1.setValue(selectedAddressDetails.address);
        this.ctrls.streetAddressLine2.setValue(''); //Clear it, not parsed separately from addr1
        this.ctrls.cityName.setValue(selectedAddressDetails.city);
        this.ctrls.stateCode.setValue(selectedAddressDetails.stateShort);
        this.ctrls.countryCode.setValue(selectedAddressDetails.countryShort);
        this.ctrls.postalCode.setValue(selectedAddressDetails.zipCode);
        this.ctrls.fixedLocationPoint.latitude.setValue(selectedAddressDetails.lat);
        this.ctrls.fixedLocationPoint.longitude.setValue(selectedAddressDetails.lng);

        //close the modal once we have set the formy values
        this.handleAddressLookupDialogClose();
    }

    setCreateModeExpandedStateFromProps(propsToCheck) {
        let formShouldBeExpanded: boolean;
        if (propsToCheck.createModeCrtStepFormStates.formName === LocationFormContainer.formName) {
            formShouldBeExpanded = true;
        } else {
            formShouldBeExpanded = false;
        }

        if (this.state.formIsExpanded !== formShouldBeExpanded) {
            this.setState({ formIsExpanded: formShouldBeExpanded });
        }
    }

    handleExpansionPanelChange = (event, expanded) => {
        //Users can only toggle the expanded state when in edit mode. Create mode controls this state internally.
        if (this.props.detailViewMode === DetailViewModes.EDIT) {
            this.setState({
                formIsExpanded: expanded
            });
        }
    }

    togglePolygonResetDialog(isOpen: boolean) {
        this.setState({ polygonResetDialogIsOpen: isOpen });
    }

    //They cancelled the save after the warning, lets revert the forms address to the previous value
    handlePolygonResetDialogCancel = () => {
        this.togglePolygonResetDialog(false);
    }

    handlePolygonResetDialogConfirmation = () => {
        this.togglePolygonResetDialog(false);
        this.props.editableFixedLocation.geofencePolygon = null;
        this.props.editableFixedLocation.geofenceRadiusInFeet = 53; //0.01 mile default
        this.submitForm(this.ctrls.fixedLocationDetailsForm.getModel());
    }

    handleEditWithCustomPolygon(model) {
        //Before we can do the direct comparison we need to hand the null to empty string form conversion
        const crtFormLat = (model.fixedLocationPoint.latitude === '') ? null : Number(model.fixedLocationPoint.latitude);
        const crtFormLng = (model.fixedLocationPoint.longitude === '') ? null : Number(model.fixedLocationPoint.longitude);

        if ((this.props.editableFixedLocation.fixedLocationPoint.latitude !== crtFormLat)
            || this.props.editableFixedLocation.fixedLocationPoint.longitude !== crtFormLng) {
            this.togglePolygonResetDialog(true);
        } else {
            //Something other than lat/lng was change so just submit as normal
            this.submitForm(model);
        }
    }

    renderForm() {
        const { classes } = this.props;
        const disabled = this.props.isSavingFixedLocation
            || (this.props.detailViewMode === DetailViewModes.CREATE && this.props.createModeCrtStepFormStates.formName !== LocationFormContainer.formName);

        //Normally each form control has a standard amount of padding between rows, but when we split a row for desktop
        //it results on the mobile version applying to much padding when the controls snap back to 12.
        const formControlSplitRowClassTop = (this.props.isMobile === true) ? null : classes.formControl;
        const formControlSplitRowClassBottom = (this.props.isMobile === true) ? classes.formControlSplitRowClassBottom : classes.formControl;

        return (
            <div className={classes.root}>
                <Formsy
                    name={LocationFormContainer.formName}
                    ref={(f) => this.ctrls.fixedLocationDetailsForm = f}
                    disabled={disabled}
                    onChange={(currentValues, isChanged) => {
                        this.handleFormChange(currentValues, isChanged);
                    }}
                    onValidSubmit={(model) => {
                        //Extra confimation is required when editing with a geofence polygon in place
                        if (this.props.detailViewMode === DetailViewModes.EDIT && (this.props.editableFixedLocation.geofencePolygon !== null)) {
                            this.handleEditWithCustomPolygon(model);
                        } else {
                            this.submitForm(model);
                        }
                    }}
                    onValid={() => {
                        this.toggleFormSubmitButton(true);
                    }}
                    onInvalid={() => {
                        this.toggleFormSubmitButton(false);
                    }}
                    autoComplete='off'
                >

                    <Grid
                        className={'fill-height'}
                        container={true}
                        direction='column'
                    >

                        <Grid container={true} direction='row'>
                            <Grid item={true} xs={12}>
                                <TextInput
                                    ref={(i) => this.ctrls.fixedLocationName = i}
                                    formControlClass={classes.formControl}
                                    label='Fixed Location Name'
                                    name='fixedLocationName'
                                    required={true}
                                    value={''}
                                    validations='maxLength:50'
                                    validationErrors={{
                                        maxLength: 'The max length is 50 characters'
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container={true} direction='row' spacing={40}>
                            <Grid
                                item={true}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <TextInput
                                    ref={(i) => this.ctrls.fixedLocationCode = i}
                                    formControlClass={formControlSplitRowClassTop}
                                    label='Fixed Location Code'
                                    name='fixedLocationCode'
                                    required={true}
                                    disabled={this.props.detailViewMode === DetailViewModes.EDIT}
                                    value={''}
                                    validations='maxLength:50'
                                    validationErrors={{
                                        maxLength: 'The max length is 50 characters'
                                    }}
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <SelectInput
                                    ref={(i) => this.ctrls.fixedLocationType = i}
                                    name='fixedLocationType'
                                    label='Fixed Location Type'
                                    formControlClass={formControlSplitRowClassBottom}
                                    options={this.getFixedLocationTypeSelectOptions()}
                                    required={true}
                                    searchable={false}
                                    disabled={this.props.detailViewMode === DetailViewModes.EDIT}
                                />
                            </Grid>
                        </Grid>

                        <Grid container={true} direction='row'>
                            <Grid item={true} xs={12}>
                                <Typography>
                                    In order to create a location and provide services, we
                                    require Latitude &amp; Longitude. If you do not know this information,
                                    you can try an address lookup to verify.
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={12}>
                                <FormControl style={{ marginBottom: 32 }}>
                                    <Button
                                        className={classes.outlinedAddressLookupButton}
                                        variant='text'
                                        size='small'
                                        color='primary'
                                        onClick={this.handleAddressLookupButtonClick}
                                    >
                                        <AddLocationIcon className={classes.outlinedAddressLookupButtonLeftIcon} />
                                        ADDRESS LOOKUP
                                    </Button>

                                    <AddressLookupDialog
                                        dialogIsOpen={this.state.addressLookupDialogIsOpen}
                                        onDialogClose={this.handleAddressLookupDialogClose}
                                        onAddressConfirmation={this.handleAddressLookupDialogConfirmation}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container={true} direction='row'>
                            <Grid item={true} xs={12}>
                                <TextInput
                                    ref={(i) => this.ctrls.streetAddressLine1 = i}
                                    formControlClass={classes.formControl}
                                    label='Address 1'
                                    name='streetAddressLine1'
                                    required={false}
                                    value={''}
                                    validations='maxLength:500'
                                    validationErrors={{
                                        maxLength: 'The max length is 500 characters'
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container={true} direction='row'>
                            <Grid item={true} xs={12}>
                                <TextInput
                                    ref={(i) => this.ctrls.streetAddressLine2 = i}
                                    formControlClass={classes.formControl}
                                    label='Address 2'
                                    name='streetAddressLine2'
                                    required={false}
                                    value={''}
                                    validations='maxLength:500'
                                    validationErrors={{
                                        maxLength: 'The max length is 500 characters'
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container={true} direction='row' spacing={40}>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextInput
                                    ref={(i) => this.ctrls.cityName = i}
                                    formControlClass={formControlSplitRowClassTop}
                                    label='City'
                                    name='cityName'
                                    required={false}
                                    value={''}
                                    validations='maxLength:50'
                                    validationErrors={{
                                        maxLength: 'The max length is 50 characters'
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextInput
                                    ref={(i) => this.ctrls.stateCode = i}
                                    formControlClass={formControlSplitRowClassBottom}
                                    label='State'
                                    name='stateCode'
                                    required={false}
                                    value={''}
                                    validations='maxLength:10'
                                    validationErrors={{
                                        maxLength: 'The max length is 10 characters'
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container={true} direction='row' spacing={40}>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextInput
                                    ref={(i) => this.ctrls.postalCode = i}
                                    formControlClass={formControlSplitRowClassTop}
                                    label='Postal Code'
                                    name='postalCode'
                                    required={false}
                                    value={''}
                                    validations={{
                                        matchRegexp: /^[\s|A-Z|0-9|-]+$/,
                                        maxLength: 10
                                    }}
                                    validationErrors={{
                                        matchRegexp: 'Invalid character entered',
                                        maxLength: 'The max length is 10 characters'
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextInput
                                    ref={(i) => this.ctrls.countryCode = i}
                                    formControlClass={formControlSplitRowClassBottom}
                                    label='Country'
                                    name='countryCode'
                                    required={false}
                                    value={''}
                                    validations='maxLength:10'
                                    validationErrors={{
                                        maxLength: 'The max length is 10 characters'
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container={true} direction='row' spacing={40}>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextInput
                                    ref={(i) => this.ctrls.fixedLocationPoint.latitude = i}
                                    formControlClass={formControlSplitRowClassTop}
                                    label='Latitude'
                                    name='fixedLocationPoint.latitude'
                                    required={true}
                                    value={''}
                                    validations={{
                                        isValidLatitude: (values, value) => {
                                            const val = Number(value);
                                            return isValidCoordinates.latitude(val);
                                        }
                                    }}
                                    validationErrors={{
                                        isValidLatitude: 'Must be a valid latitude'
                                    }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextInput
                                    ref={(i) => this.ctrls.fixedLocationPoint.longitude = i}
                                    formControlClass={formControlSplitRowClassBottom}
                                    label='Longitude'
                                    name='fixedLocationPoint.longitude'
                                    required={true}
                                    value={''}
                                    validations={{
                                        isValidLongitude: (values, value) => {
                                            const val = Number(value);
                                            return isValidCoordinates.longitude(val);
                                        }
                                    }}
                                    validationErrors={{
                                        isValidLongitude: 'Must be a valid longitude'
                                    }}
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Formsy>

                <PolygonResetDialog
                    dialogIsOpen={this.state.polygonResetDialogIsOpen}
                    onCancel={this.handlePolygonResetDialogCancel}
                    onConfirm={this.handlePolygonResetDialogConfirmation}
                />
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        const fixedLocationCode = (this.props.editableFixedLocation === null || this.props.editableFixedLocation.fixedLocationCode === null) ? '' : this.props.editableFixedLocation.fixedLocationCode;
        const expansionPanelActionsSubmitButtonLabel = (this.props.detailViewMode === DetailViewModes.CREATE) ? 'NEXT' : 'SAVE';

        return (
            <ExpansionPanel
                expanded={this.state.formIsExpanded}
                style={{ marginBottom: 20 }}
                onChange={this.handleExpansionPanelChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container={true} direction='row' zeroMinWidth={true}>
                        <Grid item={true} xs={6}>
                            <Typography className={classes.heading}>Location</Typography>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Typography noWrap={true} className={classes.secondaryHeading}>{fixedLocationCode}</Typography>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {this.renderForm()}
                </ExpansionPanelDetails>

                <Divider />

                <ExpansionPanelActions>
                    <Button
                        size='small'
                        onClick={(event) => {
                            this.props.cancelForm(event);
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        size='small'
                        color='primary'
                        type='submit'
                        onClick={() => {
                            //Normally a button type of submit would do this without us calling manually,
                            //but since this button lives outside of the form due to the expansion panel
                            //structure we need to trigger it manually.
                            this.ctrls.fixedLocationDetailsForm.submit();
                        }}
                        disabled={!this.state.canSubmitForm}
                    >
                        {expansionPanelActionsSubmitButtonLabel}
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(LocationFormContainer);
