/* This module serves as the base module for app http requests. Often used by apiRequests. */
import * as queryString from 'query-string';

enum RequestMethodTypes {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE'
}

enum ContentTypes {
    JSON = 'application/json',
    FORM = 'application/x-www-form-urlencoded; charset=UTF-8',
    XML = 'application/xml'
}

export interface ICustomHeaders {
    headerKey: string;
    headerValue: any;
}
//Returns the clients browser date in a format the BE expects
//NOTE: Mimics moment().format('YYYY/MM/DD'), didn't want to bring in all of moment to do that
function getXClientDate() {
    const d = new Date();
    const year = d.getFullYear();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('/');
}

function invoke(url: string, method: RequestMethodTypes, body?: {}, contentType?: ContentTypes, acceptType?: ContentTypes, bearerAuthToken?: string, customHeaders?: ICustomHeaders[]): Promise<any> {
    const requestParams: any = {
        method,
        mode: 'cors',
        headers: {},
        credentials: 'include'
    };

    //Custom header for the backend to pickup the client date as needed
    requestParams.headers['X-CLIENT-DATE'] = getXClientDate();

    //Any other custom headers the request requires
    if (Array.isArray(customHeaders)) {
        customHeaders.forEach((customHeader) => {
            requestParams.headers[customHeader.headerKey] = customHeader.headerValue;
        });
    }


    if (body) {
        //Automatically stringify JSON data, but avoid doing others like
        requestParams.body = (contentType === ContentTypes.JSON) ? JSON.stringify(body) : body;
    }
    if (contentType) { requestParams.headers['Content-Type'] = contentType; }
    if (acceptType) { requestParams.headers.Accept = acceptType; }
    if (bearerAuthToken) { requestParams.headers.Authorization = 'Bearer ' + bearerAuthToken; }

    return fetch(url, requestParams);
}

// TODO: These take enough params now that we should probably update to take an object
const Requests = {
    get(url: string, contentType?: ContentTypes, acceptType?: ContentTypes, bearerAuthToken?: string, customHeaders?: ICustomHeaders[]): Promise<any> {
        return invoke(url, RequestMethodTypes.GET, null, contentType, acceptType, bearerAuthToken, customHeaders);
    },

    post(url: string, body?: {}, contentType?: ContentTypes, acceptType?: ContentTypes, bearerAuthToken?: string, customHeaders?: ICustomHeaders[]): Promise<any> {
        return invoke(url, RequestMethodTypes.POST, body, contentType, acceptType, bearerAuthToken, customHeaders);
    },

    put(url: string, body?: {}, contentType?: ContentTypes, acceptType?: ContentTypes, bearerAuthToken?: string, customHeaders?: ICustomHeaders[]): Promise<any> {
        return invoke(url, RequestMethodTypes.PUT, body, contentType, acceptType, bearerAuthToken, customHeaders);
    },

    patch(url: string, body?: {}, contentType?: ContentTypes, acceptType?: ContentTypes, bearerAuthToken?: string, customHeaders?: ICustomHeaders[]): Promise<any> {
        return invoke(url, RequestMethodTypes.PATCH, body, contentType, acceptType, bearerAuthToken, customHeaders);
    },

    delete(url: string, contentType?: ContentTypes, acceptType?: ContentTypes, bearerAuthToken?: string, customHeaders?: ICustomHeaders[]): Promise<any> {
        return invoke(url, RequestMethodTypes.DELETE, null, contentType, acceptType, bearerAuthToken, customHeaders);
    },

    //Expects the search portion of the url as input (e.g.) this.props.location.search
    getQueryPropsFromUrl(locationSearch: string) {
        const parsedQueryParams = queryString.parse(locationSearch);
        return parsedQueryParams;
    },

    //Turns a JavaScript object of k/v pairs into a URL query string
    toQueryString(paramsObject: {}): string {
        return Object
            .keys(paramsObject)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
            .join('&');
    }
};

export {
    ContentTypes,
    Requests
};
