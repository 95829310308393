import React, { Fragment } from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';

interface IAppLoadingErrorProps {
    classes?: any;
}

const styles = (): StyleRules => {
    return createStyles({
        appBar: {
            backgroundColor: '#002D5B',
            boxShadow: 'none'
        },
        errorWrapper: {
            margin: '16px'
        },
        errorMesssage: {
            paddingTop: '16px',
            paddingBottom: '16px'
        }
    });
};

const AppLoadingError = (props: IAppLoadingErrorProps) => {
    return (
        <Fragment>
            <AppBar position='static' className={props.classes.appBar}>
                <Toolbar />
            </AppBar>
            <div className={props.classes.errorWrapper}>
                <h2>Initialization Error</h2>
                <Typography className={props.classes.errorMesssage}>A failure occurred while locating one of the required services. Please try again.</Typography>
            </div>
        </Fragment>
    );
};

export default withStyles(styles)(AppLoadingError);
