import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
    return <Slide direction='up' {...props} />;
}

interface PolygonResetDialogProps {
    dialogIsOpen: boolean;
    onCancel: any; //Function;
    onConfirm: any; //Function;
}

class PolygonResetDialog extends React.Component<PolygonResetDialogProps, {}> {
    render() {
        return (
            <Dialog
                open={this.props.dialogIsOpen}
                TransitionComponent={Transition}
                onClose={this.props.onCancel}
                //Since we'll revert form values we want to make then acknowledge
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
            >
                <DialogTitle>Custom Geofence Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <span style={{ marginTop: 16, display: 'inline-block' }}>
                        Changing the latitude or longitude in a Fixed Location will revert your custom polygon geofence to a default
                         radius geofence. Would you like to continue or cancel your changes?<br /><br />
                             NOTE: You will have the ability to draw a new polygon within the Settings tab of the Fixed Location once
                             your changes have been saved.
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    <Button onClick={this.props.onConfirm} color='primary'>Continue</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default PolygonResetDialog;
