import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { Paper } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import mui104Theme from './assets/theme/mui104Theme';
import PageNotFound from './components/nimble/pageNotFound';
import OfflineSnackbar from './components/nimble/indicators/offlineSnackbar';
import trimbleLogo from './assets/images/trimble_logo.png';

import AppConfig from './custom_modules/appConfig';
import AppNavWrapper from './components/appNav/appNavWrapper';
import SnackbarNotifier from './components/shared/snackbarNotifier';
import AppLoadingError from './components/shared/appLoadingError';

const history = createBrowserHistory();

const App = (): JSX.Element => {
    if (AppConfig.initializationError === true) {
        return <AppLoadingError />;
    }

    return (
        <MuiThemeProvider theme={mui104Theme}>
            <Router history={history}>
                <div className='app-wrapper fill-height'>
                    <Paper
                        className='app-body fill-height'
                        style={{ boxShadow: 'none' }}
                    >
                        <AppNavWrapper
                            navLogoUrl={trimbleLogo}
                            nav={AppConfig.nav}
                            bearer={AppConfig.bearerToken}
                        />

                        <SnackbarProvider maxSnack={3}>
                            <SnackbarNotifier />
                            <Switch>
                                <Redirect exact={true} from='/' to='/locations' />
                                <Redirect from='/FreightPortal' to='/locations' />

                                {AppConfig.nav?.appRoutes.map((route) => {
                                    return (
                                        <Route
                                            key={route.id}
                                            path={route.path}
                                            exact={route.exact || false}
                                            component={route.component}
                                        />
                                    );
                                })}

                                <Route component={PageNotFound} />
                            </Switch>
                        </SnackbarProvider>

                        <OfflineSnackbar />
                    </Paper>
                </div>
            </Router>
        </MuiThemeProvider>
    );
};

export default App;
