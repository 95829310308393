import React, { Fragment } from 'react';
import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';

import { IPagerChangePageResponse } from '../../../interfaces/pagers';
import SidebarPager from '../../nimble/pagers/sidebarPager';
import SidebarListActionsPanel from '../../nimble/sidebarListActionsPanel';
import LoadingIndicator from '../../nimble/indicators/loadingIndicator';

import {
    IFixedLocation,
    IFixedLocationDetails,
    IFixedLocationsListRequestParams
} from '../../../interfaces/locationManagementInterfaces';
import {
    LocationManagementSortByOptionsWithValues,
    DetailViewModes
} from '../../../metadata/locationManagementMetadata';
import LocationList from './locationList';

import placeholderImage from '../../../assets/images/location_mgmt/sidebar_card_placeholder.svg';

const styles = (theme): StyleRules => {
    return createStyles({
        noFixedLocationIndicatorText: {
            display: 'inline-block',
            textAlign: 'center',
            width: '100%',
            fontSize: 14,
            marginTop: 10,

            [theme.breakpoints.up('sm')]: {
                fontSize: 16
            }
        },
        //@FIXME: Firefox & Edge currently (4/4/18) have issues with deeply nested flex items and 100% height. They do not account for the space consumed
        //by the parent components. For now thias component is using vh with a manual substraction of parent spacing to get the pager to always sit
        //at the bottom of the viewport. Revisit with future browsers releases as flexbox becomes more fully baked.
        collapsedFixedLocationListActions: {
            height: 'calc(100vh - 175px)'
        },
        expandedFixedLocationListActions: {
            height: 'calc(100vh - 307px)'
        }
    });
};

interface SidebarProps {
    isFetchingFixedLocations: boolean;
    fixedLocations: IFixedLocation[];
    fixedLocationListActionsContainerIsExpanded: boolean;
    fixedLocationsRequestParams: IFixedLocationsListRequestParams;
    selectedFixedLocationDetails: IFixedLocationDetails;
    totalPossibleCountForCrtRequestParams: number;
    detailViewMode: DetailViewModes;
    updateFixedLocationDetailViewMode: Function;
    fetchFixedLocationDetails: Function;
    updateFixedLocationListSearchTerm: Function;
    updateFixedLocationListPageNumber: Function;
    updateFixedLocationListSort: Function;
    deleteFixedLocation: Function;
    updateFixedLocationListActionsContainerIsExpanded: Function;
    classes?: any;
}

class Sidebar extends React.Component<SidebarProps, {}> {

    //Used to respond to changes in visibility coming from actual panel events
    handleFixedLocationListActionsVisibilityChange = (fixedLocationListActionsContainerIsExpanded: boolean) => {
        this.props.updateFixedLocationListActionsContainerIsExpanded(fixedLocationListActionsContainerIsExpanded);
    }

    renderNoFixedLocationIndicator(indicatorText: string) {
        return (
            <div className='no-fixed-locations fill-height'>
                <img alt='Fixed Locations placeholder' src={placeholderImage} />
                <span className={this.props.classes.noFixedLocationIndicatorText}>{indicatorText}</span>
            </div>
        );
    }

    renderFixedLocationList(disableSidebarInputs: boolean) {
        const selectedFixedLocationCode = (this.props.selectedFixedLocationDetails && this.props.selectedFixedLocationDetails.fixedLocationCode) ? this.props.selectedFixedLocationDetails.fixedLocationCode : null;
        let fixedLocationList;

        if (this.props.isFetchingFixedLocations === true) {
            fixedLocationList = (
                <LoadingIndicator label='Locations Loading...' />
            );
        } else if (this.props.fixedLocationsRequestParams.searchTerm !== '' && this.props.totalPossibleCountForCrtRequestParams === 0) {
            fixedLocationList = this.renderNoFixedLocationIndicator('Your search returned no fixed locations');
        } else if (this.props.fixedLocations.length === 0) {
            fixedLocationList = this.renderNoFixedLocationIndicator('You currently have no fixed locations');
        } else {
            fixedLocationList = (
                <Fragment>
                    <LocationList
                        detailViewMode={this.props.detailViewMode}
                        selectedFixedLocationCode={selectedFixedLocationCode}
                        fixedLocations={this.props.fixedLocations}
                        fetchFixedLocationDetails={this.props.fetchFixedLocationDetails}
                        deleteFixedLocation={this.props.deleteFixedLocation}
                    />

                    <SidebarPager
                        disabled={disableSidebarInputs}
                        recordsToSkip={this.props.fixedLocationsRequestParams.skip}
                        recordsPerPage={this.props.fixedLocationsRequestParams.take}
                        totalRecordCount={this.props.totalPossibleCountForCrtRequestParams}
                        onPageChange={(newPageDetails: IPagerChangePageResponse) => {
                            this.props.updateFixedLocationListPageNumber(newPageDetails);
                        }}
                    />
                </Fragment>
            );
        }

        return fixedLocationList;
    }

    render() {
        const { classes } = this.props;
        const actionContainerClass = this.props.fixedLocationListActionsContainerIsExpanded ? classes.expandedFixedLocationListActions : classes.collapsedFixedLocationListActions;
        const disableSidebarInputs = (this.props.detailViewMode === DetailViewModes.CREATE) || this.props.isFetchingFixedLocations;

        return (
            <div id='location-management-sidebar' className={actionContainerClass}>
                <SidebarListActionsPanel
                    disabled={disableSidebarInputs}
                    sortOptions={LocationManagementSortByOptionsWithValues}
                    sortColumn={this.props.fixedLocationsRequestParams.sortColumn}
                    sortDirection={this.props.fixedLocationsRequestParams.sortDirection}
                    searchTerm={this.props.fixedLocationsRequestParams.searchTerm}
                    createListItem={() => {
                        this.props.updateFixedLocationDetailViewMode(DetailViewModes.CREATE);
                    }}
                    updateSort={this.props.updateFixedLocationListSort}
                    updateSearchTerm={this.props.updateFixedLocationListSearchTerm}
                    listActionsContainerIsExpanded={this.props.fixedLocationListActionsContainerIsExpanded}
                    onToggleListActionsVisibility={this.handleFixedLocationListActionsVisibilityChange}
                />

                <div id='location-management-sidebar-content' className='fill-height'>
                    {this.renderFixedLocationList(disableSidebarInputs)}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Sidebar);
