import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import AppNav from '../nimble/nav/appNav';

import { emptyReduxStore } from '../../redux/actions';

interface IAppNavWrapperProps {
    navLogoUrl: string;
    bearer: string;
    nav?: any;
    emptyReduxStore: Function;
}

const AppNavWrapper = (props: IAppNavWrapperProps) => {
    const location = useLocation();
    const activeRouteParts = location.pathname.split('/');
    const decodedToken = props.bearer ? jwtDecode(props.bearer) : null;

    const getOrgName = () => {
        return decodedToken !== null ? decodedToken['10-4/OrganizationName'] : '';
    }

    return (
        <AppNav
            navLogoUrl={props.navLogoUrl}
            nav={props.nav}
            orgName={getOrgName()}
            activeTab={`/${activeRouteParts[1]}`}
            closeDrawerOnListItemClick={true}
            handleListItemClick={((navMenuItem) => {
                if (navMenuItem.productKey.toLowerCase() === 'logout') {
                    props.emptyReduxStore();
                }
            })}
        />
    );
};

export default connect(null, { emptyReduxStore })(AppNavWrapper);
