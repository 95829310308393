import React from 'react';
import {
    Snackbar,
    withStyles,
    createStyles
} from '@material-ui/core';
import MoodBad from '@material-ui/icons/MoodBad';
import { isFunction } from 'lodash';
import { check as offlineCheck, watch as watchNetwork } from 'is-offline';

/**
 * Our FE apps should be setup as Progessive Web Apps (PWAs):
 * https://developers.google.com/web/progressive-web-apps/
 *
 * The PWA checklist recommends we indicate to the user when they are offline.
 * This component is an easy way to do this in any app. When loaded it will
 * continously poll for an monitor the browsers connectivity and a persistant
 * snackbar will be displayed with the offline notice if needed. If they are
 * online nothing is displayed. If they go offline and then back online the
 * snackbar will auto dismiss.
 *
 * NOTE: This uses offline/offline events that are supplied by browsers.
 * Unfortunately vendors can't standardize on what offline means spo there
 * are some cases when this won't always work (e.g.) you have a local connection,
 * but not a wider Internet connection. The alternative is to poll some sep endpoint
 * and we've decided against that for now. Browsers will eventually standaize this
 * and things here will just work better over time.
 *
 * Reference:
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/Online_and_offline_events
 */

const styles = (theme) => {
    return createStyles({
        snackbarContent: {
            backgroundColor: `${theme.palette.primary.common.errorColor}`
        },
        offlineMessage: {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',

            '& > svg': {
                color: `${theme.palette.primary.common.white}`,
                marginRight: 8
            },
            '> span': {
                marginTop: 2
            }
        }
    });
};

interface IOfflineSnackbarState {
    isOffline: boolean;
    //watchNetwork returns a function that is used to disable/unmount the event listeners. So a better
    //type for networkWatchReference would be Function, but this project has no type defs so that
    //creates an error typing to when calling the unwatch method.
    networkWatchReference: any;
}

class OfflineSnackbar extends React.Component<any, IOfflineSnackbarState> {
    constructor(props: any) {
        super(props);

        this.state = {
            isOffline: false,
            networkWatchReference: null
        };
    }

    componentDidMount() {
        offlineCheck().then((isOffline) => {
            if (this.state.isOffline !== isOffline) {
                this.toggleIsOffline(isOffline);
            }

            this.setupNetworkMonitoring();
        });
    }

    componentWillUnmount() {
        this.teardownNetworkMonitoring();
    }

    toggleIsOffline(isOffline: boolean) {
        this.setState({ isOffline });
    }

    setupNetworkMonitoring() {
        const networkWatchReference = watchNetwork((isOffline) => {
            if (this.state.isOffline !== isOffline) {
                this.toggleIsOffline(isOffline);
            }
        });

        this.setState({ networkWatchReference });
    }

    teardownNetworkMonitoring() {
        if (this.state.networkWatchReference !== null && isFunction(this.state.networkWatchReference.unwatch)) {
            this.state.networkWatchReference.unwatch();
            this.setState({ networkWatchReference: null });
        }
    }

    renderMessage() {
        return (
            <div
                className={this.props.classes.offlineMessage}
            >
                <MoodBad />
                <span>You seem to be offline</span>
            </div>
        );
    }

    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={this.state.isOffline === true}
                message={this.renderMessage()}
                ContentProps={{
                    className: this.props.classes.snackbarContent
                }}
            />
        );
    }
}

export default withStyles(styles)(OfflineSnackbar);
