import React from 'react';
import {
    Button,
    withStyles,
    createStyles
} from '@material-ui/core';
import Formsy from 'formsy-react';
import SearchInput from './inputs/searchInput';
import SelectInput from './inputs/selectInput';
import {
    SelectInputSortByOptions,
    SelectInputSortByDirections
} from '../../interfaces/inputs';

const styles = (theme) => {
    return createStyles({
        button: {
            margin: theme.spacing.unit
        },
        sidebarListActions: {
            boxShadow: 'rgba(0, 0, 0, 0.156863) 0px 3px 10px'
        },
        sidebarListActionsMini: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        sidebarListActionsExtra: {
            height: '118px',
            maxHeight: '118px',
            padding: '6px 16px 6px 16px',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)'
        },
        formControl: {
            display: 'flex' //Needed for ie11
        }
    });
};

interface SidebarListActionsPanelProps {
    /**
     * An array of sort by options used to populate the underlying selectInput
     */
    sortOptions: SelectInputSortByOptions[];
    /**
     * The active sort column key (must be in the provided sortOptions array)
     */
    sortColumn: string;
    /**
     * The active sort direction (ascending / descending)
     */
    sortDirection: SelectInputSortByDirections;
    /**
     * The active searchTerm
     */
    searchTerm: string;
    /**
     * Callback handler for when the create button is clicked
     */
    createListItem: Function;
    /**
     * Callback handler for when the sort is changes
     */
    updateSort: Function;
    /**
     * Callback handler for when user triggers a new search
     */
    updateSearchTerm: Function;
    /**
     * Allows the parent component to manage the expanded state
     */
    listActionsContainerIsExpanded: boolean;
    /**
     * Optional callback handler for when changes the visilibility of the action panel
     */
    onToggleListActionsVisibility?: Function;
    /**
     * Used to manually disable the forms present within the action panel
     */
    disabled: boolean;
    classes?: any;
}

//Refs: https://stackoverflow.com/a/46120422 & https://github.com/callemall/material-ui/issues/8059
const SidebarListActionsPanel = (props: SidebarListActionsPanelProps): JSX.Element => {
    const { classes, disabled } = props;
    const selectSortInputs = props.sortOptions.map((sortOption) => {
        return {
            value: sortOption.value,
            label: sortOption.label
        };
    });

    const updateSort = (selectedOption: { value: any, label: string }) => {
        //The sort by value is sortColumn_sortDirection. Split and send back an obj.
        const valueParts = selectedOption.value.split('_');
        const newSortColumn = valueParts[0];
        const sortDirection = valueParts[1];

        props.updateSort(newSortColumn, sortDirection);
    }

    return (
        <section className={`${classes.sidebarListActions} box-shadow`}>
            <div className={classes.sidebarListActionsMini}>
                <Button
                    className={classes.button}
                    variant='contained'
                    color='primary'
                    size='medium'
                    disabled={disabled}
                    onClick={() => {
                        props.createListItem();
                    }}
                >
                    CREATE
            </Button>
                <Button
                    className={classes.button}
                    variant='text'
                    color='primary'
                    size='medium'
                    disabled={disabled}
                    onClick={() => {
                        props.onToggleListActionsVisibility(!props.listActionsContainerIsExpanded);
                    }}
                >
                    {props.listActionsContainerIsExpanded ? 'HIDE SEARCH/SORT' : 'SEARCH/SORT'}
                </Button>
            </div>
            {
                props.listActionsContainerIsExpanded &&
                <div className={classes.sidebarListActionsExtra}>
                    <Formsy
                        autoComplete='off'
                        disabled={disabled}
                    >
                        <SearchInput
                            name='sidebarListActionsPanelSearch'
                            label='Search'
                            placeholder='Search'
                            value={props.searchTerm}
                            disabled={disabled}
                            onRequestSearch={(newSearchTerm) => {
                                props.updateSearchTerm(newSearchTerm);
                            }}
                        />

                        <SelectInput
                            name='sidebarListActionsPanelSortBy'
                            label='Sort By'
                            value={`${props.sortColumn}_${props.sortDirection}`}
                            formControlClass={classes.formControl}
                            onChange={(selectedOption) => {
                                updateSort(selectedOption)
                            }}
                            options={selectSortInputs}
                            searchable={false}
                            disabled={disabled}
                        />
                    </Formsy>
                </div>
            }
        </section>
    );
};

export default withStyles(styles)(SidebarListActionsPanel);
