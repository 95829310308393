import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import createAppStore from './store';
import AppConfig from './custom_modules/appConfig';
import App from './App';

import './assets/CSS/app.css';

const { persistor, store } = createAppStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate
            loading={<div className='loader' />}
            persistor={persistor}
            onBeforeLift={async () => {
                await AppConfig.initialize();
            }}
        >
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
