import React from 'react';
import moment from 'moment';
import {
    IconButton,
    Chip,
    Menu,
    MenuItem
} from '@material-ui/core';
import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';
import MoreIcon from '@material-ui/icons/MoreVert';
import PlaceIcon from '@material-ui/icons/Place';
import ClockIcon from '@material-ui/icons/AccessTime';
import { isEmpty } from 'lodash';
import { momentStandardDateTimeFormat } from '../../../custom_modules/momentHelper';
import { IFixedLocation } from '../../../interfaces/locationManagementInterfaces';
import { FixedLocationTypes } from '../../../metadata/locationManagementMetadata';

const styles = (theme): StyleRules => {
    return createStyles({
        fixedLocationContainer: {
            'position': 'relative',
            'padding': '12px 16px 12px 16px',
            'borderBottom': `1px solid ${theme.palette.primary.common.borderColor}`,

            '&.isActive': {
                backgroundColor: `${theme.palette.primary.common.activeItemBackground}`
            },
            '&:hover': {
                backgroundColor: `${theme.palette.primary.common.hoveredItemBackground}`
            }
        },
        fixedLocationLastUpdated: {
            color: '#656565',
            fontStyle: 'italic',
            fontSize: 12
        },
        fixedLocationPreferenceEnabled: {
            color: '#757575 !important'
        },
        fixedLocationPreferenceDisabled: {
            color: '#d8d8d8 !important'
        },
        fixedLocationChips: {
            display: 'inline-flex',
            opacity: 1,
            lineHeight: '30px !important'
        },
        fixedLocationChip: {
            cursor: 'pointer !important'
        },
        fixedLocationIcons: {
            'marginTop': 14,
            '&> span': {
                marginRight: 8,
                position: 'relative',
                top: 4
            },
            '&> svg': {
                position: 'relative',
                top: 6,
                marginRight: 8
            }
        },
        fixedLocationAttributes: {
            fontSize: 15,
            fontWeight: 500,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        fixedLocationCode: {
            lineHeight: '24px',
            color: '#000000',
            textAlign: 'right',

            //To truncate we must give specific widths
            //Width is based on the smallest possible size for each breakpoint
            [theme.breakpoints.between('xs', 'md')]: {
                width: 150
            },
            [theme.breakpoints.only('lg')]: {
                width: 140
            },
            [theme.breakpoints.only('xl')]: {
                width: 215
            }
        },
        fixedLocationName: {
            lineHeight: '24px',
            color: '#555555',
            textAlign: 'left',

            //To truncate we must give specific widths
            //Width is based on the smallest possible size for each breakpoint
            [theme.breakpoints.between('xs', 'md')]: {
                width: 150
            },
            [theme.breakpoints.only('lg')]: {
                width: 140
            },
            [theme.breakpoints.only('xl')]: {
                width: 215
            }
        },
        fixedLocationAddressLine: {
            color: '#757575',
            fontWeight: 500
        },
        fixedLocationLastUpdateDate: {
            fontSize: 12,
            fontStyle: 'italic',
            color: '#656565',
            marginTop: 14
        },
        moreOptionsSelector: {
            position: 'absolute',
            bottom: 0,
            right: 0
        }
    });
};

interface LocationCardProps {
    isSelected: boolean;
    fixedLocation: IFixedLocation;
    fetchFixedLocationDetails: Function;
    openDeleteDialogForFixedLocation: Function;
    classes?: any;
}

interface LocationCardState {
    moreMenuAnchorEl: any;
}

class LocationCard extends React.Component<LocationCardProps, LocationCardState> {
    constructor(props: LocationCardProps) {
        super(props);

        this.state = {
            moreMenuAnchorEl: null
        };
    }

    buildLocationAddressLine(city: string, state: string, country: string) {
        let addressLine: string = '';
        const cityName = isEmpty(this.props.fixedLocation.cityName) ? '' : `${this.props.fixedLocation.cityName}, `;
        const stateCode = isEmpty(this.props.fixedLocation.stateCode) ? '' : this.props.fixedLocation.stateCode;
        const countryCode = isEmpty(this.props.fixedLocation.countryCode) ? '' : this.props.fixedLocation.countryCode;

        //Add cityName (might be an empty string)
        addressLine += cityName;

        //Add stateCode (with or without space as needed)
        if (isEmpty(addressLine) && !isEmpty(stateCode)) {
            addressLine += stateCode;
        } else if (!isEmpty(addressLine) && !isEmpty(stateCode)) {
            addressLine += ` ${stateCode}`;
        }

        //Add countryCode (with or without space as needed)
        if (isEmpty(addressLine) && !isEmpty(countryCode)) {
            addressLine += countryCode;
        } else if (!isEmpty(addressLine) && !isEmpty(countryCode)) {
            addressLine += ` ${countryCode}`;
        }

        return addressLine;
    }

    getFixedLocationType() {
        const fixedLocationType = FixedLocationTypes.find((fixedLocationType) => {
            return fixedLocationType.value === this.props.fixedLocation.fixedLocationType;
        });

        return fixedLocationType;
    }

    handleMoreIconClick = (event) => {
        event.stopPropagation(); //Don't letting this bubble or it will trigger a card click
        this.setState({ moreMenuAnchorEl: event.currentTarget });
    }

    handleMoreIconClose = () => {
        this.setState({ moreMenuAnchorEl: null });
    }

    renderMoreOptionsSelector() {
        return (
            <div>
                <IconButton
                    className={this.props.classes.moreOptionsSelector}
                    onClick={this.handleMoreIconClick}
                >
                    <MoreIcon />
                </IconButton>
                <Menu
                    anchorEl={this.state.moreMenuAnchorEl}
                    open={Boolean(this.state.moreMenuAnchorEl)}
                    onClose={this.handleMoreIconClose}
                >
                    <MenuItem
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            this.handleMoreIconClose();
                            this.props.openDeleteDialogForFixedLocation(this.props.fixedLocation.fixedLocationCode);
                        }}
                    >
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        const isActiveClass = this.props.isSelected ? 'isActive' : '';
        const { cityName, stateCode, countryCode } = this.props.fixedLocation;
        const hasCustomGeofenceClass = (this.props.fixedLocation.geofenceRadiusInFeet === null && this.props.fixedLocation.geofencePolygon === null) ? 'disabled' : '';
        const hasCustomDeliveryStatus = (this.props.fixedLocation.overrideDeliveryStatusFlag === false) ? 'disabled' : '';
        const fixedLocationType = this.getFixedLocationType();

        let lastUpdatedDate;
        if (this.props.fixedLocation.lastUpdatedDate === 'just now') {
            //'just now' is the value set when an FL has been updated and the list has yet to be refreshed
            lastUpdatedDate = this.props.fixedLocation.lastUpdatedDate;
        } else if (this.props.fixedLocation.lastUpdatedDate !== null && this.props.fixedLocation.lastUpdatedDate !== '') {
            lastUpdatedDate = moment(this.props.fixedLocation.lastUpdatedDate).format(momentStandardDateTimeFormat);
        } else {
            lastUpdatedDate = '';
        }

        return (
            <div
                className={`${classes.fixedLocationContainer} ${isActiveClass} pointer-item`}
                onClick={() => this.props.fetchFixedLocationDetails(this.props.fixedLocation.fixedLocationCode)}
            >
                <div className={classes.fixedLocationAttributes}>
                    <div
                        className={`${classes.fixedLocationName} textTruncate`}
                        title={this.props.fixedLocation.fixedLocationName}
                    >
                        {this.props.fixedLocation.fixedLocationName}
                    </div>
                    <div
                        className={`${classes.fixedLocationCode} textTruncate`}
                        title={this.props.fixedLocation.fixedLocationCode}
                    >
                        {this.props.fixedLocation.fixedLocationCode}
                    </div>
                </div>
                <div className={classes.fixedLocationAddressLine}>
                    {this.buildLocationAddressLine(cityName, stateCode, countryCode)}
                </div>
                <div className={classes.fixedLocationIcons}>
                    <ClockIcon className={hasCustomDeliveryStatus} />
                    <PlaceIcon className={`mdi ${hasCustomGeofenceClass}`} />
                    <Chip label={fixedLocationType.label} />
                </div>
                <div className={classes.fixedLocationLastUpdateDate}>
                    Last updated {lastUpdatedDate}
                </div>
                {this.renderMoreOptionsSelector()}
            </div>
        );
    }
}

export default withStyles(styles)(LocationCard);
