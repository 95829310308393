import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import { removeSnackbar } from '../../redux/actions/snackbarNotificationActions';

interface SnackbarNotifierProps {
    notifications?: any;
    removeSnackbar: Function;
    enqueueSnackbar: any;
    closeSnackbar: any;
}

class SnackbarNotifier extends Component<SnackbarNotifierProps, {}> {

    private displayed: any = [];

    public shouldComponentUpdate(nextProps: any): boolean {
        if (this.props.notifications !== nextProps.notifications) {
            const newSnacks = nextProps.notifications || [];

            const { notifications: currentSnacks } = this.props;
            let notExists = false;
            for (const snackItem of newSnacks) {
                if (notExists) {
                    continue;
                }
                notExists = notExists || !currentSnacks.filter((snack: any): boolean => {
                    return snackItem.key === snack.key;
                }).length;
            }
            return notExists;
        }
        return false;
    }

    public componentDidUpdate(prevProps: any): void {
        if (this.props.notifications !== prevProps.notifications && this.props.notifications !== undefined) {
            const { notifications = [] } = this.props;

            notifications.forEach((notification: any): void => {
                // Do nothing if snackbar is already displayed
                if (this.displayed.includes(notification.key)) {
                    return;
                }

                const options = {
                    variant: notification.variant,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center'
                    }
                };

                // Display snackbar using notistack
                this.props.enqueueSnackbar(notification.message, options);

                // Keep track of snackbars that we've displayed
                this.storeDisplayed(notification.key);

                // Dispatch action to remove snackbar from redux store
                this.props.removeSnackbar(notification.key);
            });
        }
    }

    private storeDisplayed = (id: any): void => {
        this.displayed = [...this.displayed, id];
    }

    public render(): JSX.Element {
        return <Fragment />;
    }

}

const mapStateToProps = (state: any): object => {
    return {
        notifications: state.snackbarNotifications.notificationList
    };
};

export default withSnackbar(connect(mapStateToProps, { removeSnackbar })(SnackbarNotifier));
