import React from 'react';
import Paginator from 'react-pagify';
import * as segmentize from 'segmentize';
import { Button, withStyles, createStyles } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { IPagerChangePageResponse } from '../../../interfaces/pagers';

const styles = () => {
    return createStyles({
        paginatorWrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'flex-end',
            height: 48,
            minHeight: 48, /* Accounts for list actions expansion panel */
            width: '100%',
            borderTop: 'thin solid #a5a5a5'
        },
        pagerText: {
            fontSize: 11,
            textAlign: 'center'
        },
        pagerTextTotal: {
            fontWeight: 500
        }
    });
};

interface ISidebarPagerProps {
    /**
     * The number of records to skip (e.g.) if recordsPerPage is 100 then skipping 100 puts you on page 2
     */
    recordsToSkip: number;
    /**
     * The max number of records being displayed on the page (in lists we typically use 100).
     */
    recordsPerPage: number;
    /**
     * The total number of records that can be paged through. Used to calculate the page ranges.
     */
    totalRecordCount: number;
    /**
     * Used to pass a callback from the parent component. Any page change event in the UI will
     * fire this callback passing an object conforming to IPagerChangePageResponse which the parent
     * can then use to make any application state changes and API calls required to get the data
     * for the newly requested page.
     */
    onPageChange: Function;
    /**
     * Used to manually disable the paging buttons
     */
    disabled?: boolean;
    classes?: any;
}

/**
 * This is the generic pagination component that should be used in all sidebar lists. It renders a
 * very simple UI that display prev/next buttons as well as indicator counts for the current location
 * within the results.
 *
 * `Example Snippet`:
 * <pre>
 * &lt;SidebarPager
 *   disabled={disableSidebarInputs}
 *   recordsToSkip={0}
 *   recordsPerPage={100}
 *   totalRecordCount={1000}
 *   onPageChange={(newPageDetails: IPagerChangePageResponse) => {
 *      //Trigger whatever your view needs to do when the page changes here...
 *   }}
 * /&gt;
 * </pre>
 *
 * `Rendered Example`:<br/>
 * ![Rendered Example](https://104fpdev.blob.core.windows.net/nimble/docs/images/sidebar_action_panel_and_pager.png)
 */

//Refs: https://stackoverflow.com/a/46120422 & https://github.com/callemall/material-ui/issues/8059
class SidebarPager extends React.Component<ISidebarPagerProps, {}> {
    constructor(props: ISidebarPagerProps) {
        super(props);

        this.changePage = this.changePage.bind(this);
    }

    /**
     * Calculates the total number of pages required based on the prop values passed in for
     * totalRecordCount & recordsPerPage.
     */
    getTotalPageCount(): number {
        let totalPageCount;
        if (this.props.totalRecordCount > 0) {
            if (this.props.totalRecordCount < this.props.recordsPerPage) {
                totalPageCount = 1;
            } else {
                totalPageCount = Math.ceil(this.props.totalRecordCount / this.props.recordsPerPage);
            }
        } else {
            totalPageCount = 0;
        }

        return totalPageCount;
    }

    /**
     * Calculates the value for the starting record of the range
     */
    getPageRangeStart(): number {
        const crtPageNumber = this.getPageNumberFromRecordsToSkipTake(this.props.recordsToSkip, this.props.recordsPerPage);
        return ((crtPageNumber - 1) * this.props.recordsPerPage) + 1;
    }

    /**
     * Calculates the value for the ending record of the range
     */
    getPageRangeEnd(): number {
        let rangeEnd;
        if (this.props.recordsPerPage < this.props.totalRecordCount) {
            rangeEnd = this.props.recordsPerPage * this.getPageNumberFromRecordsToSkipTake(this.props.recordsToSkip, this.props.recordsPerPage);
            if (rangeEnd > this.props.totalRecordCount) {
                rangeEnd = this.props.totalRecordCount;
            }
        } else {
            rangeEnd = this.props.totalRecordCount;
        }

        return rangeEnd;
    }

    /**
     * Calculates the current page number from given skip/take params
     */
    getPageNumberFromRecordsToSkipTake(skip: number, take: number): number {
        let pageNumber: number;
        if (skip < take) {
            pageNumber = 1;
        } else if (skip === take) {
            pageNumber = 2;
        } else if (take > 0) { //Avoid division by zero
            pageNumber = Math.ceil(skip / take) + 1;
        } else {
            pageNumber = 1; //Default
        }

        return pageNumber;
    }

    /**
     * Handles the page change request event and triggers the parent component's
     * onPageChange callback. It hands the parent component page an object conforming to
     * IPagerChangePageResponse which it can then use to make any application state
     * changes and API calls required to get the data for the newly requested page.
     */
    changePage(newPage: number, event: any) {
        const isDisabled = (this.props.disabled && this.props.disabled === true) ? true : false;
        if (isDisabled) { return false; }

        const crtPageNumber = this.getPageNumberFromRecordsToSkipTake(this.props.recordsToSkip, this.props.recordsPerPage);
        if (newPage !== crtPageNumber) {
            const skip = ((newPage <= 1) ? 0 : ((newPage - 1) * this.props.recordsPerPage));

            const newPageDetails: IPagerChangePageResponse = {
                page: newPage,
                skip,
                take: this.props.recordsPerPage
            };

            this.props.onPageChange(newPageDetails);
        } else {
            event.stopPropagation();
            return false;
        }
    }

    render() {
        const { classes } = this.props;
        const isDisabled = (this.props.disabled && this.props.disabled === true) ? true : false;
        const crtPageNumber = this.getPageNumberFromRecordsToSkipTake(this.props.recordsToSkip, this.props.recordsPerPage);
        const totalPageCount = this.getTotalPageCount();
        const prevPageNumber = Math.max(crtPageNumber - 1, 1);
        const nextPageNumber = Math.min(crtPageNumber + 1, totalPageCount);

        return (
            <Paginator.Context
                className={classes.paginatorWrapper}
                segments={segmentize({
                    page: crtPageNumber,
                    pages: totalPageCount
                })}
                onSelect={this.changePage}
            >
                <Paginator.Button page={prevPageNumber}>
                    <Button
                        className={classes.button}
                        variant='text'
                        disabled={(isDisabled || (crtPageNumber === 1)) ? true : false}
                        color='primary'
                        size='small'
                    >
                        <ChevronLeft className={classes.leftIcon} />
                        PREV
                    </Button>
                </Paginator.Button>

                <div className={classes.pagerText}>
                    <div>{`Showing ${this.getPageRangeStart()} to ${this.getPageRangeEnd()}`}</div>
                    <div className={classes.pagerTextTotal}>{`Total Results ${this.props.totalRecordCount}`}</div>
                </div>

                <Paginator.Button page={nextPageNumber}>
                    <Button
                        className={classes.button}
                        variant='text'
                        disabled={(isDisabled || (crtPageNumber === totalPageCount)) ? true : false}
                        color='primary'
                        size='small'
                    >
                        NEXT
                        <ChevronRight className={classes.leftIcon} />
                    </Button>
                </Paginator.Button>
            </Paginator.Context>
        );
    }
}

export default withStyles(styles)(SidebarPager);
