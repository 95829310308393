import { ContentTypes } from '../custom_modules/httpRequests';
import { isEmpty } from 'lodash';

enum Endpoints {
    BASE_TOKEN_ENDPOINT = 'Profile/Users/Token',
    BASE_LOGIN_ENDPOINT = 'Profile/Users/Login?ReturnUrl=',
    BASE_LOGOUT_ENDPOINT = 'Profile/Users/Logout'
}

export default class ProfileJwtAuthService {
    hostname: string = null;
    productKey: string = null;
    authClientId: string;
    loginEndpoint: string = null;

    constructor(hostname: string, productKey: string, authClientId: string) {
        this.hostname = hostname;
        this.productKey = productKey;
        this.authClientId = authClientId;
        this.loginEndpoint = this.getEndpoint('BASE_LOGIN_ENDPOINT');
    }

    logout() {
        const logoutEndpoint = this.getEndpoint('BASE_LOGOUT_ENDPOINT');
        window.location.href = logoutEndpoint;
    }

    getEndpoint(endpointConst: string): string {
        switch (endpointConst) {
            case 'BASE_TOKEN_ENDPOINT':
                return `${this.hostname}/${Endpoints[endpointConst]}`;
            case 'BASE_LOGIN_ENDPOINT':
                return `${this.hostname}/${Endpoints[endpointConst]}${encodeURIComponent(window.location.href)}`;
            case 'BASE_LOGOUT_ENDPOINT':
                return `${this.hostname}/${Endpoints[endpointConst]}`;
            default:
                throw new Error('Invalid endpoint requested!');
        }
    }

    appendClientId(baseUrl: string, clientId: string) {
        if (!isEmpty(this.authClientId)) {
            return `${baseUrl}?clientId=${clientId}`;
        } else {
            return baseUrl;
        }
    }

    getUserToken(): Promise<any> {
        const tokenEndpoint = this.appendClientId(this.getEndpoint('BASE_TOKEN_ENDPOINT'), this.authClientId);
        const loginEndpoint = this.getEndpoint('BASE_LOGIN_ENDPOINT');

        const requestParams: any = {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: ContentTypes.JSON
            },
            credentials: 'include'
        };

        return fetch(tokenEndpoint, requestParams)
            .catch((e) => {
                // If we cannot fetch the token then we've probably gotten the HTML login
                // page reponse so we need to redirect.
                window.location.href = loginEndpoint;
            });
    }
}
