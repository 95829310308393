import { createStore, applyMiddleware, Store } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducers from './redux/reducers';

interface AppStore {
    store: Store;
    persistor: Persistor;
}

const middleWare = process.env.NODE_ENV !== 'production' ? [thunk, logger] : [thunk];

const persistConfig = {
    key: 'root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

const createAppStore = (): AppStore => {
    const store = createStore(persistedReducer, applyMiddleware(...middleWare));
    const persistor = persistStore(store);
    return { store, persistor };
};

export default createAppStore;
