import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@material-ui/core';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import Async from 'react-select/async';

import SelectStyles from '../../../assets/selectTheme';
import DropdownIndicator from '../../nimble/reactSelect/dropdownIndicator';
import OverwriteWithFragment from '../../nimble/reactSelect/overwriteWithFragment';

import AppConfig from '../../../custom_modules/appConfig';

const styles = (theme: Theme) => {
    return createStyles({
        dialog: {
            minHeight: '400px',
            margin: 'auto 8px'
        },
        dialogText: {
            paddingTop: '16px',
            paddingBottom: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            dialog: {
                minHeight: '85%'
            }
        }
    });
};

function Transition(props) {
    return <Slide direction='up' {...props} />;
}

interface AddressLookupDialogProps {
    dialogIsOpen: boolean;
    onDialogClose: Function;
    onAddressConfirmation: Function;
    classes?: any;
}

const AddressLookupDialog = (props: AddressLookupDialogProps): JSX.Element => {

    const [address, setAddress] = useState(null);

    const handleAddressChange = (addressOption: any) => {
        setAddress(addressOption);
    };

    const createLabel = (streetAddress, city, state, country, countryFullName) => {

        let label = '';
        if (streetAddress) {
            label = streetAddress;
        }
        if (city) {
            label += `, ${city}`;
        }
        if (state) {
            label += `, ${state}`;
        }
        if (country && countryFullName) {
            label += `, ${country.toUpperCase() === 'US' ? 'USA' : countryFullName}`;
        }
        if (label.startsWith(',')) {
            label = label.substring(1);
        }

        return label;
    };

    const processPositionLookup = async (inputValue: string, callback: Function): Promise<void> => {
        const region = 'NA'; // NA = North America or WW = WorldWide
        const lookUpUrl = `https://singlesearch.alk.com/${region}/api/search?authToken=${AppConfig.alkMapboxApiKey}&query=${inputValue}&maxResults=5`;

        try {
            const response = await fetch(lookUpUrl);
            const data = await response.json();
            const alkGeocodeResults = data.Locations.map((location: any): any => {

                const { StreetAddress, City, State, Zip, Country, CountryFullName } = location.Address;
                return {
                    label: createLabel(StreetAddress, City, State, Country, CountryFullName),
                    address: StreetAddress,
                    city: City,
                    stateShort: State,
                    countryShort: Country,
                    zipCode: Zip,
                    lat: location.Coords.Lat,
                    lng: location.Coords.Lon
                };
            });

            callback(alkGeocodeResults);
        } catch (ex) {
            console.error('Unable to find search results');
        }
    };

    return (
        <Dialog
            fullWidth={true}
            open={props.dialogIsOpen}
            TransitionComponent={Transition}
            onClose={() => { props.onDialogClose(); }}
            classes={{
                paper: props.classes.dialog
            }}
        >
            <DialogTitle>Address Lookup</DialogTitle>
            <DialogContent className={props.classes.dialogContent}>
                <DialogContentText className={props.classes.dialogText}>
                    Enter an address and we'll see what we can find...
                </DialogContentText>
                <Async
                    styles={SelectStyles}
                    id='addressLookup'
                    inputId='addressLookup-input'
                    className='addressLookup--select--QA'
                    components={{
                        IndicatorSeparator: OverwriteWithFragment,
                        DropdownIndicator
                    }}
                    noOptionsMessage={(menuProps: any) => menuProps.inputValue === '' ? 'Type to Search...' : 'No results found.'}
                    value={address}
                    placeholder='Address...'
                    loadOptions={processPositionLookup}
                    onChange={handleAddressChange}
                    isClearable={true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onDialogClose(); }} className='action--cancel--QA'>Cancel</Button>
                <Button onClick={() => { props.onAddressConfirmation(address); }} disabled={address === null ? true : false} color='primary' className='action--confirm--QA'>USE THIS LOCATION</Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(styles)(AddressLookupDialog);
