import update from 'immutability-helper';
import {
    DetailViewModes,
    CreateModeFormStatesByStep,
    TabNames
} from '../../metadata/locationManagementMetadata';
import { ILocationManagementReduxState } from '../../interfaces/locationManagementInterfaces';
import { cloneDeep } from 'lodash';
import {
    REQUEST_FIXED_LOCATIONS,
    RECEIVE_FIXED_LOCATIONS,
    REQUEST_FIXED_LOCATION_DETAILS,
    RECEIVE_FIXED_LOCATION_DETAILS,
    SET_FIXED_LOCATION_DETAIL_VIEW_MODE,
    CLEAR_FIXED_LOCATION_DETAILS,
    REQUEST_FIXED_LOCATION_CREATE,
    RECEIVE_FIXED_LOCATION_CREATED,
    REQUEST_FIXED_LOCATION_UPDATE,
    RECEIVE_FIXED_LOCATION_UPDATED,
    REQUEST_FIXED_LOCATION_DELETE,
    RECEIVE_FIXED_LOCATION_DELETED,
    SET_PENDING_SNACKBAR_MSG,
    SET_FORM_DIRTY_STATUS,
    RESET_FORM_DIRTY_STATUSES,
    SET_FIXED_LOCATION_ACTION_CONTAINER_IS_EXPANDED,
    SET_EDITABLE_FIXED_LOCATION,
    DESTROY_EDITABLE_FIXED_LOCATION,
    SET_CREATE_MODE_STEP,
    SET_DETAIL_VIEW_MODE_ACTIVE_TAB_NAME,
    HANDLE_FIXED_LOCATION_OPERATING_HOURS_STATUS_CHANGE,
    HANDLE_FIXED_LOCATION_OPERATING_HOURS_START_TIME_CHANGE,
    HANDLE_FIXED_LOCATION_OPERATING_HOURS_END_TIME_CHANGE,
    DELETE_FIXED_LOCATION_OPERATING_HOURS_ROW,
    ADD_FIXED_LOCATION_OPERATING_HOURS_ROW
} from '../actions/locationManagementActions';

export const locationManagementDefaultState: ILocationManagementReduxState = {
    isFetchingFixedLocations: false,
    isFetchingFixedLocationDetails: false,
    isCreatingFixedLocation: false,
    isUpdatingFixedLocation: false,
    isDeletingFixedLocation: false,
    pendingSnackbarMsg: null,
    pendingSnackbarMsgStatus: null,
    selectedFixedLocationDetails: null,
    editableFixedLocation: null,
    fixedLocations: [],
    fixedLocationListActionsContainerIsExpanded: false,
    totalPossibleCountForCrtRequestParams: null,
    detailViewMode: DetailViewModes.EDIT,
    detailViewActiveTabName: TabNames.details,
    createModeCrtStepFormStates: CreateModeFormStatesByStep[1],
    createModeLatestStepDirection: null,
    formDirtyStatuses: {
        fixedLocationDetailsForm: false,
        fixedLocationDetailsOperatingHoursForm: false,
        fixedLocationSettingsDeliveryStatusForm: false,
        fixedLocationSettingsDwellTimeForm: false,
        fixedLocationSettingsGeofencingForm: false
    }
};

// TODO: This reducer has gotten large. At some point we should probably break this down futher.
function locationManagement(state = locationManagementDefaultState, actionPayload) {
    switch (actionPayload.type) {
        case REQUEST_FIXED_LOCATIONS:
            return update(state, {
                isFetchingFixedLocations: { $set: true },
                fixedLocations: { $set: [] },
                selectedFixedLocationDetails: { $set: null }
            });
        case RECEIVE_FIXED_LOCATIONS:
            return update(state, {
                isFetchingFixedLocations: { $set: false },
                fixedLocations: { $set: actionPayload.fixedLocations },
                totalPossibleCountForCrtRequestParams: { $set: actionPayload.totalPossibleCountForCrtRequestParams }
            });
        case REQUEST_FIXED_LOCATION_DETAILS:
            return update(state, {
                detailViewActiveTabName: { $set: TabNames.details }, //Anytime the details change reset the active tab
                isFetchingFixedLocationDetails: { $set: true },
                selectedFixedLocationDetails: { $set: null }
            });
        case RECEIVE_FIXED_LOCATION_DETAILS:
            return update(state, {
                isFetchingFixedLocationDetails: { $set: false },
                selectedFixedLocationDetails: { $set: actionPayload.selectedFixedLocationDetails }
            });
        case SET_FIXED_LOCATION_DETAIL_VIEW_MODE: {
            return {
                ...state,
                detailViewMode: actionPayload.detailViewMode
            };
        }
        case CLEAR_FIXED_LOCATION_DETAILS:
            return update(state, {
                selectedFixedLocationDetails: { $set: null }
            });
        case REQUEST_FIXED_LOCATION_CREATE:
            return update(state, {
                isCreatingFixedLocation: { $set: true },
                pendingSnackbarMsg: { $set: null },
                pendingSnackbarMsgStatus: { $set: null }
            });
        case RECEIVE_FIXED_LOCATION_CREATED:
            return update(state, {
                isCreatingFixedLocation: { $set: false },
                selectedFixedLocationDetails: { $set: actionPayload.fixedLocationDetails }
            });
        case REQUEST_FIXED_LOCATION_UPDATE:
            return update(state, {
                isUpdatingFixedLocation: { $set: true },
                pendingSnackbarMsg: { $set: null },
                pendingSnackbarMsgStatus: { $set: null }
            });
        case RECEIVE_FIXED_LOCATION_UPDATED:
            if (actionPayload.fixedLocationDetails && actionPayload.fixedLocationsWithUpdated) {
                return update(state, {
                    isUpdatingFixedLocation: { $set: false },
                    selectedFixedLocationDetails: { $set: actionPayload.fixedLocationDetails },
                    fixedLocations: { $set: actionPayload.fixedLocationsWithUpdated }
                });
            } else {
                //We avoid touching other state props when an err has occured
                return update(state, {
                    isUpdatingFixedLocation: { $set: false }
                });
            }
        case REQUEST_FIXED_LOCATION_DELETE:
            return update(state, {
                isDeletingFixedLocation: { $set: true },
                pendingSnackbarMsg: { $set: null },
                pendingSnackbarMsgStatus: { $set: null }
            });
        case RECEIVE_FIXED_LOCATION_DELETED:
            //We avoid touching other state props when an err has occured
            return update(state, {
                isDeletingFixedLocation: { $set: false }
            });
        case SET_PENDING_SNACKBAR_MSG:
            return update(state, {
                pendingSnackbarMsg: { $set: actionPayload.msg },
                pendingSnackbarMsgStatus: { $set: actionPayload.status }
            });
        case SET_FORM_DIRTY_STATUS:
            const newFormState = cloneDeep(state.formDirtyStatuses);
            newFormState[actionPayload.formName] = actionPayload.isDirty;

            return update(state, {
                formDirtyStatuses: { $set: newFormState }
            });
        case RESET_FORM_DIRTY_STATUSES:
            const newFormsState = cloneDeep(state.formDirtyStatuses);
            for (const k in newFormsState) {
                newFormsState[k] = false;
            }

            return update(state, {
                formDirtyStatuses: { $set: newFormsState }
            });
        case SET_FIXED_LOCATION_ACTION_CONTAINER_IS_EXPANDED:
            return update(state, {
                fixedLocationListActionsContainerIsExpanded: { $set: actionPayload.isExpanded }
            });
        case SET_EDITABLE_FIXED_LOCATION: {
            const existingFixedLocation = actionPayload.editableFixedLocation;
            const dayOfWeekNameList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            const newOperatingHoursList = [];

            // check to see if each day of the week is present
            // if there is more than 1 set of hours per day, add those to the new list as is with an open status
            // else if the existing hours list was empty, add 24 hours as the default
            // else add a "closed" row for the day.
            dayOfWeekNameList.forEach((dayOfWeekName) => {
                if (state.detailViewMode === DetailViewModes.CREATE && existingFixedLocation.operatingHours.length === 0) {
                    newOperatingHoursList.push({
                        status: { value: '24', label: 'Open 24 hrs.' },
                        dayOfWeek: dayOfWeekName,
                        startTime: '00:00:00',
                        endTime: '23:59:00'
                    });
                } else {
                    if (existingFixedLocation.operatingHours !== null && existingFixedLocation.operatingHours.length > 0) {
                        const operatingHoursPerDayList = existingFixedLocation.operatingHours.filter((e) => e.dayOfWeek === dayOfWeekName);
                        if (operatingHoursPerDayList.length > 0) {
                            operatingHoursPerDayList.forEach((hoursPerDayRow) => {
                                let status = { value: 'Open', label: 'Open' };
                                if (hoursPerDayRow.startTime === '00:00:00' && hoursPerDayRow.endTime === '23:59:00') {
                                    status = { value: '24', label: 'Open 24 hrs.' };
                                } else if (hoursPerDayRow.startTime === '' && hoursPerDayRow.endTime === '') {
                                    status = { value: 'Closed', label: 'Closed' };
                                }
                                newOperatingHoursList.push({
                                    status,
                                    dayOfWeek: hoursPerDayRow.dayOfWeek,
                                    startTime: hoursPerDayRow.startTime,
                                    endTime: hoursPerDayRow.endTime
                                });
                            });
                        } else {
                            newOperatingHoursList.push({
                                status: { value: 'Closed', label: 'Closed' },
                                dayOfWeek: dayOfWeekName,
                                startTime: '',
                                endTime: ''
                            });
                        }
                    } else {
                        newOperatingHoursList.push({
                            status: { value: 'Closed', label: 'Closed' },
                            dayOfWeek: dayOfWeekName,
                            startTime: '',
                            endTime: ''
                        });
                    }
                }
            });

            existingFixedLocation.operatingHours = newOperatingHoursList;

            // this is used for load times.
            const newLoadTimes = [{ isLoadFlag: true, pieceUnit: 'Pallet', loadTimeMinutes: 0 }, { isLoadFlag: false, pieceUnit: 'Pallet', loadTimeMinutes: 0 }, { isLoadFlag: true, pieceUnit: 'Box', loadTimeMinutes: 0 }, { isLoadFlag: false, pieceUnit: 'Box', loadTimeMinutes: 0 }, { isLoadFlag: true, pieceUnit: 'Each', loadTimeMinutes: 0 }, { isLoadFlag: false, pieceUnit: 'Each', loadTimeMinutes: 0 }];

            newLoadTimes.forEach((newLoadTime, index) => {
                const existingFixedLocationLoadTimes = existingFixedLocation.loadTimes.find((existingLoadTime) => {
                    return existingLoadTime.pieceUnit === newLoadTime.pieceUnit && existingLoadTime.isLoadFlag === newLoadTime.isLoadFlag;
                });

                if (existingFixedLocationLoadTimes !== undefined) {
                    if (existingFixedLocationLoadTimes.loadTimeMinutes === null) {
                        newLoadTimes[index].loadTimeMinutes = 0;
                    } else {
                        newLoadTimes[index].loadTimeMinutes = existingFixedLocationLoadTimes.loadTimeMinutes;
                    }
                }
            });

            existingFixedLocation.loadTimes = newLoadTimes;

            return {
                ...state,
                editableFixedLocation: existingFixedLocation
            };
        }
        case DESTROY_EDITABLE_FIXED_LOCATION:
            return update(state, {
                editableFixedLocation: { $set: null }
            });
        case SET_CREATE_MODE_STEP:
            return update(state, {
                createModeCrtStepFormStates: { $set: actionPayload.createModeCrtStepFormStates },
                createModeLatestStepDirection: { $set: actionPayload.createModeLatestStepDirection }
            });
        case SET_DETAIL_VIEW_MODE_ACTIVE_TAB_NAME: {
            return update(state, {
                detailViewActiveTabName: { $set: actionPayload.detailViewActiveTabName }
            });
        }
        case HANDLE_FIXED_LOCATION_OPERATING_HOURS_STATUS_CHANGE: {
            const existingOperatingHoursList = state.editableFixedLocation.operatingHours;
            const newOperatingHoursList = [];

            existingOperatingHoursList.forEach((hoursRow, index, originalOperatingHours) => {
                if (index === actionPayload.id) {
                    newOperatingHoursList.push({
                        status: actionPayload.status,
                        dayOfWeek: hoursRow.dayOfWeek,
                        startTime: hoursRow.startTime,
                        endTime: hoursRow.endTime
                    });
                } else if (index === 0
                    || (index > 0
                        && (hoursRow.dayOfWeek !== actionPayload.dayOfWeek || hoursRow.dayOfWeek !== originalOperatingHours[index - 1].dayOfWeek))) {
                    newOperatingHoursList.push(hoursRow);
                }
            });

            return update(state, {
                editableFixedLocation: {
                    operatingHours: {
                        $set: newOperatingHoursList
                    }
                }
            });
        }
        case HANDLE_FIXED_LOCATION_OPERATING_HOURS_START_TIME_CHANGE: {
            const id = actionPayload.id;

            return update(state, {
                editableFixedLocation: {
                    operatingHours: {
                        [id]: {
                            startTime: {
                                $set: actionPayload.startTime
                            }
                        }
                    }
                }
            });
        }
        case HANDLE_FIXED_LOCATION_OPERATING_HOURS_END_TIME_CHANGE: {
            const id = actionPayload.id;

            return update(state, {
                editableFixedLocation: {
                    operatingHours: {
                        [id]: {
                            endTime: {
                                $set: actionPayload.endTime
                            }
                        }
                    }
                }
            });
        }
        case DELETE_FIXED_LOCATION_OPERATING_HOURS_ROW: {
            return update(state, {
                editableFixedLocation: {
                    operatingHours: {
                        $splice: [[actionPayload.id, 1]]
                    }
                }
            });
        }
        case ADD_FIXED_LOCATION_OPERATING_HOURS_ROW: {
            const newOperatingHoursToAdd = {
                status: { value: 'Open', label: 'Open' },
                dayOfWeek: actionPayload.dayOfWeek,
                startTime: '',
                endTime: ''
            };
            const newOperatingHoursList = [];

            // length is 1 based and the loop below will use a 0 based index, so we subtract to even them up
            const operatingHoursLength = state.editableFixedLocation.operatingHours.length - 1;

            state.editableFixedLocation.operatingHours.forEach((hoursRow, index, originalOperatingHours) => {
                // if we are on the last row and the day of week matches
                // or we are not on the last row and the day of week matches as well as the next day doesn't match
                // add both the original row and the new row
                // else just add the original row.
                if ((index === operatingHoursLength
                    && hoursRow.dayOfWeek === newOperatingHoursToAdd.dayOfWeek)
                    || (index <= operatingHoursLength
                        && hoursRow.dayOfWeek === newOperatingHoursToAdd.dayOfWeek
                        && hoursRow.dayOfWeek !== originalOperatingHours[index + 1].dayOfWeek)) {
                    newOperatingHoursList.push(hoursRow);
                    newOperatingHoursList.push(newOperatingHoursToAdd);
                } else {
                    newOperatingHoursList.push(hoursRow);
                }
            });

            return update(state, {
                editableFixedLocation: {
                    operatingHours: {
                        $set: newOperatingHoursList
                    }
                }
            });
        }
        default:
            return state;
    }
}

export default locationManagement;
