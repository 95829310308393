import React from 'react';
import { Paper, CircularProgress } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = () => {
    return createStyles({
        progressIndicatorWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        indicatorText: {
            fontSize: '18px',
            marginTop: '10px'
        }
    });
};

interface LoadingIndicatorProps {
    /**
     * The string which appears below the loading indicator
     */
    label?: string;
    classes?: any;
}

/**
 * This is a basic circular loading indicator. The size of the circle and the label can be customized.
 * The indicator is always centered both vertically and horizontally. It always takes up 100% of the
 * height and width of the container it is embeded in.
 *
 * `Example Snippet`:
 * <pre>
 * &lt;LoadingIndicator label='Locations Loading...' /&gt;
 * </pre>
 */
const LoadingIndicator = ({ classes, label = 'Loading...' }: LoadingIndicatorProps): JSX.Element => {

    return (
        <Paper
            className={`${classes.progressIndicatorWrapper} fill-height`}
            elevation={0}
        >
            <CircularProgress
                size={120}
                thickness={3}
            />
            <p className={classes.indicatorText}>{label}</p>
        </Paper>
    );
};

export default withStyles(styles)(LoadingIndicator);
