import { ContentTypes, Requests } from '../custom_modules/httpRequests';
import AppConfig from '../custom_modules/appConfig';
import {
    IFixedLocationDetails,
    IFixedLocationsListRequestParams
} from '../interfaces/locationManagementInterfaces';


//The invoker of the API should have a standard Promise catch block to account for general
//server errors, but status codes in the >= 400 && < 500 range won't trigger the catch. This method
//does a simple status check and forces a rejection for these cases as well. This will then
//result on the invokers normal catch block being invoked. Reject will pass through the response body
//block for the UI to use as needed for error message display.
function handleApiResponse(response) {
    // The delete endpoint returns '204 - No Content'
    // Check for the 204 here so we don't try to parse the non-existant json
    if (response.status === 204) {
        return {};
    } else {
        return response.json().then((json) => {
            //In the case of a reject if we want to show the exact server error the UI
            //can grab json.messages.
            return response.ok ? json : Promise.reject(json);
        });
    }
}

/** APIS **/
const LocationManagementApi = {
    getApiBaseUrl() {
        return `${AppConfig.locationMgmtApiHostname}/v1/fixedlocation/`;
    },

    getFixedLocations(requestParams: IFixedLocationsListRequestParams) {
        const requestUrl = `${this.getApiBaseUrl()}?${Requests.toQueryString(requestParams)}`;

        return Requests
            .get(requestUrl, ContentTypes.JSON, ContentTypes.JSON, AppConfig.bearerToken)
            .then(handleApiResponse);
    },

    getFixedLocationDetails(fixedLocationCode: string) {
        const requestUrl = `${this.getApiBaseUrl()}${fixedLocationCode}`;

        return Requests
            .get(requestUrl, ContentTypes.JSON, ContentTypes.JSON, AppConfig.bearerToken)
            .then(handleApiResponse);
    },

    //Creates a new FL
    createFixedLocation(fixedLocationDetails: IFixedLocationDetails) {
        const requestUrl = this.getApiBaseUrl();

        return Requests
            .post(requestUrl, fixedLocationDetails, ContentTypes.JSON, ContentTypes.JSON, AppConfig.bearerToken)
            .then(handleApiResponse);
    },

    //Updates an existing FL
    updateFixedLocation(fixedLocationDetails: IFixedLocationDetails) {
        const requestUrl = `${this.getApiBaseUrl()}${fixedLocationDetails.fixedLocationCode}`;
        return Requests
            .put(requestUrl, fixedLocationDetails, ContentTypes.JSON, ContentTypes.JSON, AppConfig.bearerToken)
            .then(handleApiResponse);
    },

    //Soft deletes a FL
    deleteFixedLocation(fixedLocationCode: string) {
        const requestUrl = `${this.getApiBaseUrl()}${fixedLocationCode}`;

        return Requests
            .delete(requestUrl, ContentTypes.JSON, ContentTypes.JSON, AppConfig.bearerToken)
            .then(handleApiResponse);
    }
};

const OrganizationsDataApi = {
    getApiBaseUrl() {
        return `${AppConfig.organizationsApiHostname}`;
    },
    getUserApplications() {
        const requestUrl = `${this.getApiBaseUrl()}/v1/users/me/apps/`;
        return Requests
            .get(requestUrl, ContentTypes.JSON, ContentTypes.JSON, AppConfig.bearerToken)
            .then(handleApiResponse);
    }
};

export {
    LocationManagementApi,
    OrganizationsDataApi
};
