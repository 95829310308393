import React from 'react';
import { tenfourColors } from '../../assets/theme/mui104Theme';

const areaMaskColors = {
    modal: 'rgba(0, 0, 0, 0.5)',
    disabled: tenfourColors.disabledColor
};

interface IAreaMaskProps {
    /**
     * Show/hide mask
     */
    enabled: boolean;
    /**
    * This controls the color of the mask. Valid values are "modal" & "disabled". If
    * modal then the color matches the MUI dialog mask color. If disabled then it
    * matches tenfourColors.disabledColor.
    */
    maskType: string;
}

/**
 * This component renders a opaque mask exactly like the one used in MUI's dialog
 * compoonent. It is used to make an area appear disabled and prevent clicks from
 * getting through. It is absolutely positioned and for it to work as expected the
 * parent component must be relatively positioned.
 */
class AreaMask extends React.Component<IAreaMaskProps, {}> {
    constructor(props: IAreaMaskProps) {
        super(props);

        this.state = {
            deleteDialogIsOpen: false,
            fixedLocationCodePendingDelete: null
        };
    }

    getAreaMaskStyles(): React.CSSProperties {
        const areaMaskStyles: React.CSSProperties = {
            position: 'absolute',
            top: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            zIndex: 1000
        };

        if (!areaMaskColors[this.props.maskType]) {
            throw new Error('AreaMask maskType prop value must be: modal or disabled');
        } else {
            areaMaskStyles.backgroundColor = areaMaskColors[this.props.maskType];
        }

        return areaMaskStyles;
    }

    render() {
        if (this.props.enabled === true) {
            return (
                <div style={this.getAreaMaskStyles()} />
            );
        } else {
            return null;
        }
    }
}

export default AreaMask;
