import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Grid,
    Typography,
    IconButton,
    Hidden,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    withStyles,
    createStyles,
    Tooltip
} from '@material-ui/core';
import { Close, Menu, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { IAppNavigation, INavLink } from '../../../interfaces/routing';
import trimbleLogo from '../../../assets/images/trimble_logo_white.png';

interface IAppNavProps {
    /** Company Logo URL */
    navLogoUrl: string;
    /** Object containing all required info to build the nav and nav drawer links*/
    nav?: IAppNavigation;
    orgName: string;
    /**Function to handle when a ListItem is clicked in the drawer */
    handleListItemClick?: Function;
    /**Enable toggling the nav drawer when a ListItem is clicked */
    closeDrawerOnListItemClick?: boolean;
    /**The currently selected tab or select option */
    activeTab: string;
    classes?: any;
}

interface IAppNavState {
    menuDrawerIsOpen: boolean;
}

const styles = (theme) => {
    return createStyles({
        scrollButtons: {
            '& svg': {
                color: theme.palette.common.white,
                fill: theme.palette.common.white
            }
        },
        listSubHeaderRoot: {
            fontSize: '16px'
        },
        listItemText: {
            fontSize: '16px'
        },
        typographyRoot: {
            flexGrow: 1,
            borderLeft: '1px solid #fff',
            marginLeft: '16px',
            paddingLeft: '16px',
            fontSize: '16px'
        },
        navSelectWrapper: {
            paddingRight: '14px'
        },
        noImgSpacer: {
            marginLeft: '40px'
        },
        arrowIcon: {
            verticalAlign: 'middle',
            color: '#fff',
            paddingRight: '4px'
        },
        companyLogo: {
            maxHeight: '40px'
        },
        [theme.breakpoints.only('xs')]: {
            companyLogo: {
                maxWidth: '100px'
            }
        }
    });
};

/**
 * This component provides a standard subnav that used by most customer facing FEs (e.g.) FreightPortal,
 * Dash, etc. It consists of a logo on the left, product links in the center, and a user profile area on the
 * far right. It is responsive and uses a drawer for the mobile topNav experience.
 *
 * `Example`:<br/>
 * ![topNav example](https://104fpdev.blob.core.windows.net/nimble/docs/images/topNav.png)
 *
 * NOTE: see app.tsx in the example folder for a complete example
 */
class AppNav extends React.Component<IAppNavProps, IAppNavState> {

    constructor(props: IAppNavProps) {
        super(props);

        this.state = {
            menuDrawerIsOpen: false
        };
    }

    DropdownIndicator = (drowpDownProps: any): JSX.Element => {
        const Chevron = drowpDownProps.selectProps.menuIsOpen === true ? KeyboardArrowUp : KeyboardArrowDown;
        return <Chevron className={this.props.classes.arrowIcon} />;
    };

    toggleMenuDrawer = () => {
        const menuDrawerIsOpen = !this.state.menuDrawerIsOpen;
        this.setState({
            menuDrawerIsOpen
        });
    }

    handleListItemClick = (navMenuItem) => {
        if (this.props.handleListItemClick) {
            //pass the entire navMenuItem data to the parent component
            this.props.handleListItemClick(navMenuItem);
        }
        if (this.props.closeDrawerOnListItemClick === true) {
            this.toggleMenuDrawer();
        }
    }

    renderNavMenu() {
        const { classes } = this.props;

        return Object.keys(this.props.nav.appNavLinks).map((key) => {
            //Only render a menu item from appNavLinks that should be displayed/ the user has permission to view
            const allowedRoutes = this.props.nav.appNavLinks[key].filter((navMenuItem: INavLink) => navMenuItem.showInMenu);
            return (
                <List key={key} className='app-top-toolbar-drawer-list'>
                    <ListSubheader classes={{ root: classes.listSubHeaderRoot }}>{key}</ListSubheader>
                    {allowedRoutes.map((navMenuItem: INavLink) => {
                        return this.renderNavMenuItem(navMenuItem);
                    })}
                </List>
            );
        });
    }

    renderNavMenuItem(navMenuItem: INavLink) {
        const { classes } = this.props;
        const IconComponent = navMenuItem.iconComponent;

        return (
            <ListItem
                button={true}
                component={navMenuItem.path.startsWith('http') || navMenuItem.path.startsWith('mailto') ? 'a' : RouterLink}
                // @ts-ignore
                to={navMenuItem.path.startsWith('http') || navMenuItem.path.startsWith('mailto') ? '' : navMenuItem.path}
                href={navMenuItem.path}
                key={navMenuItem.productKey}
                className={`${this.props.activeTab === navMenuItem.path ? 'active' : ''} link--${navMenuItem.productKey}--QA`}
                onClick={() => { this.handleListItemClick(navMenuItem); }}
                selected={this.props.activeTab === navMenuItem.path}
            >
                {//check if we have an icon
                    IconComponent
                        ?
                        <ListItemIcon>
                            <IconComponent
                                className='mui-icon'
                            />
                        </ListItemIcon>
                        :
                        <span className={classes.noImgSpacer} />
                }
                <ListItemText primary={navMenuItem.label} classes={{ primary: classes.listItemText }} />
            </ListItem>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <AppBar position='static' className='nav-top header-container'>
                <Toolbar variant='dense' className='app-top-toolbar'>
                    <Grid container={true} alignItems='center'>
                        <Grid item={true} xs={6} sm={8} md={3} lg={2} className='app-menu-wrapper'>
                            <IconButton
                                color='inherit'
                                aria-label='Menu'
                                className='app-menu action--open--QA'
                                onClick={this.toggleMenuDrawer}
                            >
                                <Menu />
                            </IconButton>
                            <div className={classes.appLogoWrapper} data-qa='applicationHeader-companyLogo-container'>
                                <Tooltip title='Back to Home'>
                                    <RouterLink
                                        to='/'
                                        aria-label='Back to Home'
                                        classes={{ root: classes.button }}
                                        data-qa='home-button'
                                    >
                                        <img className={classes.companyLogo} src={trimbleLogo} alt='Trimble Logo' />
                                    </RouterLink>
                                </Tooltip>
                            </div>
                            <Hidden only='xs'>
                                <Typography variant='h6' color='inherit' className={classes.typographyRoot} data-qa='applicationHeader-applicationDescription'>
                                    Visibility
                                </Typography>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Toolbar>

                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <Drawer
                            className='app-top-toolbar-drawer'
                            open={this.state.menuDrawerIsOpen}
                            variant='temporary'
                            onClose={this.toggleMenuDrawer}
                        >
                            <div className='app-top-toolbar-drawer-content'>
                                <div className='app-top-toolbar-drawer-header'>
                                    <Close
                                        className='action--close--QA'
                                        onClick={this.toggleMenuDrawer}
                                    />
                                    <Typography variant='h2'>
                                        {this.props.orgName || ''}
                                    </Typography>
                                </div>

                                <div className='company-logo-wrapper'>
                                    <img src={this.props.navLogoUrl} alt='company logo' />
                                </div>

                                {this.renderNavMenu()}
                            </div>
                        </Drawer>
                    </Grid>
                </Grid>
            </AppBar>
        );
    }
}

export default withRouter(withStyles(styles)(AppNav));
