import React from 'react';
import { createStyles, withStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const styles = () => {
    return createStyles({
        arrowIcon: {
            verticalAlign: 'middle',
            color: '#757575',
            paddingRight: '4px'
        }
    });
};
const DropdownIndicator = (props: any): JSX.Element => {
    const Chevron = props.selectProps.menuIsOpen === true ? KeyboardArrowUp : KeyboardArrowDown;
    return <Chevron className={props.classes.arrowIcon} />;
};

export default withStyles(styles)(DropdownIndicator);
