/*
This module contains utility methods for working with Formsy forms
*/
import { isNull, isEqual, isObject, isUndefined } from 'lodash';

export const FORM_UNSAVED_CHANGES_WARNING_TEXT = 'The form has unsaved changes. If you continue your changes will be lost. Are you sure you wish to continue?';

//When trying to use a db value as the value for a form control we often need to convert
//from null to an empty string.
function getFormValueWithoutNull(incomingValue) {
    return isNull(incomingValue) ? '' : incomingValue;
}

//This method is useful for restoring a form from an incoming object (e.g.) nextProps
//coming into componentWillReceiveProps.
//NOTE: this method operates directly on the form.
export function restoreFormValuesFromObject(formControl, formInputContrls, objectToPullFrom) {
    const modelFields = formControl.getModel();
    for (const mf in modelFields) {
        //When shifting from one restore to the next in places where certain form fields come and go, like a select
        //dependent checkbox group, it's possible this control isn't restoreable.
        if (!isUndefined(formInputContrls[mf])) {
            //We often get nulls back from the db which isn't a valid form value so convert to an empty string if needed
            const valueToSet = getFormValueWithoutNull(objectToPullFrom[mf]);

            /*
            Formsy supports nested objects in the form so we need to check for this before just assuming it is a direct prop update.
            Example:
                <MyInput name="email"/>
                <MyInput name="address.street"/>
                Will result in the "form model" being a nested object like {email: 'value', address: {street: 'value'}}
            */
            if (isObject(valueToSet)) {
                for (const nestedMf in valueToSet) {
                    const nestedValueToSet = (objectToPullFrom[mf][nestedMf] === null) ? '' : objectToPullFrom[mf][nestedMf];

                    //Verify the form has a nested field that actually matches this. If not treat it as a normal non-nested form
                    //field value. An example of this is the select component. It's value appears nested because the selected
                    //value is an Object (the selected option Object) and in this case it should be restored as the entire object.
                    //Example: { id: 2, strId: "DistributionCenter", label: "Distribution Center", displayOrder: 2, showInUI: true }
                    if (formInputContrls[mf][nestedMf]) {
                        formInputContrls[mf][nestedMf].setValue(nestedValueToSet);
                    } else {
                        formInputContrls[mf].setValue(valueToSet);
                    }
                }
            } else {
                formInputContrls[mf].setValue(valueToSet);
            }
        }
    }
}

export function determineIfFormIsDirty(currentValuesFromOnChangeEvent: {}, objectToCompareAgainst: {}): boolean {
    //We use the currentValuesFromOnChangeEvent as the model to pick out and create a
    //comparison object. We cannot compare directly against objectToCompareAgainst as
    //it might contains more properties than are present on the form.
    const existingFormFields = {};
    const formFields = Object.keys(currentValuesFromOnChangeEvent);
    for (const ff of formFields) {
        //Check to see if this is a nested object so we update the check accordingly
        //NOTE: currently we only support 1 level of nesting (e.g.) foo.bar not foo.bar.baz
        if (ff.indexOf('.') > -1) {
            //The existingFormFields needs to remain as the nested obj string, (e.g.) foo.bar, but we need the
            //split parts to lookup against the objectToCompareAgainst.
            const ffParts = ff.split('.');
            existingFormFields[ff] = (isNull(objectToCompareAgainst) || isNull(objectToCompareAgainst[ffParts[0]][ffParts[1]])) ? '' : objectToCompareAgainst[ffParts[0]][ffParts[1]];
        } else {
            //The original obj has nulls from the db, but they have been converted to strings for
            //the form inputs. Convert/default here as needed to make for a valid comparison.
            existingFormFields[ff] = (isNull(objectToCompareAgainst) || isNull(objectToCompareAgainst[ff])) ? '' : objectToCompareAgainst[ff];
        }
    }

    //If dirty return true
    return isEqual(currentValuesFromOnChangeEvent, existingFormFields) === false;
}
