import React from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUndefined, isEqual, isArray } from 'lodash';
import { asyncComponent } from 'react-async-component';
import {
    Tabs,
    Tab,
    Grid,
    Fab,
    Hidden
} from '@material-ui/core';
import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LoadingIndicator from '../../nimble/indicators/loadingIndicator';
import { FORM_UNSAVED_CHANGES_WARNING_TEXT } from '../../../custom_modules/formUtils';

import {
    IFixedLocationDetails,
    ICreateModeFormStepDetails
} from '../../../interfaces/locationManagementInterfaces';
import { FixedLocation } from '../../../classes/fixedLocation';
import {
    DetailViewModes,
    TabNames,
    CreateModeStepDirections
} from '../../../metadata/locationManagementMetadata';
import {
    updateFixedLocationDetailViewMode,
    updateFormDirtyStatus,
    resetAllFormDirtyStatuses,
    initializeEditableFixedLocation,
    updateEditableFixedLocation,
    createModeChangeStep,
    changeDetailViewTab
} from '../../../redux/actions/locationManagementActions';
//The details tab is immediately used, but the settings tab might never be so delay loading
//until the user activates the tab.
import DetailsTab from './detailsTab';

import placeholderImage from '../../../assets/images/location_mgmt/detail_placeholder.png';

const SettingsTab = asyncComponent({
    resolve: () => import(/* webpackChunkName: 'SettingsTab' */ './settingsTab'),
    LoadingComponent: () => (
        <LoadingIndicator
            label='Settings Loading...'
        />
    )
});

const styles = (theme): StyleRules => {
    return createStyles({
        noFixedLocationSelected: {
            'marginTop': 125,
            '& img': {
                width: '100%'
            },
            '& p': {
                fontSize: theme.typography.pxToRem(18),
                textAlign: 'center'
            }
        },
        closeFab: {
            'position': 'absolute',
            'right': 30,
            'marginTop': 10,
            'backgroundColor': theme.palette.primary.common.chipBackgroundColor,
            'zIndex': 10,

            '& svg': {
                color: 'rgba(0, 0, 0, 0.54) !important'
            }
        }
    });
};

interface DetailsPaneWrapperProps {
    isFetchingFixedLocationDetails: boolean;
    isFetchingFixedLocations: boolean;
    isSavingFixedLocation: boolean;
    selectedFixedLocationDetails: IFixedLocationDetails;
    editableFixedLocation: FixedLocation;
    detailViewMode: DetailViewModes;
    detailViewActiveTabName: TabNames;
    createModeCrtStepFormStates: ICreateModeFormStepDetails; //CreateModeFormStatesByStep[stepX]
    createModeLatestStepDirection: CreateModeStepDirections;
    formDirtyStatuses: any;
    countOfFixedLocationsInSidebar: number;
    updateSelectedDetailPaneTab: Function;
    fixedLocationListActionsContainerIsExpanded: boolean;
    isMobile: boolean;
    //Added by MUI
    classes?: any;
    //Added by Redux
    history: any;
    dispatch: any;
    store: any;
}

class DetailsPaneWrapper extends React.Component<DetailsPaneWrapperProps, {}> {

    componentDidMount() {
        this.props.dispatch(resetAllFormDirtyStatuses());
        this.props.dispatch(initializeEditableFixedLocation(this.props.selectedFixedLocationDetails));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //We have nested object here and need a deep equality check
        if (!isEqual(nextProps.selectedFixedLocationDetails, this.props.selectedFixedLocationDetails)) {
            this.props.dispatch(resetAllFormDirtyStatuses());
            this.props.dispatch(initializeEditableFixedLocation(nextProps.selectedFixedLocationDetails));
        }
    }

    handleTabChange = (event, tabName: string) => {
        const nextTabName: TabNames = TabNames[tabName];
        const hasDirtyForms = this.getHasDirtyForms();

        //A tab change isn't a route change so Prompt won't kick in. We'll control manually for this event.
        if (hasDirtyForms === true) {
            const cancelResult = window.confirm(FORM_UNSAVED_CHANGES_WARNING_TEXT);
            if (cancelResult === true) {
                //If a tab has multiple forms we won't necessarily know which to reset, but
                //a tab change means all new forms so we are safe to reset them all.
                this.props.dispatch(resetAllFormDirtyStatuses());
                this.props.dispatch(changeDetailViewTab(nextTabName));
            }
        } else {
            this.props.dispatch(changeDetailViewTab(nextTabName));
        }
    }

    //Called by each form's save/next action to updated the active editableFixedLocation instance
    updateEditableFixedLocation = (partialFixedLocationObj) => {
        //The last step in the create flow triggers a save of this FL in the db for the first time
        if (this.props.detailViewMode === DetailViewModes.CREATE && this.props.createModeCrtStepFormStates.step === 5) {
            this.props.dispatch(updateEditableFixedLocation(partialFixedLocationObj, true));
        } else {
            this.props.dispatch(updateEditableFixedLocation(partialFixedLocationObj, false));
        }
    }

    //Used to determine if any forms under the wrapper are dirty
    getHasDirtyForms(): boolean {
        let hasDirtyForms = false;
        for (const v of Object.values(this.props.formDirtyStatuses)) {
            if (v === true) {
                hasDirtyForms = true;
                break;
            }
        }

        return hasDirtyForms;
    }

    //This will close the create/edit form and deselect a fixed location. React Routers Prompt
    //component only kicks in on route changes so we'll mimic it if needed here.
    cancelForm = () => {
        const hasDirtyForms = this.getHasDirtyForms();
        if (hasDirtyForms === true) {
            const cancelResult = window.confirm(FORM_UNSAVED_CHANGES_WARNING_TEXT);
            if (cancelResult === true) {
                //updateFixedLocationDetailViewMode will clear details internally
                this.props.dispatch(updateFixedLocationDetailViewMode(DetailViewModes.EDIT));
            }
        } else {
            //updateFixedLocationDetailViewMode will clear details internally
            this.props.dispatch(updateFixedLocationDetailViewMode(DetailViewModes.EDIT));
        }
    }

    setFormIsDirty = (formName: string, isDirty: boolean) => {
        if (isUndefined(this.props.formDirtyStatuses[formName])) {
            throw new Error('Unknown form name passed to setFormIsDirty');
        } else {
            this.props.dispatch(updateFormDirtyStatus(formName, isDirty));
        }
    }

    renderNoCardSelectedIndicator() {
        let placeholderText: string;
        if (this.props.isFetchingFixedLocations === true) {
            placeholderText = ''; //We need the list to be loaded before we know what message to display
        } else if (this.props.countOfFixedLocationsInSidebar > 0) {
            placeholderText = 'Select a fixed location from the list';
        } else {
            placeholderText = 'There are no fixed locations to display';
        }

        return (
            <Grid
                className={'fill-height'}
                container={true}
                spacing={0}
                direction='column'
                alignItems='center'
            >
                <Grid
                    item={true}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                >
                    <div className={this.props.classes.noFixedLocationSelected}>
                        <img alt='Fixed Location placeholder' src={placeholderImage} />
                        <p>{placeholderText}</p>
                    </div>
                </Grid>
            </Grid>
        );
    }

    render() {
        let componentToRender;
        const boundActionCreators = bindActionCreators({
            createModeChangeStep,
            changeDetailViewTab
        },
            this.props.dispatch);

        if (this.props.isFetchingFixedLocationDetails === true) {
            componentToRender = (
                <LoadingIndicator
                    label='Location Details Loading...'
                />
            );
        } else if (this.props.detailViewMode === DetailViewModes.EDIT && this.props.selectedFixedLocationDetails === null) {
            componentToRender = this.renderNoCardSelectedIndicator();
        } else {
            componentToRender = (
                <div className='fill-height' style={{ overflowX: 'hidden', position: 'relative' }}>
                    {/*
                    Extra close button position at the top right of the form to break out of CREATE or EDIT
                    mode. Mobile has the dialog close already so it is skipped there.
                    */}
                    <Hidden smDown={true}>
                        <Fab
                            color='inherit'
                            className={this.props.classes.closeFab}
                            onClick={this.cancelForm}
                        >
                            <CloseIcon />
                        </Fab>
                    </Hidden>

                    <Prompt
                        when={this.getHasDirtyForms()}
                        message={FORM_UNSAVED_CHANGES_WARNING_TEXT}
                    />

                    <Tabs
                        value={this.props.detailViewActiveTabName}
                        onChange={this.handleTabChange}
                        indicatorColor='primary'
                        textColor='primary'
                        centered={true}
                    >
                        {/* During "create" tabs are disabled as there is a forced flow */}
                        <Tab
                            label='Details'
                            value={TabNames.details}
                            disabled={this.props.detailViewMode === DetailViewModes.CREATE && this.props.createModeCrtStepFormStates.step > 2}
                        />
                        <Tab
                            label='Settings'
                            value={TabNames.settings}
                            disabled={this.props.detailViewMode === DetailViewModes.CREATE && this.props.createModeCrtStepFormStates.step < 3}
                        />
                    </Tabs>
                    {this.props.detailViewActiveTabName === TabNames.details &&
                        <DetailsTab
                            detailViewMode={this.props.detailViewMode}
                            createModeCrtStepFormStates={this.props.createModeCrtStepFormStates}
                            createModeLatestStepDirection={this.props.createModeLatestStepDirection}
                            createModeChangeStep={boundActionCreators.createModeChangeStep}
                            isSavingFixedLocation={this.props.isSavingFixedLocation}
                            editableFixedLocation={this.props.editableFixedLocation}
                            updateEditableFixedLocation={(partialFixedLocationObj) => {
                                this.updateEditableFixedLocation(partialFixedLocationObj);
                            }}
                            formDirtyStatuses={this.props.formDirtyStatuses}
                            setFormIsDirty={this.setFormIsDirty}
                            cancelForm={this.cancelForm}
                            changeTab={boundActionCreators.changeDetailViewTab}
                            isMobile={this.props.isMobile}
                        />
                    }
                    {this.props.detailViewActiveTabName === TabNames.settings &&
                        <SettingsTab
                            detailViewMode={this.props.detailViewMode}
                            createModeCrtStepFormStates={this.props.createModeCrtStepFormStates}
                            createModeLatestStepDirection={this.props.createModeLatestStepDirection}
                            createModeChangeStep={boundActionCreators.createModeChangeStep}
                            isSavingFixedLocation={this.props.isSavingFixedLocation}
                            editableFixedLocation={this.props.editableFixedLocation}
                            updateEditableFixedLocation={(partialFixedLocationObj) => {
                                this.updateEditableFixedLocation(partialFixedLocationObj);
                            }}
                            formDirtyStatuses={this.props.formDirtyStatuses}
                            setFormIsDirty={this.setFormIsDirty}
                            cancelForm={this.cancelForm}
                            changeTab={boundActionCreators.changeDetailViewTab}
                        />
                    }
                </div>
            );
        }

        return componentToRender;
    }
}

function mapStateToProps(state) {
    const {
        isFetchingFixedLocations,
        isFetchingFixedLocationDetails,
        isSavingFixedLocation,
        selectedFixedLocationDetails,
        editableFixedLocation,
        fixedLocations,
        detailViewMode,
        detailViewActiveTabName,
        createModeCrtStepFormStates,
        createModeLatestStepDirection,
        formDirtyStatuses
    } = state.locationManagement;

    return {
        isFetchingFixedLocations,
        isFetchingFixedLocationDetails,
        isSavingFixedLocation,
        selectedFixedLocationDetails,
        editableFixedLocation,
        detailViewMode,
        detailViewActiveTabName,
        createModeCrtStepFormStates,
        createModeLatestStepDirection,
        formDirtyStatuses,
        countOfFixedLocationsInSidebar: isArray(fixedLocations) ? fixedLocations.length : 0
    };
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(DetailsPaneWrapper)));
