import React, { Fragment, Component } from 'react';
import Select from 'react-select';
import { withFormsy } from 'formsy-react';
import {
    FormControl,
    InputLabel,
    FormHelperText,
    withStyles,
    createStyles
} from '@material-ui/core';

import { ISelectInputOption } from '../../../interfaces/inputs';
import SelectStyles from '../../../assets/selectTheme';
import DropdownIndicator from '../reactSelect/dropdownIndicator';
import OverwriteWithFragment from '../reactSelect/overwriteWithFragment';

interface ISelectInputProps {
    id: string;
    name: string;
    label: string;
    options: ISelectInputOption[];
    formControlClass?: string;
    placeholder?: string;
    searchable?: boolean;
    disabled?: boolean;
    required?: boolean;
    onChange?: Function;
    onFocus?: Function;
    onBlur?: Function;
    //Props below here are automatically injected by MUI & Formsy
    showRequired?: Function;
    showError?: Function;
    getErrorMessage?: Function;
    changeValue?: Function;
    setValue?: Function;
    getValue?: Function;
    classes?: any;
}

interface ISelectInputState {
    isFocused: boolean;
}

const styles = (theme) => {
    return createStyles({
        errorMessage: {
            color: theme.palette.primary.common.errorColor
        },
        selectField: {
            marginTop: '16px'
        }
    });
};

class SelectInput extends Component<ISelectInputProps, ISelectInputState> {
    // Define a ctrls obj to wrap HTML control refs
    ctrls: {
        selectComponent?: HTMLFormElement;
    } = {};

    constructor(props) {
        super(props);

        this.state = {
            isFocused: false
        };
    }

    setFocusState(isFocused: boolean) {
        this.setState({
            isFocused
        });
    }

    //SelectInput will truncate text to make it fit in the input area. This can make it difficult
    //to see the actual selected value. In MUTLI like modes the custom chip renderer already implements
    //a title tag. So this method is really only needed for the SINGLE select.
    addTitlesToOptions(optionsToUpdate: ISelectInputOption[]): ISelectInputOption[] {
        const updatedOptions = optionsToUpdate.map((option): ISelectInputOption => {
            if (option.title === undefined) {
                option.title = option.label;
            }

            return option;
        });

        return updatedOptions;
    }

    /**
     * Called when the input receives focus
     */
    handleOnFocus = () => {
        this.setFocusState(true);

        //If the parent component has its own onFocus handler call it as well
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    /**
     * Called when the input loses focus
     */
    handleOnBlur = () => {
        this.setFocusState(false);

        //If the parent component has its own onBlur handler call it as well
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    /**
     * Called when the input value is changed
     *
     * NOTE: In the case of a multiselect, selectedOption is an array of the all the selected
     * options, including the newly selected item.
     */
    handleOnChange(selectedOption) {
        this.props.setValue(selectedOption);

        //If the parent component has its own onBlur handler call it as well
        //Returns the select object, not just the value.
        if (this.props.onChange) {
            this.props.onChange(selectedOption);
        }
    }

    /**
     * Display helper/error text below the input
     */
    renderErrorMessage() {
        const errorMessage = this.props.getErrorMessage();
        if (errorMessage) {
            return <FormHelperText className={this.props.classes.errorMessage}>{errorMessage}</FormHelperText>;
        }

        return <Fragment />;
    }

    getPlaceholderText() {
        let placeholderText = 'Select...';

        if (this.state.isFocused === true) {
            //When they are in the input hide the placeholder text
            placeholderText = '';
        } else if (this.props.placeholder !== undefined) {
            placeholderText = this.props.placeholder;
        }

        return placeholderText;
    }


    renderSelect() {

        let currentValue = this.props.getValue();

        // sometimes the value can come in as a string or number (don't know why), but react-select requires that it's an object that would match one of the options
        // available in the drop down. so, this is a workaround/hack to get that object from the props.options
        if (currentValue !== undefined && typeof currentValue !== 'object') {
            const selectedOption = this.props.options.find((option) => {
                return option.value === currentValue;
            });
            if (selectedOption !== undefined) {
                currentValue = selectedOption;
            }
        }

        return (
            <Select
                id={this.props.id}
                name={this.props.name}
                styles={SelectStyles}
                className={this.props.classes.selectField}
                value={currentValue}
                components={{
                    IndicatorSeparator: OverwriteWithFragment,
                    DropdownIndicator
                }}
                placeholder={this.getPlaceholderText()}
                isSearchable={this.props.searchable !== undefined ? this.props.searchable : true}
                isClearable={false}
                isDisabled={this.props.disabled !== undefined ? this.props.disabled : false}
                required={this.props.required !== undefined ? this.props.required : false}
                onChange={(selectedOption) => this.handleOnChange(selectedOption)}
                onFocus={() => this.handleOnFocus()}
                onBlur={() => this.handleOnBlur()}
                options={this.addTitlesToOptions(this.props.options)}
            />
        );
    }

    render() {
        const isFocusedClass = this.state.isFocused ? 'focused' : '';
        //Set a specific className based on the validation state of this component.
        //showRequired() is true when the value is empty and the required prop is
        //passed to the input. showError() is true when the value typed is invalid.
        let formControlClasses = this.props.showRequired() ? 'required' : this.props.showError() ? 'error' : null;
        if (this.props.formControlClass) {
            //Tack on any extra parent component classes for the form control
            formControlClasses += ` ${this.props.formControlClass}`;
        }

        return (
            <div className='SelectInputWrapper'>
                <FormControl
                    className={`SelectInputWrapper ${formControlClasses}`}
                    required={this.props.required !== undefined ? this.props.required : false}
                    disabled={this.props.disabled !== undefined ? this.props.disabled : false}
                    fullWidth={true}
                >
                    <InputLabel className={`Select-label ${isFocusedClass}`} htmlFor={this.props.id}>
                        {this.props.label}
                    </InputLabel>
                    {this.renderSelect()}
                    {this.renderErrorMessage()}
                </FormControl>
            </div>
        );
    }
}

export default withFormsy(withStyles(styles)(SelectInput));
