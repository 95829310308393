import React from 'react';
import Formsy from 'formsy-react';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ExpansionPanelActions,
    Typography,
    Grid,
    Divider,
    Button,
    withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    IFixedLocationDetails,
    ICreateModeFormStepDetails
} from '../../../interfaces/locationManagementInterfaces';
import {
    DetailViewModes,
    FormNames,
    TabNames,
    CreateModeStepDirections
} from '../../../metadata/locationManagementMetadata';
import OperatingHoursRow from './operatingHoursRow';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 16,
        paddingRight: 16
    },
    heading: {
        fontSize: theme.typography.pxToRem(15)
    },
    sectionHeading: {
        fontSize: theme.typography.pxToRem(16)
    },
    sectionHeadingNote: {
        marginTop: '24px'
    }
});

interface OperatingHoursFormContainerProps {
    isSavingFixedLocation: boolean;
    editableFixedLocation: IFixedLocationDetails;
    detailViewMode: DetailViewModes;
    createModeCrtStepFormStates: ICreateModeFormStepDetails; //CreateModeFormStatesByStep[stepX]
    createModeLatestStepDirection: CreateModeStepDirections;
    createModeChangeStep: Function;
    updateEditableFixedLocation: Function;
    setFormIsDirty: Function;
    cancelForm: Function;
    changeTab: Function;
    classes?: any;
}

interface OperatingHoursFormContainerState {
    canSubmitForm: boolean;
    formIsExpanded: boolean;
    selectedStatus: string;
}

class OperatingHoursFormContainer extends React.Component<OperatingHoursFormContainerProps, OperatingHoursFormContainerState> {
    static formName = FormNames.fixedLocationDetailsOperatingHoursForm; //Must exist in redux locationManagement.formDirtyStatuses

    // Define a ctrls obj to wrap HTML control refs
    ctrls: {
        fixedLocationDetailsOperatingHoursForm?: HTMLFormElement;
    } = {};

    constructor(props: OperatingHoursFormContainerProps) {
        super(props);

        this.state = {
            canSubmitForm: false,
            formIsExpanded: true,
            selectedStatus: ''
        };
    }

    componentDidMount() {
        this.enabledNextButtonIfNeeded(this.props);

        if (this.props.detailViewMode === DetailViewModes.CREATE
                && this.props.createModeLatestStepDirection === CreateModeStepDirections.previous) {
            //By default the form is pristine and not submittable. We want the user to be allowed to advance again
            //using the next button without requiring them to change the form.
            this.setState({ canSubmitForm: true });
            this.props.setFormIsDirty(OperatingHoursFormContainer.formName, true);
        }

        if (this.props.detailViewMode === DetailViewModes.CREATE) {
            this.setCreateModeExpandedStateFromProps(this.props);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.detailViewMode === DetailViewModes.CREATE) {
            if (this.props.createModeCrtStepFormStates !== nextProps.createModeCrtStepFormStates) {
                this.enabledNextButtonIfNeeded(nextProps);
            }

            this.setCreateModeExpandedStateFromProps(nextProps);
        }
    }

    enabledNextButtonIfNeeded(propsToCheck) {
        //By default the form is pristine and not submittable. We want the user to be allowed to advance again
        //using the next button without requiring them to change the form if they are in create mode.
        let shouldEnableNext: boolean;
        if (propsToCheck.detailViewMode === DetailViewModes.CREATE && propsToCheck.createModeLatestStepDirection === CreateModeStepDirections.previous) {
            shouldEnableNext = true;
        } else {
            shouldEnableNext = false;
        }

        if (shouldEnableNext === true) {
            this.setState({ canSubmitForm: true });
            propsToCheck.setFormIsDirty(OperatingHoursFormContainer.formName, true);
        }
    }

    setCreateModeExpandedStateFromProps(propsToCheck) {
        let formShouldBeExpanded: boolean;
        if (propsToCheck.createModeCrtStepFormStates.formName === OperatingHoursFormContainer.formName) {
            formShouldBeExpanded = true;
        } else {
            formShouldBeExpanded = false;
        }

        if (this.state.formIsExpanded !== formShouldBeExpanded) {
            this.setState({ formIsExpanded: formShouldBeExpanded });
        }
    }

    enableSubmit = () => {
        this.setState({ canSubmitForm: true });
    }

    disableSubmit = () => {
        this.setState({ canSubmitForm: false });
    }

    submitForm(formModel) {
        this.props.setFormIsDirty(OperatingHoursFormContainer.formName, false);

        //If in "edit" mode updateEditableFixedLocation also does updates to the API. When
        //in "create" mode we only update editableFixedLocation. A sep call will be made to
        //do the initial FL save/create once the create flow is complete.
        this.props.updateEditableFixedLocation(formModel);
        if (this.props.detailViewMode === DetailViewModes.CREATE) {
            this.props.createModeChangeStep(CreateModeStepDirections.next);
            this.props.changeTab(TabNames.settings);
        }
    }

    handleExpansionPanelChange = (event, expanded) => {
        //Users can only toggle the expanded state when in edit mode. Create mode controls this state internally.
        if (this.props.detailViewMode === DetailViewModes.EDIT) {
            this.setState({
                formIsExpanded: expanded
            });
        }
    }

    renderForm() {
        const { classes } = this.props;
        const disabled = this.props.isSavingFixedLocation
            || (this.props.detailViewMode === DetailViewModes.CREATE && this.props.createModeCrtStepFormStates.formName !== OperatingHoursFormContainer.formName);

        return (
            <div className={classes.root}>
                <Formsy
                    name={OperatingHoursFormContainer.formName}
                    ref={(f) => this.ctrls.fixedLocationDetailsOperatingHoursForm = f}
                    disabled={disabled}
                    onValidSubmit={(model) => {
                        this.submitForm(model);
                    }}
                    onValid={this.enableSubmit}
                    onInvalid={this.disableSubmit}
                    autoComplete='off'
                >
                    <Grid container={true} direction='row'>
                        <Grid item={true} xs={12}>
                            <Typography className={classes.sectionHeading}>
                                Hours of Operation
                            </Typography>
                            <Typography>
                                Choose the days you are open and/or closed. Enter the hours you are open. If you are closed for a period of time during a day, you can add a second set of hours to a day.
                            </Typography>
                            <Typography className={classes.sectionHeadingNote}>
                                PLEASE NOTE: Timezone is local to the location.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid
                        className='fill-height'
                        container={true}
                        direction='column'
                    >
                        {this.renderHoursOfOperationRows()}
                    </Grid>
                </Formsy>
            </div>
        );
    }

    renderHoursOfOperationRows() {
        return this.props.editableFixedLocation.operatingHours.map((hoursRow, index, operatingHours) => {
            let firstHours;
            let subsequentHours;

            if (index === 0 || (index !== 0 && hoursRow.dayOfWeek !== operatingHours[index - 1].dayOfWeek)) {
                firstHours = true;
            }
            else {
                firstHours = false;
            }

            const operatingHoursTotal = operatingHours.length - 1;
            if (index === operatingHoursTotal || (index !== operatingHoursTotal && hoursRow.dayOfWeek !== operatingHours[index + 1].dayOfWeek)) {
                subsequentHours = true;
            } else {
                subsequentHours = false;
            }

            return (
                <OperatingHoursRow
                    key={`${hoursRow.dayOfWeek}_${hoursRow.startTime}_${hoursRow.endTime}_${index}`}
                    index={index}
                    name={index}
                    firstHours={firstHours}
                    subsequentHours={subsequentHours}
                    status={hoursRow.status}
                    dayOfWeek={hoursRow.dayOfWeek}
                    startTime={hoursRow.startTime}
                    endTime={hoursRow.endTime}
                />
            );
        });
    }

    renderBackButtonIfNeeded() {
        let backButton = null;
        if (this.props.detailViewMode === DetailViewModes.CREATE) {
            backButton = (
                <Button
                    size='small'
                    onClick={(event) => {
                        //Back to the previous step
                        this.props.createModeChangeStep(CreateModeStepDirections.previous);
                    }}
                >
                    BACK
                </Button>
            );
        }

        return backButton;
    }

    render() {
        const { classes } = this.props;
        const expansionPanelActionsSubmitButtonLabel = (this.props.detailViewMode === DetailViewModes.CREATE) ? 'NEXT' : 'SAVE';

        if (this.props.editableFixedLocation === null) {
            return null;
        }

        return (
            <ExpansionPanel
                expanded={this.state.formIsExpanded}
                style={{ marginBottom: 20 }}
                onChange={this.handleExpansionPanelChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container={true} direction='row' zeroMinWidth={true}>
                        <Grid item={true} xs={6}>
                            <Typography className={classes.heading}>Location Hours</Typography>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {this.renderForm()}
                </ExpansionPanelDetails>

                <Divider />

                <ExpansionPanelActions>
                    <Button
                        size='small'
                        onClick={(event) => {
                            this.props.cancelForm(event);
                        }}
                    >
                        CANCEL
                    </Button>
                    {this.renderBackButtonIfNeeded()}
                    <Button
                        size='small'
                        color='primary'
                        type='submit'
                        onClick={() => {
                            //Normally a button type of submit would do this without us calling manually,
                            //but since this button lives outside of the form due to the expansion panel
                            //structure we need to trigger it manually.
                            this.ctrls.fixedLocationDetailsOperatingHoursForm.submit();
                        }}
                        disabled={!this.state.canSubmitForm}
                    >
                        {expansionPanelActionsSubmitButtonLabel}
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(OperatingHoursFormContainer);
