export const enqueueSnackbar = (notification: any): object => {
    return {
        type: 'ENQUEUE_SNACKBAR',
        notification: {
            key: new Date().getTime() + Math.random(),
            ...notification
        }
    };
};

export const removeSnackbar = (key: any): object => {
    return {
        type: 'REMOVE_SNACKBAR',
        key
    };
};
