import React from 'react';
import { Typography } from '@material-ui/core';
import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';

const styles = (): StyleRules => {
    return createStyles({
        errorWrapper: {
            margin: '16px',
            textAlign: 'center'
        },
        errorMesssage: {
            paddingTop: '16px',
            paddingBottom: '16px'
        }
    });
};

const PageNotFound = ({ classes }) => {
    return (
        <div className={classes.errorWrapper} data-qa='error-container'>
            <Typography variant='h2' component='h2' data-qa='error-header'>Page Not Found Error</Typography>
            <Typography className={classes.errorMesssage} data-qa='pageNotFoundError'>
                We are unable to locate this page.
            </Typography>
        </div>
    );
};

export default withStyles(styles)(PageNotFound);
