const SelectStyles = {
    option: (provided: any, state: any): object => {
        return ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'rgba(0,0,0, .14)'
                : state.isFocused
                    ? 'rgba(0,0,0, .075)'
                    : '#fff',
            borderBottom: 0,
            color: 'rgba(0,0,0, .87)',
            padding: '8px'
        });
    },
    control: (provided: any, state: any): object => {
        const selectBorder = '1px solid #1976D2';
        return ({
            ...provided,
            'minHeight': '34px',
            'boxShadow': 'unset',
            'borderRadius': '2px',
            'border': state.isDisabled
                ? null
                : state.isSelected
                    ? selectBorder
                    : state.isFocused
                        ? selectBorder
                        : '1px solid #838383',
            ':hover': {
                border: state.isDisabled
                    ? null
                    : state.isSelected
                        ? selectBorder
                        : state.isFocused
                            ? selectBorder
                            : null
            }
        });
    },
    loadingMessage: (provided: any): object => {
        return ({
            ...provided,
            textAlign: 'left'
        });
    },
    menu: (provided: any): object => {
        return ({
            ...provided,
            padding: 0,
            margin: 0,
            border: '1px solid #838383',
            borderRadius: '2px',
            borderTop: 0
        });
    },
    noOptionsMessage: (provided: any): object => {
        return ({
            ...provided,
            textAlign: 'left'
        });
    }
};

export default SelectStyles;
