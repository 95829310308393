import React, { Fragment } from 'react';
import {
    AppBar,
    Grid,
    Toolbar,
    IconButton,
    Dialog,
    DialogContent,
    Hidden,
    withStyles,
    createStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
    ISplitGridLeftSideColumnCounts,
    ISplitGridRightSideColumnCounts
} from '../../../interfaces/layout';

const styles = (theme) => {
    return createStyles({
        appBar: {
            position: 'relative'
        },
        closeButton: {
            color: theme.palette.primary.common.white
        },
        splitGridContainer: {
            height: '100%',
            flexDirection: 'row'
        },
        leftSide: {
            borderRight: '1px solid #dbdcde',
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        rightSide: {
            overflowY: 'auto'
        }
    });
};

interface ISplitGridWithMobileRightDialogProps {
    /**
     * Defines the left side column count at each support grid breakpoint size
     */
    leftSideColumnCounts: ISplitGridLeftSideColumnCounts;
    /**
     * Defines the right side column count at each support grid breakpoint size
     */
    rightSideColumnCounts: ISplitGridRightSideColumnCounts;
    /**
     * A React component to embed on the left side of the layout
     */
    leftSideComponent: any;
    /**
     * A React component to embed on the right side of the layout
     */
    rightSideComponent: any;
    /**
     * Flag that determines if the mobile dialog should be displayed or not. This is controlled by the parent component.
     */
    mobileRightSideDialogIsOpen: boolean;
    /**
     * A callback method provided by the parent component that will be called when the dialog is closed by the user.
     */
    mobileRightSideDialogOnClose: Function;
    classes?: any;
}

/**
 * This component provides a generic moblie friendly split grid layout. Currently, the most common use case is creating a view that offers
 * a list on the left with details for the selected list item on the right. The right side of the layout is automatically hidden on mobile
 * and is injected inside a mobile fullscreen dialog. The implementor passes custom components for each side of the layout and controls
 * when to show the right side dialog in mobile (typically when the user selects an items from the list).
 *
 * NOTE:  When using this component the you must cap the containing div at 100% viewport height. Each side component
 * must is responsible for managing its scrolling independently. This ends up working a lot like framesets in
 * HTML4.
 *
 * `Example Snippet`:
 * <pre>
 * const leftSideSplitGridColumnCounts: ISplitGridLeftSideColumnCounts = {
 *   xs: 12, sm: 12, md: 4, lg: 3, xl: 3
 * };
 * const rightSideSplitGridColumnCounts: ISplitGridRightSideColumnCounts = {
 *   md: 8, lg: 9, xl: 9
 * };
 *
 * &lt;div className='fill-height' style={{ overflow: 'hidden', maxHeight: '100vh' }}&gt;
 *  &lt;SplitGridWithMobileRightDialog
 *    leftSideColumnCounts={leftSideSplitGridColumnCounts}
 *    rightSideColumnCounts={rightSideSplitGridColumnCounts}
 *    leftSideComponent={this.renderLeftSide()
 *    rightSideComponent={this.renderRightSide()}
 *    mobileRightSideDialogIsOpen={this.state.mobileRightSideDialogIsOpen}
 *    mobileRightSideDialogOnClose={this.closeMobileRightSideDialog}
 *  /&gt;
 * &lt;/div&gt;
 * </pre>
 *
 * `Rendered Example`:<br/>
 * ![Rendered Example](https://104fpdev.blob.core.windows.net/nimble/docs/images/splitGridWithMobileRightDialog.png)
 */
class SplitGridWithMobileRightDialog extends React.Component<ISplitGridWithMobileRightDialogProps, any> {
    constructor(props: ISplitGridWithMobileRightDialogProps) {
        super(props);

        this.validateColumnCounts();
    }

    //Verify the given column counts add up to 12 for a complete grid. xs & sm are always 12.
    validateColumnCounts() {
        const mdCount = parseInt(this.props.leftSideColumnCounts.md, 10) + parseInt(this.props.rightSideColumnCounts.md, 10);
        const lgCount = parseInt(this.props.leftSideColumnCounts.lg, 10) + parseInt(this.props.rightSideColumnCounts.lg, 10);
        const xlCount = parseInt(this.props.leftSideColumnCounts.xl, 10) + parseInt(this.props.rightSideColumnCounts.xl, 10);

        if (mdCount !== 12 || lgCount !== 12 || xlCount !== 12) {
            throw new Error('Invalid SplitGridWithMobileRightDialog column count. Must equal 12!');
        }
    }

    //When screen size is xs or sm we remove the right side of the grid, let the left go full 12, and use a
    //responsive dialog to show the right side as needed (usually on left side item click).
    renderMobileRightSideDialog() {
        return (
            <Hidden mdUp={true}>
                <Dialog
                    fullScreen={true}
                    open={this.props.mobileRightSideDialogIsOpen}
                    onClose={() => this.props.mobileRightSideDialogOnClose()}
                >
                    <AppBar className={this.props.classes.appBar}>
                        <Toolbar>
                            <IconButton
                                color='primary'
                                onClick={() => this.props.mobileRightSideDialogOnClose()}
                                aria-label='Close'
                            >
                                <CloseIcon
                                    className={this.props.classes.closeButton}
                                />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {this.props.rightSideComponent}
                    </DialogContent>
                </Dialog>
            </Hidden>
        );
    }

    render() {
        return (
            <Fragment>
                <Grid
                    className={this.props.classes.splitGridContainer}
                    container={true}
                    spacing={0}
                >
                    <Grid
                        className={`${this.props.classes.leftSide} fill-height`}
                        item={true}
                        xs={this.props.leftSideColumnCounts.xs}
                        sm={this.props.leftSideColumnCounts.sm}
                        md={this.props.leftSideColumnCounts.md}
                        lg={this.props.leftSideColumnCounts.lg}
                        xl={this.props.leftSideColumnCounts.xl}
                    >
                        {this.props.leftSideComponent}
                    </Grid>
                    <Hidden smDown={true}>
                        <Grid
                            className={`${this.props.classes.rightSide} fill-height`}
                            item={true}
                            md={this.props.rightSideColumnCounts.md}
                            lg={this.props.rightSideColumnCounts.lg}
                            xl={this.props.rightSideColumnCounts.xl}
                        >
                            {this.props.rightSideComponent}
                        </Grid>
                    </Hidden>
                </Grid>

                {this.renderMobileRightSideDialog()}
            </Fragment>
        );
    }
}

export default withStyles(styles)(SplitGridWithMobileRightDialog);
