import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage/session';

import locationManagementReducer from './locationManagementReducer';
import notificationReducer from './snackbarNotificationReducer';

import { EMPTY_STORE } from '../actions/index';

const appReducer = combineReducers({
    locationManagement: locationManagementReducer,
    snackbarNotifications: notificationReducer
});

const rootReducer = (state: any, action: any): object => {
    switch (action.type) {
        case EMPTY_STORE: {
            storage.removeItem('persist:root');
            return appReducer(undefined, action);
        }
        default:
            return appReducer(state, action);
    }
};

export default rootReducer;
