import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual, isUndefined, uniq, includes, cloneDeep } from 'lodash';
import withWidth from '@material-ui/core/withWidth';
import SplitGridWithMobileRightDialog from '../nimble/layout/splitGridWithMobileRightDialog';
import {
    ISplitGridLeftSideColumnCounts,
    ISplitGridRightSideColumnCounts
} from '../../interfaces/layout';
import { SelectInputSortByDirections } from '../../interfaces/inputs';
import { Requests } from '../../custom_modules/httpRequests';
import { IPagerChangePageResponse } from '../../interfaces/pagers';

import {
    fetchFixedLocations,
    fetchFixedLocationDetails,
    updateFixedLocationDetailViewMode,
    deleteFixedLocation,
    updateFixedLocationListActionsContainerIsExpanded,
    clearFixedLocationDetails
} from '../../redux/actions/locationManagementActions';
import Sidebar from './listPane/sidebar';
import {
    IFixedLocation,
    IFixedLocationDetails,
    IFixedLocationsListRequestParams,
    ILocationManagementWrapperRequestParams
} from '../../interfaces/locationManagementInterfaces';
import {
    LocationManagementSortByOptions,
    DetailViewModes,
    SnackbarMsgStatuses
} from '../../metadata/locationManagementMetadata';
import DetailsPaneWrapper from './detailsPane/detailsPaneWrapper';
import ApiResponseSnackbar from '../shared/apiResponseSnackbar';

const leftSideSplitGridColumnCounts: ISplitGridLeftSideColumnCounts = {
    xs: 12,
    sm: 12,
    md: 4,
    lg: 3,
    xl: 3
};

const rightSideSplitGridColumnCounts: ISplitGridRightSideColumnCounts = {
    md: 8,
    lg: 9,
    xl: 9
};

const locationManagementWrapperDefaultRequestParams: ILocationManagementWrapperRequestParams = {
    skip: 0,
    take: 100,
    searchTerm: '',
    sortColumn: 'FixedLocationName',
    sortDirection: SelectInputSortByDirections.ascending,
    fixedLocationCode: ''
};

interface LocationManagementWrapperProps {
    isFetchingFixedLocations: boolean;
    fixedLocations: IFixedLocation[];
    fixedLocationListActionsContainerIsExpanded: boolean;
    totalPossibleCountForCrtRequestParams: number;
    isFetchingFixedLocationDetails: boolean;
    selectedFixedLocationDetails: IFixedLocationDetails;
    detailViewMode: DetailViewModes;
    pendingSnackbarMsg: string;
    pendingSnackbarMsgStatus: SnackbarMsgStatuses;
    //NOTE: Props below here are set automatically
    location: any; //Route info from the history API
    history: any;
    dispatch: any;
    width?: any;
    classes?: any;
}

interface LocationManagementWrapperState {
    isMobile: boolean;
    mobileRightSideDialogIsOpen: boolean;
    snackbarIsOpen: boolean;
    validSortByColumnValues: string[];
    wrapperRequestParams: ILocationManagementWrapperRequestParams;
}

class LocationManagementWrapper extends React.Component<LocationManagementWrapperProps, LocationManagementWrapperState> {
    constructor(props: LocationManagementWrapperProps) {
        super(props);

        this.state = {
            isMobile: false,
            mobileRightSideDialogIsOpen: false,
            snackbarIsOpen: false,
            validSortByColumnValues: this.getValidSortByOptions(),
            wrapperRequestParams: locationManagementWrapperDefaultRequestParams
        };
    }

    componentDidMount() {
        const crtWrapperRequestParams = this.getRequestParamsFromUrl(this.props);
        //When mounting we have to account for people coming in off a direct external link. The UI
        //doesn't have a single card view mode. When clicking on a card the right side details are
        //displayed. If the user comes from an external link and it has a specific fixedLocationCode
        //then it's likely that the sidebar might not have a matching card (unless by luck to shows in
        //the first 100 records). So to work around this we force a search on the code directly.
        if (crtWrapperRequestParams.fixedLocationCode !== crtWrapperRequestParams.searchTerm) {
            crtWrapperRequestParams.sortColumn = 'FixedLocationCode';
            crtWrapperRequestParams.sortDirection = SelectInputSortByDirections.ascending;
            crtWrapperRequestParams.skip = 0;
            crtWrapperRequestParams.searchTerm = decodeURIComponent(crtWrapperRequestParams.fixedLocationCode);

            //Update url to match our overrides (doesn't trigger any updates)
            this.props.history.replace(`/locations/?${Requests.toQueryString(crtWrapperRequestParams)}`);
        }

        //Fetch LM data from current request params
        this.loadData(crtWrapperRequestParams);

        //Expand the list actions panel if there is an active search term so the user is aware of the search
        if (!isEmpty(crtWrapperRequestParams.searchTerm)) {
            this.props.dispatch(updateFixedLocationListActionsContainerIsExpanded(true));
        }

        //Handle mobile
        const isMobile = this.props.width === 'xs' || this.props.width === 'sm';

        this.setState({ isMobile }, () => {
            this.checkMobileDialog(this.props);
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.location !== nextProps.location) {
            const crtWrapperRequestParams = this.getRequestParamsFromUrl(nextProps);
            this.loadData(crtWrapperRequestParams);
        }

        if (nextProps.pendingSnackbarMsg !== null && this.props.pendingSnackbarMsg === null) {
            this.setState({ snackbarIsOpen: true });
        }

        //Handle mobile
        const isNextMobile = nextProps.width === 'xs' || nextProps.width === 'sm';
        if (this.state.isMobile !== isNextMobile) {
            this.setState({ isMobile: isNextMobile }, () => {
                this.checkMobileDialog(nextProps);
            });
        } else {
            this.checkMobileDialog(nextProps);
        }
    }

    loadData(crtWrapperRequestParams: ILocationManagementWrapperRequestParams) {
        const isFirstLoad = isEmpty(this.props.location.search); //Direct route, no params, treat as first request and load
        const prevFixedLocationRequestParams = this.getFixedLocationRequestParamsFromRequestParamsObj(this.state.wrapperRequestParams);
        const fixedLocationRequestParams = this.getFixedLocationRequestParamsFromRequestParamsObj(crtWrapperRequestParams);

        this.setState({
            wrapperRequestParams: crtWrapperRequestParams
        }, () => {
            if (isFirstLoad === true || !isEqual(fixedLocationRequestParams, prevFixedLocationRequestParams)) {
                this.props.dispatch(fetchFixedLocations(fixedLocationRequestParams));
            }

            //If a fixedLocationCode param is given and it it doesn't match the currently selected
            //fixedLocationCode (if one is selected at all that is).
            if (!isEmpty(crtWrapperRequestParams.fixedLocationCode) &&
                    (isEmpty(this.props.selectedFixedLocationDetails) || (this.props.selectedFixedLocationDetails.fixedLocationCode !== crtWrapperRequestParams.fixedLocationCode))) {
                this.props.dispatch(fetchFixedLocationDetails(crtWrapperRequestParams.fixedLocationCode));
            }
        });
    }

    getRequestParamsFromUrl(propsToCheck) {
        const parsedQueryParams = Requests.getQueryPropsFromUrl(propsToCheck.location.search);

        //Cleanup/validate params here as needed
        const searchTerm: string = !isUndefined(parsedQueryParams.searchTerm) ? decodeURIComponent(parsedQueryParams.searchTerm) : locationManagementWrapperDefaultRequestParams.searchTerm;
        const fixedLocationCode: string = !isUndefined(parsedQueryParams.fixedLocationCode) ? decodeURIComponent(parsedQueryParams.fixedLocationCode) : locationManagementWrapperDefaultRequestParams.fixedLocationCode;
        const skip: number = !isUndefined(parsedQueryParams.skip) ? parseInt(parsedQueryParams.skip, 10) : locationManagementWrapperDefaultRequestParams.skip;
        const take: number = !isUndefined(parsedQueryParams.take) ? parseInt(parsedQueryParams.take, 10) : locationManagementWrapperDefaultRequestParams.take;

        //Validate or default sort direction to one of the predefined values
        const incomingSortDirection: string = !isUndefined(parsedQueryParams.sortDirection) ? parsedQueryParams.sortDirection : locationManagementWrapperDefaultRequestParams.sortDirection;
        let sortDirection: SelectInputSortByDirections;
        if (incomingSortDirection === SelectInputSortByDirections.ascending || incomingSortDirection === SelectInputSortByDirections.descending) {
            sortDirection = SelectInputSortByDirections[incomingSortDirection];
        } else {
            sortDirection = SelectInputSortByDirections.ascending;
        }

        const sortColumn: string = (!isUndefined(parsedQueryParams.sortColumn) && includes(this.state.validSortByColumnValues, parsedQueryParams.sortColumn)) ? parsedQueryParams.sortColumn : locationManagementWrapperDefaultRequestParams.sortColumn;

        const requestParams = {
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection,
            fixedLocationCode
        };

        return requestParams;
    }

    getFixedLocationRequestParamsFromRequestParamsObj(fixedLocationRequestParamsObj: IFixedLocationsListRequestParams) {
        const {
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection
        } = fixedLocationRequestParamsObj;

        return {
            skip,
            take,
            searchTerm,
            sortColumn,
            sortDirection
        };
    }

    getValidSortByOptions(): string[] {
        const validSortByColumnValues = LocationManagementSortByOptions.map((sortByOption) => {
            return sortByOption.propertyName;
        });

        return uniq(validSortByColumnValues);
    }

    updateFixedLocationCodeRequestParam(selectedFixedLocationCode: string) {
        const newWrapperRequestParams = cloneDeep(this.state.wrapperRequestParams);
        newWrapperRequestParams.fixedLocationCode = decodeURIComponent(selectedFixedLocationCode);
        this.pushNewHistoryPageForUpdatedRequestParams(newWrapperRequestParams);
    }

    updateFixedLocationListSearchTermRequestParam(newSearchTerm: string) {
        const newWrapperRequestParams = cloneDeep(this.state.wrapperRequestParams);
        newWrapperRequestParams.searchTerm = decodeURIComponent(newSearchTerm);
        //They might have searched aftering having first pages. So we also need to reset paging or
        //this might looks as if no results exists.
        newWrapperRequestParams.skip = 0;
        newWrapperRequestParams.fixedLocationCode = ''; //Always cleared when taking new actions
        this.pushNewHistoryPageForUpdatedRequestParams(newWrapperRequestParams);
    }

    updateFixedLocationListPageNumberRequestParam(newPageDetails: IPagerChangePageResponse) {
        const newWrapperRequestParams = cloneDeep(this.state.wrapperRequestParams);
        newWrapperRequestParams.skip = newPageDetails.skip;
        newWrapperRequestParams.take = newPageDetails.take;
        newWrapperRequestParams.fixedLocationCode = ''; //Always cleared when taking new actions
        this.pushNewHistoryPageForUpdatedRequestParams(newWrapperRequestParams);
    }

    updateFixedLocationListSortRequestParam(newSortColumn: string, newSortDirection: SelectInputSortByDirections) {
        const newWrapperRequestParams = cloneDeep(this.state.wrapperRequestParams);
        newWrapperRequestParams.sortColumn = newSortColumn;
        newWrapperRequestParams.sortDirection = newSortDirection;
        newWrapperRequestParams.fixedLocationCode = ''; //Always cleared when taking new actions
        newWrapperRequestParams.skip = 0; //Take them back to the first page when sorting
        this.pushNewHistoryPageForUpdatedRequestParams(newWrapperRequestParams);
    }

    pushNewHistoryPageForUpdatedRequestParams(wrapperParamsToSet: ILocationManagementWrapperRequestParams) {
        //We push a new page onto the history stack and these Params will become the new state when the component updates
        this.props.history.push(`/locations/?${Requests.toQueryString(wrapperParamsToSet)}`);
    }

    //The rightside of the grid is hidden in mobile and rendered inside of a fullscreen responsive dialog
    //as needed based on current state.
    checkMobileDialog(propsToCheck) {
        //When the breakpoint is mobile sized and a FL has been selected show the rightside dialog
        if (this.state.isMobile === true && this.state.mobileRightSideDialogIsOpen === false) {
            if (this.props.selectedFixedLocationDetails !== null
                    || this.props.isFetchingFixedLocationDetails === true
                    || propsToCheck.detailViewMode === DetailViewModes.CREATE) {
                this.openMobileRightSideDialog();
            }
        } else if (this.state.isMobile === false && this.state.mobileRightSideDialogIsOpen === true) {
            //In addition to handling the open state, this method handles closing the dialog if the window has
            //been resized larger than mobile by the user. Typically the user will close
            //the dialog manually.
            this.closeMobileRightSideDialog();
        }
    }

    openMobileRightSideDialog() {
        this.setState({ mobileRightSideDialogIsOpen: true });
    }

    closeMobileRightSideDialog = () => {
        this.setState({ mobileRightSideDialogIsOpen: false });
        this.props.dispatch(clearFixedLocationDetails());

        if (this.props.detailViewMode === DetailViewModes.CREATE) {
            this.props.dispatch(updateFixedLocationDetailViewMode(DetailViewModes.EDIT));
        }
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setState({ snackbarIsOpen: false });
    }

    renderLeftSide() {
        const boundActionCreators = bindActionCreators({
            updateFixedLocationDetailViewMode,
            deleteFixedLocation,
            updateFixedLocationListActionsContainerIsExpanded
        },
        this.props.dispatch);

        return (
            <Sidebar
                isFetchingFixedLocations={this.props.isFetchingFixedLocations}
                fixedLocations={this.props.fixedLocations}
                fixedLocationListActionsContainerIsExpanded={this.props.fixedLocationListActionsContainerIsExpanded}
                fixedLocationsRequestParams={this.state.wrapperRequestParams}
                selectedFixedLocationDetails={this.props.selectedFixedLocationDetails}
                totalPossibleCountForCrtRequestParams={this.props.totalPossibleCountForCrtRequestParams}
                detailViewMode={this.props.detailViewMode}
                updateFixedLocationDetailViewMode={boundActionCreators.updateFixedLocationDetailViewMode}
                fetchFixedLocationDetails={(newFixedLocationCode: string) => {
                    this.updateFixedLocationCodeRequestParam(newFixedLocationCode);
                }}
                updateFixedLocationListSearchTerm={(newSearchTerm: string) => {
                    this.updateFixedLocationListSearchTermRequestParam(newSearchTerm);
                }}
                updateFixedLocationListPageNumber={(newPageDetails: IPagerChangePageResponse) => {
                    this.updateFixedLocationListPageNumberRequestParam(newPageDetails);
                }}
                updateFixedLocationListSort={(newSortColumn: string, newSortDirection: SelectInputSortByDirections) => {
                    this.updateFixedLocationListSortRequestParam(newSortColumn, newSortDirection);
                }}
                deleteFixedLocation={(fixedLocationCode: string) => {
                    //Delete the requested location and trigger a new list fetch. We can't simply remove
                    //the location from the list without a refresh as this will potentially leaving paging
                    //in a broken state.
                    const fixedLocationRequestParams = this.getFixedLocationRequestParamsFromRequestParamsObj(this.state.wrapperRequestParams);
                    boundActionCreators.deleteFixedLocation(fixedLocationCode, fixedLocationRequestParams);
                }}
                updateFixedLocationListActionsContainerIsExpanded={boundActionCreators.updateFixedLocationListActionsContainerIsExpanded}
            />
        );
    }

    renderRightSide() {
        return (
            <DetailsPaneWrapper
                detailViewMode={this.props.detailViewMode}
                isMobile={this.state.isMobile}
            />
        );
    }

    render() {
        //When using splitgrid we must cap the containing div at 100% viewport height. Each side
        //must then manage its scrolling independently. This ends up working a lot like framesets in
        //HTML4.
        return (
            <div className='fill-height' style={{ overflow: 'hidden', maxHeight: '100vh' }}>
                <SplitGridWithMobileRightDialog
                    leftSideColumnCounts={leftSideSplitGridColumnCounts}
                    rightSideColumnCounts={rightSideSplitGridColumnCounts}
                    leftSideComponent={this.renderLeftSide()}
                    rightSideComponent={this.renderRightSide()}
                    mobileRightSideDialogIsOpen={this.state.mobileRightSideDialogIsOpen}
                    mobileRightSideDialogOnClose={this.closeMobileRightSideDialog}
                />

                <ApiResponseSnackbar
                    isOpen={this.state.snackbarIsOpen}
                    pendingSnackbarMsg={this.props.pendingSnackbarMsg}
                    pendingSnackbarMsgStatus={this.props.pendingSnackbarMsgStatus}
                    closeSnackbar={this.closeSnackbar}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        isFetchingFixedLocations,
        fixedLocations,
        fixedLocationListActionsContainerIsExpanded,
        totalPossibleCountForCrtRequestParams,
        isFetchingFixedLocationDetails,
        selectedFixedLocationDetails,
        detailViewMode,
        pendingSnackbarMsg,
        pendingSnackbarMsgStatus
    } = state.locationManagement;

    return {
        isFetchingFixedLocations,
        fixedLocations,
        fixedLocationListActionsContainerIsExpanded,
        totalPossibleCountForCrtRequestParams,
        isFetchingFixedLocationDetails,
        selectedFixedLocationDetails,
        detailViewMode,
        pendingSnackbarMsg,
        pendingSnackbarMsgStatus
    };
}

export default withRouter(connect(mapStateToProps)(withWidth()(LocationManagementWrapper)));
