import React from 'react';
import { withFormsy } from 'formsy-react';
import {
    FormControl,
    TextField
} from '@material-ui/core';
import { isUndefined } from 'lodash';

interface ITextInputProps {
    /**
     * Input name prop
     */
    name: string;
    /**
     * Input label prop
     */
    label: string;
    /**
     * Input value (Formsy typically manages once initialized)
     */
    value: string | number;
    /**
     * Any valid HTML5 input type (defaults to text)
     */
    type?: string;
    /**
     * Any extra classes that need to be applied to the FormControl
     */
    formControlClass?: string;
    /**
     * Any extra classes that need to be applied to the TextField which is wrapped inside the FormControl
     */
    textfieldClass?: string;
    /**
     * By default the MUI Textfield component takes up 100% of its parent containers width (see the fullWidth prop).
     * Overriding the width on the component itself involves digging into a series of nested
     * DOM elements that are actually rendered around the input itself. The simplest way to deal with
     * is to wrap the component and set a width on the parent. Since the TextField is already wrapped in a FormControl
     * we can set the property here if needed.
     *
     * NOTE: The recommended min width accounting for theme padding, etc is technically 50px. Keep in mind though
     * the actual min width should really be the width of the longest validation error message. The caption
     * text is wrapped in the FormControl and the input will expand to equal the width of a validation error.
     */
    width?: number;
    /**
     * Callback function that will recieve the latest value when it is changed. You should note use this
     * value directly to try and manipulate the input. Formsy controls after initialization, but sometimes
     * other UI elements need to monitor the value of another input.
     */
    onValueChange?: Function;
    //Props below here are automatically injected by MUI & Formsy
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    fullWidth?: boolean;
    showRequired?: Function;
    showError?: Function;
    getErrorMessage?: Function;
    changeValue?: Function;
    setValue?: Function;
    getValue?: Function;
    classes?: any;
}

/**
 * This is a general purpose text input. It wraps MUI's TextField component, styles it according to our style
 * guide and integrates it with Formsy. It is expected that this component is only used inside of Formsy form.
 */
class TextInput extends React.Component<ITextInputProps, {}> {

    //setValue() will set the value of the component, which in
    //turn will validate it and the rest of the form
    changeValue = (event) => {
        this.props.setValue(event.currentTarget.value);

        if (!isUndefined(this.props.onValueChange)) {
            this.props.onValueChange(event.currentTarget.value);
        }
    }

    render() {
        //Set a specific className based on the validation state of this component.
        //showRequired() is true when the value is empty and the required prop is
        //passed to the input. showError() is true when the value typed is invalid.
        let formControlClasses = this.props.showRequired() ? 'required' : this.props.showError() ? 'error' : null;
        if (this.props.formControlClass) {
            //Tack on any extra parent component classes for the form control
            formControlClasses += ` ${this.props.formControlClass}`;
        }

        const textFieldClass = this.props.textfieldClass || null;

        //An error message is returned ONLY if the component is invalid
        const errorMessage = this.props.getErrorMessage();

        //Build out any extra styles
        const formControlExtaStyles: React.CSSProperties = {};
        const inputLabelExtraStyles: React.CSSProperties = {};
        if (!isUndefined(this.props.width)) {
            formControlExtaStyles.width = this.props.width;
        }
        //If the type is hidden we need to pass through through in a few places
        if (!isUndefined(this.props.type) && this.props.type === 'hidden') {
            formControlExtaStyles.display = 'none';
            inputLabelExtraStyles.display = 'none';
        }

        return (
            <FormControl
                className={formControlClasses}
                style={formControlExtaStyles}
                required={!isUndefined(this.props.required) ? this.props.required : false}
                disabled={!isUndefined(this.props.disabled) ? this.props.disabled : false}
                error={(errorMessage) ? true : false}
                fullWidth={!isUndefined(this.props.fullWidth) ? this.props.fullWidth : true}
            >
                <TextField
                    className={textFieldClass}
                    label={this.props.label}
                    name={this.props.name}
                    type={!isUndefined(this.props.type) ? this.props.type : 'text'}
                    required={!isUndefined(this.props.required) ? this.props.required : false}
                    autoComplete='off'
                    onChange={this.changeValue}
                    //Often we get NULLs back from the db and try to restore them into form fields,
                    //but the TextField control does not support null so convert if needed.
                    value={(this.props.value === null) ? '' : this.props.getValue()}
                    placeholder={!isUndefined(this.props.placeholder) ? this.props.placeholder : ''}
                    fullWidth={!isUndefined(this.props.fullWidth) ? this.props.fullWidth : true}
                    InputProps={{
                        type: !isUndefined(this.props.type) ? this.props.type : 'text',
                        disabled: !isUndefined(this.props.disabled) ? this.props.disabled : false,
                        disableUnderline: true,
                        error: (errorMessage) ? true : false
                    }}
                    InputLabelProps={{
                        style: inputLabelExtraStyles,
                        disableAnimation: true,
                        shrink: true,
                        error: (errorMessage) ? true : false
                    }}
                    helperText={errorMessage}
                    FormHelperTextProps={{
                        error: (errorMessage) ? true : false
                    }}
                />
            </FormControl>
        );
    }
}

export default withFormsy(TextInput);
