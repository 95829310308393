import {
    SelectInputSortByOptions,
    SelectInputSortByDirections
} from '../interfaces/inputs';
import { IFixedLocationTypeOption } from '../interfaces/locationManagementInterfaces';
import { IFixedLocationOperatingHoursStatusOption } from '../interfaces/locationManagementInterfaces';

/* ENUMS */
enum DetailViewModes {
    CREATE = 'CREATE',
    EDIT = 'EDIT'
}

enum TabNames {
    details = 'details',
    settings = 'settings'
}

enum FormNames {
    fixedLocationDetailsForm = 'fixedLocationDetailsForm',
    fixedLocationDetailsOperatingHoursForm = 'fixedLocationDetailsOperatingHoursForm',
    fixedLocationSettingsDeliveryStatusForm = 'fixedLocationSettingsDeliveryStatusForm',
    fixedLocationSettingsDwellTimeForm = 'fixedLocationSettingsDwellTimeForm',
    fixedLocationSettingsGeofencingForm = 'fixedLocationSettingsGeofencingForm'
}

//Used by the UI to determine what type of snackbar msg to show
enum SnackbarMsgStatuses {
    SUCCESS,
    ERROR
}

//Used by the UI to determine what type of geofence map to render
enum GeofenceMapModes {
    'simple' = 'simple',
    'advanced' = 'advanced'
}

enum CreateModeStepDirections {
    'next' = 'next',
    'previous' = 'previous'
}

/* ENUM-ish constants */
const LocationManagementSortByOptions = [
    { propertyName: 'CityName', sortDirection: SelectInputSortByDirections.ascending, label: 'City Name (A-Z)' },
    { propertyName: 'CityName', sortDirection: SelectInputSortByDirections.descending, label: 'City Name (Z-A)' },
    { propertyName: 'LastUpdatedDate', sortDirection: SelectInputSortByDirections.descending, label: 'Last Update (Newest to Oldest)' },
    { propertyName: 'LastUpdatedDate', sortDirection: SelectInputSortByDirections.ascending, label: 'Last Update (Oldest to Newest)' },
    { propertyName: 'FixedLocationName', sortDirection: SelectInputSortByDirections.ascending, label: 'Location Name (A-Z)' },
    { propertyName: 'FixedLocationName', sortDirection: SelectInputSortByDirections.descending, label: 'Location Name (Z-A)' },
    { propertyName: 'FixedLocationCode', sortDirection: SelectInputSortByDirections.ascending, label: 'Location Code (A-Z)' },
    { propertyName: 'FixedLocationCode', sortDirection: SelectInputSortByDirections.descending, label: 'Location Code (Z-A)' }
];

const LocationManagementSortByOptionsWithValues: SelectInputSortByOptions[] = LocationManagementSortByOptions.map((sortByOption) => {
    const sortByOptionWithValue: SelectInputSortByOptions = {
        value: `${sortByOption.propertyName}_${sortByOption.sortDirection}`,
        propertyName: sortByOption.propertyName,
        sortDirection: sortByOption.sortDirection,
        label: sortByOption.label
    };

    return sortByOptionWithValue;
});

const FixedLocationTypes: IFixedLocationTypeOption[] = [
    { value: 'Unknown', label: 'Unknown', displayOrder: 0, showInUI: false },
    { value: 'Generic', label: 'Generic', displayOrder: 1, showInUI: true },
    { value: 'DistributionCenter', label: 'Distribution Center', displayOrder: 2, showInUI: true },
    { value: 'ManufacturingFacility', label: 'Manufacturing Facility', displayOrder: 3, showInUI: true },
    { value: 'Crossdock', label: 'Crossdock', displayOrder: 4, showInUI: true },
    { value: 'Terminal', label: 'Terminal', displayOrder: 5, showInUI: true },
    { value: 'Fuel', label: 'Fuel', displayOrder: 6, showInUI: true },
    { value: 'TrailerDropYard', label: 'Trailer Drop Yard', displayOrder: 7, showInUI: true },
    { value: 'TractorDropYard', label: 'Tractor Drop Yard', displayOrder: 8, showInUI: true },
    { value: 'TractorTrailerSwap', label: 'Tractor/Trailer Swap', displayOrder: 11, showInUI: true },
    { value: 'RestArea', label: 'Rest Area', displayOrder: 10, showInUI: true },
    { value: 'TruckWash', label: 'Truck Wash', displayOrder: 11, showInUI: true },
    { value: 'TankWash', label: 'Tank Wash', displayOrder: 12, showInUI: true },
    { value: 'Customer', label: 'Customer', displayOrder: 13, showInUI: true }
];

const FixedLocationOperatingHoursStatuses: IFixedLocationOperatingHoursStatusOption[] = [
    { value: 'Open', label: 'Open' },
    { value: '24', label: 'Open 24 hrs.' },
    { value: 'Closed', label: 'Closed' }
];

const CreateModeFormStatesByStep = {
    1: {
        step: 1,
        tabId: TabNames.details,
        formName: FormNames.fixedLocationDetailsForm
    },
    2: {
        step: 2,
        tabId: TabNames.details,
        formName: FormNames.fixedLocationDetailsOperatingHoursForm
    },
    3: {
        step: 3,
        tabId: TabNames.settings,
        formName: FormNames.fixedLocationSettingsDeliveryStatusForm
    },
    4: {
        step: 4,
        tabId: TabNames.settings,
        formName: FormNames.fixedLocationSettingsDwellTimeForm
    },
    5: {
        step: 5,
        tabId: TabNames.settings,
        formName: FormNames.fixedLocationSettingsGeofencingForm
    }
};

export {
    LocationManagementSortByOptions,
    LocationManagementSortByOptionsWithValues,
    FixedLocationTypes,
    FixedLocationOperatingHoursStatuses,
    DetailViewModes,
    CreateModeFormStatesByStep,
    CreateModeStepDirections,
    SnackbarMsgStatuses,
    GeofenceMapModes,
    TabNames,
    FormNames
};
