import React, { Fragment, Component } from 'react';
import Select from 'react-select';
import { withFormsy } from 'formsy-react';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    withStyles,
    createStyles
} from '@material-ui/core';

import { ISelectInputOption } from '../../../interfaces/inputs';
import SelectStyles from '../../../assets/selectTheme';
import DropdownIndicator from '../reactSelect/dropdownIndicator';
import OverwriteWithFragment from '../reactSelect/overwriteWithFragment';

interface ISingleSelectInputProps {
    id: string;
    name: string;
    label: string;
    options: ISelectInputOption[];
    selectedOption: string;
    onChange?: Function;
    formControlClass?: string;
    required: boolean;
    //Props below here are automatically injected by MUI & Formsy
    showRequired?: Function;
    showError?: Function;
    getErrorMessage?: Function;
    changeValue?: Function;
    setValue?: Function;
    getValue?: Function;
    classes?: any;
}

interface ISingleSelectInputState {
    isFocused: boolean;
    selectedOption: string;
}

const styles = (theme) => {
    return createStyles({
        selectInput: {
            marginTop: '16px'
        },
        errorMessage: {
            color: theme.palette.primary.common.errorColor
        }
    });
};

/**
 * ## Overview:
 * This is our generic single select input control. We are wrapping [react-select](http://jedwatson.github.io/react-select/),
 * the same core select component that MUI uses internally, and styling it to match our style guides.
 *
 * ## Situations this control can support:
 * 1. **Single select** - This is the closest to a standard select control. Dropdown with a list of options
 *    where the user picks one.
 *
 *
 * ###### NOTES:
 * - react-select underwent a rewrite during development of this component. We'll want to circle back and upgrade,
 * but lower priority for now. Refer to the v1 docs here - https://v1.react-select.com/
 *
 *
 * ## EXAMPLES:
 * Our [Storybook](http://nimble.10-4.net/storybook/?selectedKind=Components%2FInputs%2FSingleSelectInput&selectedStory=Single%20Select&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel)
 * has live examples, code snippets, and design references.
 */
class SingleSelectInput extends Component<ISingleSelectInputProps, ISingleSelectInputState> {

    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
            selectedOption: this.props.selectedOption
        };
    }

    componentDidMount() {
        const { selectedOption } = this.props;
        if (selectedOption !== null && selectedOption !== '') {
            this.props.setValue(selectedOption);
        }
    }

    //SelectInput will truncate text to make it fit in the input area. This can make it difficult
    //to see the actual selected value. This method is really only needed for the SINGLE select.
    addTitlesToOptions(): ISelectInputOption[] {
        const { options } = this.props;
        const updatedOptions = options.map((option): ISelectInputOption => {
            if (option.title === undefined) {
                option.title = option.label;
            }

            return option;
        });

        return updatedOptions;
    }

    handleOnChange = (selectedOption) => {
        this.props.setValue(selectedOption);

        // If the parent component has its own onChange handler call it as well
        // Returns the selected object, not just the value.
        if (this.props.onChange) {
            this.props.onChange(selectedOption);
        }
    }

    renderErrorMessage() {
        const errorMessage = this.props.getErrorMessage();
        if (errorMessage) {
            return <FormHelperText className={this.props.classes.errorMessage}>{errorMessage}</FormHelperText>;
        }

        return <Fragment />;
    }

    render() {
        const { classes } = this.props;
        const isFocusedClass = this.state.isFocused ? 'focused' : '';
        //Set a specific className based on the validation state of this component.
        //showRequired() is true when the value is empty and the required prop is
        //passed to the input. showError() is true when the value typed is invalid.
        let formControlClasses = this.props.showRequired() ? 'required' : this.props.showError() ? 'error' : null;

        if (this.props.formControlClass) {
            //Add on any extra parent component classes for the form control
            formControlClasses += ` ${this.props.formControlClass}`;
        }

        return (
            <div className='SingleSelectInputWrapper'>
                <FormControl
                    className={`SelectInputWrapper ${formControlClasses}`}
                    required={this.props.required}
                    fullWidth={true}
                >
                    <InputLabel className={`Select-label`} htmlFor={this.props.id}>
                        {this.props.label}
                    </InputLabel>
                    <Select
                        id={this.props.id}
                        name={this.props.name}
                        styles={SelectStyles}
                        value={this.props.getValue() || ''}
                        className={`${classes.selectInput} ${isFocusedClass}`}
                        components={{
                            IndicatorSeparator: OverwriteWithFragment,
                            DropdownIndicator
                        }}
                        onChange={this.handleOnChange}
                        options={this.addTitlesToOptions()}
                        isClearable={false}
                        isDisabled={false}
                    />
                    {this.renderErrorMessage()}
                </FormControl>
            </div>
        );
    }
}

export default withFormsy(withStyles(styles)(SingleSelectInput));
