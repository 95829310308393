import React from 'react';
import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';
import AreaMask from '../../nimble/areaMask';
import { IFixedLocation } from '../../../interfaces/locationManagementInterfaces';
import { DetailViewModes } from '../../../metadata/locationManagementMetadata';
import DeleteDialog from './deleteDialog';
import LocationCard from './locationCard';

const styles = (theme): StyleRules => {
    return createStyles({
        locationManagementFixedLocationList: {
            overflowY: 'scroll',
            position: 'relative' //Needed to support AreaMask
        }
    });
};

interface LocationListProps {
    detailViewMode: DetailViewModes;
    selectedFixedLocationCode: string;
    fixedLocations: any[];
    fetchFixedLocationDetails: Function;
    deleteFixedLocation: Function;
    classes?: any;
}

interface LocationListState {
    deleteDialogIsOpen: boolean;
    fixedLocationCodePendingDelete: string;
}

class LocationList extends React.Component<LocationListProps, LocationListState> {
    listNode: HTMLElement = null;

    constructor(props: LocationListProps) {
        super(props);

        this.state = {
            deleteDialogIsOpen: false,
            fixedLocationCodePendingDelete: null
        };
    }

    componentDidMount() {
        this.listNode = document.getElementById('location-management-fixed-location-list');
        this.scrollActiveCardIntoViewIfNeeded();
    }

    componentWillUnmount() {
        this.listNode.removeEventListener('scroll', this.listNodeScrollCanceller);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        //You can't mount this component in CREATE mode so this check is only needed when props change
        this.manageListScrollCanceller(nextProps);
    }

    //Add/remove scroll event canceller as needed based on detailViewMode
    //NOTE: Would be nice to hide the scrollbar with overflowY: hidden, but this messes with
    //the positioning of AreaMask.
    manageListScrollCanceller(nextProps) {
        if (nextProps.detailViewMode === DetailViewModes.CREATE) {
            //Prevent scrolling in CREATE mode
            this.listNode.addEventListener('scroll', this.listNodeScrollCanceller);
        } else {
            this.listNode.removeEventListener('scroll', this.listNodeScrollCanceller);
        }
    }

    //Cancels the list scroll event
    listNodeScrollCanceller = () => {
        this.listNode.scrollTop = 0;
        return false;
    }

    //When first mounted this could be from a direct restore (shared url/external link for example).
    //So we look for an active card in the list of results and scroll it to the top of the list if needed.
    //When the user selects a card on the already rendered list the list is not rerender and this the
    //scroll position remains.
    scrollActiveCardIntoViewIfNeeded() {
        const selectedCard = document.querySelector('#location-management-fixed-location-list .isActive');
        if (selectedCard !== null) {
            this.listNode.scrollTop = selectedCard['offsetTop'] - this.listNode.offsetTop;
        }
    }

    openDeleteDialogForFixedLocation = (fixedLocationCode: string) => {
        this.setState({
            fixedLocationCodePendingDelete: fixedLocationCode,
            deleteDialogIsOpen: true
        });
    }

    handleCancelDelete = () => {
        this.setState({
            deleteDialogIsOpen: false,
            fixedLocationCodePendingDelete: null
        });
    }

    handleConfirmDelete = () => {
        this.props.deleteFixedLocation(this.state.fixedLocationCodePendingDelete);
        this.setState({
            deleteDialogIsOpen: false,
            fixedLocationCodePendingDelete: null
        });
    }

    render() {
        const { classes } = this.props;

        //When in CREATE mode we turn off the scrollbar and place a mask over the list
        const listScrollIsDisabled = (this.props.detailViewMode === DetailViewModes.CREATE);

        return (
            <section
                id='location-management-fixed-location-list'
                className={`${classes.locationManagementFixedLocationList} fill-height`}
            >
                <AreaMask
                    enabled={listScrollIsDisabled}
                    maskType='disabled'
                />

                <DeleteDialog
                    dialogIsOpen={this.state.deleteDialogIsOpen}
                    onCancelDelete={this.handleCancelDelete}
                    onConfirmDelete={this.handleConfirmDelete}
                />

                {this.props.fixedLocations.map((fixedLocation: IFixedLocation) => {
                    return (
                        <LocationCard
                            key={fixedLocation.fixedLocationCode}
                            isSelected={this.props.selectedFixedLocationCode === fixedLocation.fixedLocationCode}
                            fixedLocation={fixedLocation}
                            fetchFixedLocationDetails={this.props.fetchFixedLocationDetails}
                            openDeleteDialogForFixedLocation={this.openDeleteDialogForFixedLocation}
                        />
                    );
                })}
            </section>
        );
    }
}

export default withStyles(styles)(LocationList);
