const isBrowserLangUS = ((navigator as any).language === 'en-US' || (navigator as any).language === 'en') ? true : false;

const momentTime24HourWithLeadingZero =  'HH:mm';

const momentStandardDateFormat = isBrowserLangUS ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

const momentStandardDateTimeFormat = `${momentStandardDateFormat} ${momentTime24HourWithLeadingZero}`;

export {
    momentStandardDateTimeFormat,
    momentTime24HourWithLeadingZero
};
