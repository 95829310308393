/**
 * This is the interface for a single menu item in the selectInput list
 */
 export interface ISelectInputOption {
    value: string | number;
    label: string;
    title?: string; //This gets added dynamically by SelectInput (matches the label)
    disabled?: boolean;
}

export interface SelectInputSortByOptions {
    propertyName: string;
    sortDirection: SelectInputSortByDirections;
    value: string;
    label: string;
}

/* ENUMS */
export enum SelectInputSortByDirections {
    ascending = 'ascending',
    descending = 'descending'
}
