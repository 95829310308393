import React from 'react';
import {
    Snackbar,
    Button,
    withStyles
} from '@material-ui/core';
import { isArray } from 'lodash';
import { SnackbarMsgStatuses } from '../../metadata/locationManagementMetadata';

const styles = (theme) => ({
    snackbarError: {
        'backgroundColor': `${theme.palette.primary.common.errorColor}`,
        'color': `${theme.palette.primary.common.white}`,

        '& > button': {
            backgroundColor: `${theme.palette.primary.common.errorColor}`
        }
    },
    snackbarSuccess: {
        'backgroundColor': `${theme.palette.primary.common.successColor}`,
        'color': `${theme.palette.primary.common.white}`,

        '& > button': {
            backgroundColor: `${theme.palette.primary.common.successColor}`
        }
    },
    snackbarButton: {
        color: `${theme.palette.primary.common.white}`,
        border: `2px solid ${theme.palette.primary.common.white}`
    }
});

interface IApiResponseSnackbarProps {
    isOpen: boolean;
    pendingSnackbarMsg: any; //string[] | string;
    pendingSnackbarMsgStatus: SnackbarMsgStatuses;
    closeSnackbar: any; //Function
    classes?: any;
}

class ApiResponseSnackbar extends React.Component<IApiResponseSnackbarProps, {}> {

    //Takes an incoming message or array of messages and builds a usable message node
    getSnackbarMessageNode() {
        const { pendingSnackbarMsg } = this.props;

        let snackBarMessage;
        if (isArray(pendingSnackbarMsg)) {
            if (pendingSnackbarMsg.length > 1) {
                snackBarMessage = (
                    <ul>
                        {pendingSnackbarMsg.map((msg, index) => {
                            return <li key={`snackbar_msg_${index}`}>{msg}</li>;
                        })}
                    </ul>
                );
            } else {
                snackBarMessage = <span>{pendingSnackbarMsg}</span>;
            }
        } else {
            snackBarMessage = <span>{pendingSnackbarMsg}</span>;
        }

        return snackBarMessage;
    }

    render() {
        const { classes } = this.props;

        //When an error, force user to manually dismiss as an acknowledgement
        const autoHideDuration = (this.props.pendingSnackbarMsgStatus === SnackbarMsgStatuses.ERROR) ? null : 4000;
        const snackbarContentClass = (this.props.pendingSnackbarMsgStatus === SnackbarMsgStatuses.ERROR) ? classes.snackbarError : classes.snackbarSuccess;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={this.props.isOpen}
                autoHideDuration={autoHideDuration}
                onClose={this.props.closeSnackbar}
                message={this.getSnackbarMessageNode()}
                ContentProps={{
                    className: snackbarContentClass
                }}
                action={[
                    <Button
                        key='closeApiResponseSnackbar'
                        size='small'
                        className={classes.snackbarButton}
                        onClick={this.props.closeSnackbar}
                    >
                        GOT IT
                    </Button>
                ]}
            />
        );
    }
}

export default withStyles(styles)(ApiResponseSnackbar);
