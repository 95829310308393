import { LocationManagementApi } from '../../services/apiRequests';
import {
    IFixedLocationDetails,
    IFixedLocationsListRequestParams
} from '../../interfaces/locationManagementInterfaces';
import { FixedLocation } from '../../classes/fixedLocation';
import {
    DetailViewModes,
    SnackbarMsgStatuses,
    CreateModeStepDirections,
    CreateModeFormStatesByStep,
    TabNames
} from '../../metadata/locationManagementMetadata';
import { isArray, cloneDeep } from 'lodash';

//Available Action Consts for Reducers
const REQUEST_FIXED_LOCATIONS = 'REQUEST_FIXED_LOCATIONS';
const RECEIVE_FIXED_LOCATIONS = 'RECEIVE_FIXED_LOCATIONS';
const REQUEST_FIXED_LOCATION_DETAILS = 'REQUEST_FIXED_LOCATION_DETAILS';
const RECEIVE_FIXED_LOCATION_DETAILS = 'RECEIVE_FIXED_LOCATION_DETAILS';
const SET_FIXED_LOCATION_DETAIL_VIEW_MODE = 'SET_FIXED_LOCATION_DETAIL_VIEW_MODE';
const CLEAR_FIXED_LOCATION_DETAILS = 'CLEAR_FIXED_LOCATION_DETAILS';
const REQUEST_FIXED_LOCATION_CREATE = 'REQUEST_FIXED_LOCATION_CREATE';
const RECEIVE_FIXED_LOCATION_CREATED = 'RECEIVE_FIXED_LOCATION_CREATED';
const REQUEST_FIXED_LOCATION_UPDATE = 'REQUEST_FIXED_LOCATION_UPDATE';
const RECEIVE_FIXED_LOCATION_UPDATED = 'RECEIVE_FIXED_LOCATION_UPDATED';
const REQUEST_FIXED_LOCATION_DELETE = 'REQUEST_FIXED_LOCATION_DELETE';
const RECEIVE_FIXED_LOCATION_DELETED = 'RECEIVE_FIXED_LOCATION_DELETED';
const SET_PENDING_SNACKBAR_MSG = 'SET_PENDING_SNACKBAR_MSG';
const SET_FORM_DIRTY_STATUS = 'SET_FORM_DIRTY_STATUS';
const RESET_FORM_DIRTY_STATUSES = 'RESET_FORM_DIRTY_STATUSES';
const SET_FIXED_LOCATION_ACTION_CONTAINER_IS_EXPANDED = 'SET_FIXED_LOCATION_ACTION_CONTAINER_IS_EXPANDED';
const SET_EDITABLE_FIXED_LOCATION = 'SET_EDITABLE_FIXED_LOCATION';
const DESTROY_EDITABLE_FIXED_LOCATION = 'DESTROY_EDITABLE_FIXED_LOCATION';
const SET_CREATE_MODE_STEP = 'SET_CREATE_MODE_STEP';
const SET_DETAIL_VIEW_MODE_ACTIVE_TAB_NAME = 'SET_DETAIL_VIEW_MODE_ACTIVE_TAB_NAME';
const HANDLE_FIXED_LOCATION_OPERATING_HOURS_STATUS_CHANGE = 'HANDLE_FIXED_LOCATION_OPERATING_HOURS_STATUS_CHANGE';
const HANDLE_FIXED_LOCATION_OPERATING_HOURS_START_TIME_CHANGE = 'HANDLE_FIXED_LOCATION_OPERATING_HOURS_START_TIME_CHANGE';
const HANDLE_FIXED_LOCATION_OPERATING_HOURS_END_TIME_CHANGE = 'HANDLE_FIXED_LOCATION_OPERATING_HOURS_END_TIME_CHANGE';
const DELETE_FIXED_LOCATION_OPERATING_HOURS_ROW = 'DELETE_FIXED_LOCATION_OPERATING_HOURS_ROW';
const ADD_FIXED_LOCATION_OPERATING_HOURS_ROW = 'ADD_FIXED_LOCATION_OPERATING_HOURS_ROW';

//Trigger a request for fixed locations (using latest filter state)
function requestFixedLocations() {
    return (dispatch) => {
        return dispatch({ type: REQUEST_FIXED_LOCATIONS });
    };
}

//Callback handler used when fetchFixedLocations recieves data
function receiveFixedLocations(json) {
    return {
        type: RECEIVE_FIXED_LOCATIONS,
        fixedLocations: (json && json.data) ? json.data : [],
        totalPossibleCountForCrtRequestParams: (json && json.totalPossibleCount) ? json.totalPossibleCount : 0
    };
}

//Fetch fixed locations from the API based on the current state
function fetchFixedLocations(fixedLocationRequestParmsObj: IFixedLocationsListRequestParams) {
    return (dispatch) => {
        dispatch(requestFixedLocations());

        return LocationManagementApi
            .getFixedLocations(fixedLocationRequestParmsObj)
            .then((json) => dispatch(receiveFixedLocations(json)))
            .catch((err) => {
                dispatch({
                    type: SET_PENDING_SNACKBAR_MSG,
                    msg: 'Failed to fetch locations',
                    status: SnackbarMsgStatuses.ERROR
                });
            });
    };
}

//Trigger a request for fixed location details
function requestFixedLocationDetails(fixedLocationCode: string) {
    return (dispatch) => {
        return dispatch({
            type: REQUEST_FIXED_LOCATION_DETAILS,
            fixedLocationCode
        });
    };
}

//Callback handler used when fetchFixedLocationDetails recieves data
function receivedFixedLocationDetails(json) {
    const selectedFixedLocationDetails: IFixedLocationDetails = (json.data && isArray(json.data)) ? json.data[0] : null;

    return {
        type: RECEIVE_FIXED_LOCATION_DETAILS,
        selectedFixedLocationDetails
    };
}

//Fetch fixed location details from the API for a given fixedLocatioCode
function fetchFixedLocationDetails(fixedLocationCode: string) {
    return (dispatch) => {
        dispatch(requestFixedLocationDetails(fixedLocationCode));

        return LocationManagementApi
            .getFixedLocationDetails(fixedLocationCode)
            .then((json) => dispatch(receivedFixedLocationDetails(json)))
            .catch((err) => {
                dispatch({
                    type: SET_PENDING_SNACKBAR_MSG,
                    msg: 'Failed to fetch location details',
                    status: SnackbarMsgStatuses.ERROR
                });
            });
    };
}

//Resets (clears) selectedFixedLocationDetails
function clearFixedLocationDetails() {
    return {
        type: CLEAR_FIXED_LOCATION_DETAILS
    };
}

//Used to enable edit or create mode for the details view
function updateFixedLocationDetailViewMode(detailViewMode: DetailViewModes) {
    return (dispatch) => {
        dispatch({
            type: SET_FIXED_LOCATION_DETAIL_VIEW_MODE,
            detailViewMode
        });

        if (detailViewMode === DetailViewModes.CREATE) {
            dispatch(createModeReset());
        }

        dispatch(clearFixedLocationDetails());
    };
}

//Used to update a single fixed location
function updateFixedLocation(fixedLocationDetails: IFixedLocationDetails) {
    return (dispatch, getState) => {
        dispatch({ type: REQUEST_FIXED_LOCATION_UPDATE });

        return LocationManagementApi
            .updateFixedLocation(fixedLocationDetails)
            .then(() => {
                //Find the updated location in the fixedLocations state obj and update it
                const fixedLocationsWithUpdated = getState().locationManagement.fixedLocations.map((fl) => {
                    if (fl.fixedLocationCode === fixedLocationDetails.fixedLocationCode) {
                        for (const p in fl) {
                            fl[p] = fixedLocationDetails[p];
                        }

                        //We won't the actual server update timestamp until the list is refreshed by some other action
                        //so rather than making another call we'll use a relative date.
                        fl.lastUpdatedDate = 'just now';
                    }

                    return fl;
                });

                //The update was successful, use the same data to update selectedFixedLocationDetails
                dispatch({
                    type: RECEIVE_FIXED_LOCATION_UPDATED,
                    fixedLocationDetails,
                    fixedLocationsWithUpdated
                });

                dispatch({
                    type: SET_PENDING_SNACKBAR_MSG,
                    msg: 'Updated successfully',
                    status: SnackbarMsgStatuses.SUCCESS
                });
            })
            .catch((err) => {
                dispatch({
                    type: RECEIVE_FIXED_LOCATION_UPDATED
                });

                let snackBarMessage;
                if (err && err.messages) {
                    snackBarMessage = err.messages;
                } else {
                    snackBarMessage = 'Update failed, please try again';
                }

                dispatch({
                    type: SET_PENDING_SNACKBAR_MSG,
                    msg: snackBarMessage,
                    status: SnackbarMsgStatuses.ERROR
                });
            });
    };
}

//Used to create a new fixed location
function createFixedLocation(fixedLocationDetails: IFixedLocationDetails) {
    return (dispatch) => {
        dispatch({ type: REQUEST_FIXED_LOCATION_CREATE });

        return LocationManagementApi
            .createFixedLocation(fixedLocationDetails)
            .then((json) => {
                //Exit CREATE mode and put them back on the first tab
                dispatch(changeDetailViewTab(TabNames.details));
                //updateFixedLocationDetailViewMode will clear FL details and the next RECEIVE_FIXED_LOCATION_CREATED
                //dispatch will rehydrate for us.
                dispatch(updateFixedLocationDetailViewMode(DetailViewModes.EDIT));

                //We use the passed details to update the state for selectedFixedLocationDetails. The
                //API does not return the newly created object.
                //NOTE: we are not adding to the fixedLocations state obj because we can't be sure
                //that this would be shown in the current sidebar. Depends on too many variables like
                //searchTerm, sortOrder, page, etc.
                dispatch({
                    type: RECEIVE_FIXED_LOCATION_CREATED,
                    fixedLocationDetails
                });

                dispatch({
                    type: SET_PENDING_SNACKBAR_MSG,
                    msg: 'Created successfully',
                    status: SnackbarMsgStatuses.SUCCESS
                });
            })
            .catch((err) => {
                // TODO: Figure out if we want to support more specific server based error messages
                dispatch({
                    type: RECEIVE_FIXED_LOCATION_CREATED,
                    fixedLocationDetails: null
                });

                let snackBarMessage;
                if (err && err.messages) {
                    snackBarMessage = err.messages;
                } else {
                    snackBarMessage = 'Create failed, please try again';
                }

                dispatch({
                    type: SET_PENDING_SNACKBAR_MSG,
                    msg: snackBarMessage,
                    status: SnackbarMsgStatuses.ERROR
                });
            });
    };
}

//Used to delete a fixed location
function deleteFixedLocation(fixedLocationCode: string, fixedLocationRequestParams: IFixedLocationsListRequestParams) {
    return (dispatch) => {
        dispatch({ type: REQUEST_FIXED_LOCATION_DELETE });

        return LocationManagementApi
            .deleteFixedLocation(fixedLocationCode)
            .then(dispatch({
                type: SET_PENDING_SNACKBAR_MSG,
                msg: 'Deleted successfully',
                status: SnackbarMsgStatuses.SUCCESS
            }))
            .then(dispatch(fetchFixedLocations(fixedLocationRequestParams)))
            .then(dispatch(clearFixedLocationDetails()))
            .catch((err) => {
                dispatch({
                    type: RECEIVE_FIXED_LOCATION_DELETED
                });

                dispatch({
                    type: SET_PENDING_SNACKBAR_MSG,
                    msg: 'Delete failed, please try again',
                    status: SnackbarMsgStatuses.ERROR
                });
            });
    };
}

function handleFixedLocationOperatingHoursStatusChangeAction(id: any, dayOfWeek: any, status: any) {
    return (dispatch) => {
        dispatch({
            type: HANDLE_FIXED_LOCATION_OPERATING_HOURS_STATUS_CHANGE,
            id,
            dayOfWeek,
            status
        });
    };
}

function handleFixedLocationOperatingHoursStartTimeChangeAction(id: any, startTime: any) {
    return (dispatch) => {
        dispatch({
            type: HANDLE_FIXED_LOCATION_OPERATING_HOURS_START_TIME_CHANGE,
            id,
            startTime
        });
    };
}

function handleFixedLocationOperatingHoursEndTimeChangeAction(id: any, endTime: any) {
    return (dispatch) => {
        dispatch({
            type: HANDLE_FIXED_LOCATION_OPERATING_HOURS_END_TIME_CHANGE,
            id,
            endTime
        });
    };
}

function deleteFixedLocationOperatingHoursAction(id: any) {
    return (dispatch) => {
        dispatch({
            type: DELETE_FIXED_LOCATION_OPERATING_HOURS_ROW,
            id
        });
    };
}

function addFixedLocationOperatingHoursAction(dayOfWeek: string) {
    return (dispatch) => {
        dispatch({
            type: ADD_FIXED_LOCATION_OPERATING_HOURS_ROW,
            dayOfWeek
        });
    };
}

//Used to toggle form dirtiness
function updateFormDirtyStatus(formName: string, isDirty: boolean) {
    return (dispatch) => {
        dispatch({
            type: SET_FORM_DIRTY_STATUS,
            formName,
            isDirty
        });
    };
}

//Marks all forms as clean
function resetAllFormDirtyStatuses() {
    return (dispatch) => {
        dispatch({
            type: RESET_FORM_DIRTY_STATUSES
        });
    };
}

//Used to toggle form dirtiness
function updateFixedLocationListActionsContainerIsExpanded(isExpanded: boolean) {
    return (dispatch) => {
        dispatch({
            type: SET_FIXED_LOCATION_ACTION_CONTAINER_IS_EXPANDED,
            isExpanded
        });
    };
}

//Intializes a new editable fixed location used by the create/edit forms
function initializeEditableFixedLocation(selectedFixedLocationDetails?: IFixedLocationDetails) {
    return (dispatch) => {
        dispatch({
            type: SET_EDITABLE_FIXED_LOCATION,
            editableFixedLocation: new FixedLocation(selectedFixedLocationDetails)
        });
    };
}

function destoryEditableFixedLocation() {
    return (dispatch) => {
        dispatch({
            type: DESTROY_EDITABLE_FIXED_LOCATION
        });
    };
}

//NOTE: shouldCreateAsWell allows the final update in the create flow to trigger the POST
function updateEditableFixedLocation(partialFixedLocationObj, shouldCreateAsWell: boolean) {
    return (dispatch, getState) => {
        const crtLocationManagementState = getState().locationManagement;
        //editableFixedLocation is an instance of FixedLocation. We could update directly,
        //but this would be against redux's immutability principles so we clone, update,
        //and replace.
        const updatedEditableLocation = cloneDeep(crtLocationManagementState.editableFixedLocation);
        updatedEditableLocation.partialPropUpdate(partialFixedLocationObj);

        dispatch({
            type: SET_EDITABLE_FIXED_LOCATION,
            editableFixedLocation: updatedEditableLocation
        });

        // removing any closed rows from the operating hours before calling the API
        if (updatedEditableLocation.operatingHours.length > 0) {
            updatedEditableLocation.operatingHours = updatedEditableLocation.operatingHours.filter((hour) => {
                return hour.status.value.toLowerCase() !== 'closed';
            });
        }

        //If in "edit" mode updateEditableFixedLocation also does updates to the API. When
        //in "create" mode we only update editableFixedLocation. A sep call will be made to
        //do the initial FL save/create once the create flow is complete.
        const fixedLocationDetails = updatedEditableLocation.toJSON();

        // if all rows are closed, meaning the operating hours are non-existent, send an empty array for the API to interpret correctly.
        if (fixedLocationDetails.operatingHours === null) {
            fixedLocationDetails.operatingHours = [];
        }

        if (crtLocationManagementState.detailViewMode === DetailViewModes.EDIT) {
            dispatch(updateFixedLocation(fixedLocationDetails));
        }

        //We do not test DetailViewModes.CREATE because each step comes through here. Only the
        //last step should create (the purpose of this flag).
        if (shouldCreateAsWell === true) {
            dispatch(createFixedLocation(fixedLocationDetails));
        }
    };
}

//Users can click the create button while in various states. This method gets them reset
//properly to being a new create.
function createModeReset() {
    return (dispatch) => {
        //Any time we are entering or reseting CREATE mode initialize a clean object
        dispatch(initializeEditableFixedLocation());

        dispatch({
            type: SET_CREATE_MODE_STEP,
            createModeCrtStepFormStates: CreateModeFormStatesByStep[1]
        });

        dispatch(changeDetailViewTab(TabNames.details));
    };
}

function createModeChangeStep(stepDirection: CreateModeStepDirections) {
    return (dispatch, getState) => {
        const crtCreateModeCrtStepFormStates = getState().locationManagement.createModeCrtStepFormStates;
        const crtStepNumber: number = crtCreateModeCrtStepFormStates.step;
        let nextStepNumer: number;
        let nextCreateModeCrtStepFormStates;

        if (stepDirection === CreateModeStepDirections.previous) {
            nextStepNumer = (crtStepNumber > 1) ? (crtStepNumber - 1) : 1;
        } else {
            nextStepNumer = crtStepNumber + 1;
        }

        nextCreateModeCrtStepFormStates = CreateModeFormStatesByStep[nextStepNumer];

        dispatch({
            type: SET_CREATE_MODE_STEP,
            createModeCrtStepFormStates: nextCreateModeCrtStepFormStates,
            createModeLatestStepDirection: stepDirection
        });
    };
}

//Used to toggle the active tab in the FL detail pane
function changeDetailViewTab(tabName: TabNames) {
    return (dispatch) => {
        dispatch({
            type: SET_DETAIL_VIEW_MODE_ACTIVE_TAB_NAME,
            detailViewActiveTabName: tabName
        });
    };
}

export {
    fetchFixedLocations,
    fetchFixedLocationDetails,
    updateFixedLocationDetailViewMode,
    updateFixedLocation,
    deleteFixedLocation,
    clearFixedLocationDetails,
    handleFixedLocationOperatingHoursStatusChangeAction,
    handleFixedLocationOperatingHoursStartTimeChangeAction,
    handleFixedLocationOperatingHoursEndTimeChangeAction,
    deleteFixedLocationOperatingHoursAction,
    addFixedLocationOperatingHoursAction,
    updateFormDirtyStatus,
    resetAllFormDirtyStatuses,
    updateFixedLocationListActionsContainerIsExpanded,
    initializeEditableFixedLocation,
    destoryEditableFixedLocation,
    updateEditableFixedLocation,
    createModeChangeStep,
    changeDetailViewTab,
    //Action Consts for Reducers
    REQUEST_FIXED_LOCATIONS,
    RECEIVE_FIXED_LOCATIONS,
    REQUEST_FIXED_LOCATION_DETAILS,
    RECEIVE_FIXED_LOCATION_DETAILS,
    SET_FIXED_LOCATION_DETAIL_VIEW_MODE,
    CLEAR_FIXED_LOCATION_DETAILS,
    REQUEST_FIXED_LOCATION_CREATE,
    RECEIVE_FIXED_LOCATION_CREATED,
    REQUEST_FIXED_LOCATION_UPDATE,
    RECEIVE_FIXED_LOCATION_UPDATED,
    REQUEST_FIXED_LOCATION_DELETE,
    RECEIVE_FIXED_LOCATION_DELETED,
    SET_PENDING_SNACKBAR_MSG,
    SET_FORM_DIRTY_STATUS,
    RESET_FORM_DIRTY_STATUSES,
    SET_FIXED_LOCATION_ACTION_CONTAINER_IS_EXPANDED,
    SET_EDITABLE_FIXED_LOCATION,
    DESTROY_EDITABLE_FIXED_LOCATION,
    SET_CREATE_MODE_STEP,
    SET_DETAIL_VIEW_MODE_ACTIVE_TAB_NAME,
    HANDLE_FIXED_LOCATION_OPERATING_HOURS_STATUS_CHANGE,
    HANDLE_FIXED_LOCATION_OPERATING_HOURS_START_TIME_CHANGE,
    HANDLE_FIXED_LOCATION_OPERATING_HOURS_END_TIME_CHANGE,
    DELETE_FIXED_LOCATION_OPERATING_HOURS_ROW,
    ADD_FIXED_LOCATION_OPERATING_HOURS_ROW
};
