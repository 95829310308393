import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Typography,
    Grid,
    IconButton,
    Button
} from '@material-ui/core';
import { StyleRules, createStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { addValidationRule } from 'formsy-react';

import { momentTime24HourWithLeadingZero } from '../../../custom_modules/momentHelper';
import SingleSelectInput from '../../nimble/inputs/singleSelectInput';
import TimePickerInput from '../../nimble/inputs/timePickerInput';

import {
    handleFixedLocationOperatingHoursStatusChangeAction,
    handleFixedLocationOperatingHoursStartTimeChangeAction,
    handleFixedLocationOperatingHoursEndTimeChangeAction,
    deleteFixedLocationOperatingHoursAction,
    addFixedLocationOperatingHoursAction
} from '../../../redux/actions/locationManagementActions';

import {
    FixedLocationOperatingHoursStatuses
} from '../../../metadata/locationManagementMetadata';
import {
    IFixedLocationOperatingHoursStatusOption
} from '../../../interfaces/locationManagementInterfaces';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface OperatingHoursRowProps {
    index: number;
    name: string;
    firstHours: boolean;
    subsequentHours: boolean;
    dayOfWeek: string;
    status: IFixedLocationOperatingHoursStatusOption;
    startTime: string;
    endTime: string;
    classes?: any;
    width?: Breakpoint;
    handleFixedLocationOperatingHoursStatusChangeAction: Function;
    handleFixedLocationOperatingHoursStartTimeChangeAction: Function;
    handleFixedLocationOperatingHoursEndTimeChangeAction: Function;
    deleteFixedLocationOperatingHoursAction: Function;
    addFixedLocationOperatingHoursAction: Function;
}

addValidationRule('isValidTime', (values, value) => {
    return /^([01]\d|2[0-3]):([0-5]\d)$/.test(value);
});

addValidationRule('isStartBeforeEnd', (values, value, parameters) => {
    const startTimeValue = value;

    for (const val in values) {
        if (val === `endTime_${parameters.day}_${parameters.index}`) {
            const endTimeValue = values[val];
            if (startTimeValue !== '' && endTimeValue !== '') {
                return startTimeValue < endTimeValue;
            }
            return true;
        }
    }
});

addValidationRule('isEndAfterStart', (values, value, parameters) => {
    const endTimeValue = value;

    for (const val in values) {
        if (val === `startTime_${parameters.day}_${parameters.index}`) {
            const startTimeValue = values[val];
            if (startTimeValue !== '' && endTimeValue !== '') {
                return endTimeValue > startTimeValue;
            }
            return true;
        }
    }
});

addValidationRule('isStartTimeOverlapping', (values, value, parameters) => {
    const inputStartTime = value;

    for (const valStart in values) {
        if (valStart !== `startTime_${parameters.day}_${parameters.index}`
        && valStart.startsWith(`startTime_${parameters.day}_`)) {
            const startTimeValue = values[valStart];

            for (const valEnd in values) {
                if (valEnd === `endTime_${parameters.day}_${valStart.split('_')[2]}`) {
                    const endTimeValue = values[valEnd];

                    if (inputStartTime !== '' && startTimeValue !== '' && endTimeValue !== ''
                    && inputStartTime >= startTimeValue && inputStartTime <= endTimeValue) {
                        return false;
                    }
                }
            }
        }
    }
    return true;
});

addValidationRule('isEndTimeOverlapping', (values, value, parameters) => {
    const inputEndTime = value;

    for (const valEnd in values) {
        if (valEnd !== `endTime_${parameters.day}_${parameters.index}`
        && valEnd.startsWith(`endTime_${parameters.day}_`)) {
            const endTimeValue = values[valEnd];

            for (const valStart in values) {
                if (valStart === `startTime_${parameters.day}_${valEnd.split('_')[2]}`) {
                    const startTimeValue = values[valStart];

                    if (inputEndTime !== '' && startTimeValue !== '' && endTimeValue !== ''
                    && inputEndTime >= startTimeValue && inputEndTime <= endTimeValue) {
                        return false;
                    }
                }
            }
        }
    }
    return true;
});

const styles = (theme): StyleRules => {
    return createStyles({
        dayOfWeekHeading: {
            fontSize: theme.typography.pxToRem(16),
            paddingTop: '20px'
        },
        deleteHoursButton: {
            color: theme.palette.primary.common.iconColor,
            padding: '6px'
        },
        addHoursButton: {
            padding: '4px'
        },

        deleteButtonWrapper: {
            textAlign: 'center'
        },
        addButtonWrapper: {
            textAlign: 'right'
        },

        [theme.breakpoints.only('xs')]: {
            deleteHoursButton: {
                marginTop: '12px'
            },

            timePickerWrapper: {
                maxWidth: '45%',
                flexBasis: '45%'
            },
            deleteButtonWrapper: {
                maxWidth: '8%',
                flexBasis: '8%'
            }
        },
        [theme.breakpoints.only('sm')]: {
            dayOfWeekHeading: {
                marginTop: '32px'
            },
            timePicker: {
                marginTop: '8px'
            },
            deleteHoursButton: {
                marginTop: '24px'
            },
            addHoursButton: {
                marginTop: '24px'
            },

            timePickerWrapper: {
                maxWidth: '35%',
                flexBasis: '35%'
            },
            deleteButtonWrapper: {
                maxWidth: '8%',
                flexBasis: '8%'
            },
            addButtonWrapper: {
                maxWidth: '22%',
                flexBasis: '22%'
            }
        },
        [theme.breakpoints.only('md')]: {
            deleteHoursButton: {
                marginTop: '12px'
            },

            timePickerWrapper: {
                maxWidth: '45%',
                flexBasis: '45%'
            },
            deleteButtonWrapper: {
                maxWidth: '8%',
                flexBasis: '8%'
            }
        },
        [theme.breakpoints.only('lg')]: {
            dayOfWeekHeading: {
                marginTop: '32px'
            },
            timePicker: {
                marginTop: '8px'
            },
            deleteHoursButton: {
                marginTop: '24px'
            },
            addHoursButton: {
                marginTop: '24px'
            },

            timePickerWrapper: {
                maxWidth: '35%',
                flexBasis: '35%'
            },
            deleteButtonWrapper: {
                maxWidth: '8%',
                flexBasis: '8%'
            },
            addButtonWrapper: {
                maxWidth: '22%',
                flexBasis: '22%'
            }
        },
        [theme.breakpoints.only('xl')]: {
            dayOfWeekHeading: {
                marginTop: '16px',
                marginBottom: '32px'
            },
            selectInput: {
                marginTop: '16px',
                marginBottom: '32px'
            },
            timePicker: {
                marginTop: '16px',
                marginBottom: '32px'
            },
            deleteHoursButton: {
                position: 'relative',
                right: '14px',
                top: '32px'
            },
            addHoursButton: {
                marginTop: '32px',
                marginBottom: '32px'
            },

            dayOfWeekWrapper: {
                maxWidth: '16.666667%',
                flexBasis: '16.666667%'
            },
            selectInputWrapper: {
                maxWidth: '27%',
                flexBasis: '27%'
            },
            timePickerWrapper: {
                maxWidth: '19%'
            },
            deleteButtonWrapper: {
                maxWidth: '3%',
                flexBasis: '3%'
            },
            addButtonWrapper: {
                maxWidth: '15%',
                flexBasis: '15%'
            }
        }
    });
};

class OperatingHoursRow extends React.Component<OperatingHoursRowProps, {}> {

    getFixedLocationOperatingHoursStatusSelectOptions = () => {
        let filteredFixedLocationOperatingHoursStatusSelectOptions;
        filteredFixedLocationOperatingHoursStatusSelectOptions = FixedLocationOperatingHoursStatuses.map((fixedLocationOperatingHoursStatus) => {
            return { value: fixedLocationOperatingHoursStatus.value, label: fixedLocationOperatingHoursStatus.label };
        });

        return filteredFixedLocationOperatingHoursStatusSelectOptions;
    }

    handleStatusChange = (selectedStatus) => {
        let newStartTime = '';
        let newEndTime = '';

        if (selectedStatus.value === '24') {
            newStartTime = '00:00:00';
            newEndTime = '23:59:00';
        }

        this.props.handleFixedLocationOperatingHoursStartTimeChangeAction(this.props.index, newStartTime);
        this.props.handleFixedLocationOperatingHoursEndTimeChangeAction(this.props.index, newEndTime);
        this.props.handleFixedLocationOperatingHoursStatusChangeAction(this.props.index, this.props.dayOfWeek, selectedStatus);
    }

    formatTime(time) {
        let timeValue = '';
        if (time.length >= 5 && time.indexOf(':') > -1) {
            const timeArr = time.split(':');
            timeValue = `${timeArr[0]}:${timeArr[1]}`;
        }

        return timeValue;
    }

    getNewTimeChangeValue = (time) => {
        if (typeof time === 'object' ) {
            const hh = this.addZero(time.getHours());
            const mm = this.addZero(time.getMinutes());
            time = `${hh}:${mm}`;
        }
        return time;
    }

    addZero(timePart: number): string {
        return (timePart < 10) ? `0${timePart}` : timePart.toString();
    }

    handleStartTimeChange = (newStartTime) => {
        newStartTime = this.getNewTimeChangeValue(newStartTime);

        if (newStartTime !== '' && newStartTime.length >= 5) {
            const momentStartTime = moment(newStartTime.toString(), momentTime24HourWithLeadingZero);
            if (moment(momentStartTime).isValid() === true) {
                const time = momentStartTime.format(momentTime24HourWithLeadingZero);
                this.props.handleFixedLocationOperatingHoursStartTimeChangeAction(this.props.index, time);
            }
        }
    }

    handleEndTimeChange = (newEndTime) => {
        newEndTime = this.getNewTimeChangeValue(newEndTime);

        if (newEndTime !== '' && newEndTime.length >= 5) {
            const momentEndTime = moment(newEndTime.toString(), momentTime24HourWithLeadingZero);
            if (moment(momentEndTime).isValid() === true) {
                const time = moment(momentEndTime).format(momentTime24HourWithLeadingZero);
                this.props.handleFixedLocationOperatingHoursEndTimeChangeAction(this.props.index, time);
            }
        }
    }

    render() {
        const { classes } = this.props;

        let isTimePickerDisabled;
        let isTimePickerVisible;
        let isAdditionalRowsDisabled;

        if (this.props.status.value === '24') {
            isTimePickerDisabled = true;
            isTimePickerVisible = true;
            isAdditionalRowsDisabled = true;
        } else if (this.props.status.value === 'Closed') {
            isTimePickerDisabled = true;
            isTimePickerVisible = false;
            isAdditionalRowsDisabled = true;
        } else if (this.props.status.value === 'Open') {
            isTimePickerDisabled = false;
            isTimePickerVisible = true;
            isAdditionalRowsDisabled = false;
            if (this.props.startTime === '' || this.props.endTime === '') {
                isAdditionalRowsDisabled = true;
            }
        }

        let dayOfWeekLabel;
        let dayOfWeekGrid;
        let statusSelect;
        let statusSelectGrid;
        let startTimePicker;
        let endTimePicker;
        let deleteRowButton;
        let addHoursButton;

        if (this.props.firstHours) {
            dayOfWeekLabel = (
                <Typography className={classes.dayOfWeekHeading}>
                    {this.props.dayOfWeek}
                </Typography>
            );
            statusSelect = (
                <SingleSelectInput
                    id={`status_${this.props.dayOfWeek}_${this.props.index}`}
                    name={`status_${this.props.dayOfWeek}_${this.props.index}`}
                    label='Status'
                    options={this.getFixedLocationOperatingHoursStatusSelectOptions()}
                    selectedOption={this.props.status}
                    onChange={this.handleStatusChange}
                    formControlClass={classes.selectInput}
                    required={true}
                />
            );
        }

        if (this.props.firstHours || (!this.props.firstHours && this.props.subsequentHours && isWidthUp('lg', this.props.width))) {
            dayOfWeekGrid = (
                <Grid
                    item={true}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={1}
                    className={classes.dayOfWeekWrapper}
                >
                    {dayOfWeekLabel}
                </Grid>
            );
            statusSelectGrid = (
                <Grid
                    item={true}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={3}
                    className={classes.selectInputWrapper}
                >
                    {statusSelect}
                </Grid>
            );
        }

        if (isTimePickerVisible) {
            startTimePicker = (
                <TimePickerInput
                    formControlClass={classes.timePicker}
                    label='Opens at'
                    name={`startTime_${this.props.dayOfWeek}_${this.props.index}`}
                    value={this.formatTime(this.props.startTime)}
                    onChange={this.handleStartTimeChange}
                    required={true}
                    disabled={isTimePickerDisabled}
                    validations={{
                        isLength: 5,
                        isValidTime: true,
                        isStartBeforeEnd: {day: this.props.dayOfWeek, index: this.props.index},
                        isStartTimeOverlapping: {day: this.props.dayOfWeek, index: this.props.index}
                    }}
                    validationErrors={{
                        isLength: 'Open Time must be 5 characters long',
                        isValidTime: 'Open Time must be a valid time',
                        isStartBeforeEnd: 'Open Time must be before Close Time',
                        isStartTimeOverlapping: 'Times should not overlap'
                    }}
                />
            );
            endTimePicker = (
                <TimePickerInput
                    formControlClass={classes.timePicker}
                    label='Closes at'
                    name={`endTime_${this.props.dayOfWeek}_${this.props.index}`}
                    value={this.formatTime(this.props.endTime)}
                    onChange={this.handleEndTimeChange}
                    required={true}
                    disabled={isTimePickerDisabled}
                    validations={{
                        isLength: 5,
                        isValidTime: true,
                        isEndAfterStart: {day: this.props.dayOfWeek, index: this.props.index},
                        isEndTimeOverlapping: {day: this.props.dayOfWeek, index: this.props.index}
                    }}
                    validationErrors={{
                        isLength: 'Close Time must be 5 characters long',
                        isValidTime: 'Close Time must be a valid time',
                        isEndAfterStart: 'Close Time must be after Open Time',
                        isEndTimeOverlapping: 'Times should not overlap'
                    }}
                />
            );
        }

        if (!this.props.firstHours && this.props.subsequentHours) {
            deleteRowButton = (
                <IconButton
                    color='inherit'
                    className={classes.deleteHoursButton}
                    onClick={() => this.props.deleteFixedLocationOperatingHoursAction(this.props.index)}
                >
                    <DeleteIcon />
                </IconButton>
            );
        }

        if (this.props.subsequentHours) {
            addHoursButton = (
                <Button
                    size='small'
                    color='primary'
                    className={classes.addHoursButton}
                    onClick={() => this.props.addFixedLocationOperatingHoursAction(this.props.dayOfWeek)}
                    disabled={isAdditionalRowsDisabled}
                >
                    ADD HOURS
                </Button>
            );
        }

        return (
            <Grid container={true} direction='row' spacing={16}>
                {dayOfWeekGrid}

                {statusSelectGrid}

                <Grid
                    item={true}
                    xs={4}
                    sm={3}
                    md={4}
                    lg={3}
                    xl={3}
                    className={classes.timePickerWrapper}
                >
                    {startTimePicker}
                </Grid>

                <Grid
                    item={true}
                    xs={4}
                    sm={3}
                    md={4}
                    lg={3}
                    xl={3}
                    className={classes.timePickerWrapper}
                >
                    {endTimePicker}
                </Grid>

                <Grid
                    item={true}
                    xs={4}
                    sm={3}
                    md={4}
                    lg={3}
                    xl={1}
                    className={classes.deleteButtonWrapper}
                >
                    {deleteRowButton}
                </Grid>

                <Grid
                    item={true}
                    xs={12}
                    sm={3}
                    md={12}
                    lg={3}
                    xl={1}
                    className={classes.addButtonWrapper}
                >
                    {addHoursButton}
                </Grid>
            </Grid>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        handleFixedLocationOperatingHoursStatusChangeAction,
        handleFixedLocationOperatingHoursStartTimeChangeAction,
        handleFixedLocationOperatingHoursEndTimeChangeAction,
        deleteFixedLocationOperatingHoursAction,
        addFixedLocationOperatingHoursAction
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(withWidth()(OperatingHoursRow)));
