import ProfileJwtAuthService from '../services/profileJwtAuthService';
import {
    Settings as SettingsIcon
} from '@material-ui/icons';
import {
    LogoutVariant as LogoutIcon,
    PackageVariantClosed as ShipmentsIcon,
    ViewList as ListViewIcon,
    Calendar as CalendarIcon,
    History as HistoryIcon,
    EmailOutline as NotificationsIcon,
    Domain as LocationsIcon,
    AccountCog as PreferencesIcon,
    DesktopMacDashboard as ChartIcon,
    HelpCircleOutline as FaqIcon,
    At as HelpIcon,
    Receipt as OrdersIcon,
    FormatListCheckbox as OrdersListActiveIcon
} from 'mdi-material-ui';

import { LocatorService, ServiceTypes } from '../services/locatorService';
import LocationManagementWrapper from '../components/locationManagement/locationManagementWrapper';
import { OrganizationsDataApi } from '../services/apiRequests';

const appConfig = {
    env: process.env.NODE_ENV,
    productKey: 'FreightPortal',
    authClientId: null,
    alkMapboxApiKey: 'A413EAA4767EC44E94A2360AE03B8689',
    authServiceProvider: null,
    userApplications: null,

    //These are initially set for development and will be dynamically overwritten by serviceLocator if env is production
    authHostname: 'https://develop.trimblevisibility.tech', // local = 'http://localhost'
    locationMgmtApiHostname: 'https://develop.locationmanagement.services.trimblevisibility.tech', // local = 'https://locations.frex.local'
    organizationsApiHostname: 'https://develop.organizations.services.trimblevisibility.tech', // local = 'https://organizations.frex.local'
    bearerToken: null,
    nav: null,
    initializationError: false,
    iconComponent: {
        ShipmentsOverview: ShipmentsIcon,
        ShipmentListActive: ListViewIcon,
        ShipmentsCalendar: CalendarIcon,
        ShipmentListHistory: HistoryIcon,
        Orders: OrdersIcon,
        OrdersListActive: OrdersListActiveIcon,
        CustomNotifications: NotificationsIcon,
        Locations: LocationsIcon,
        Preferences: PreferencesIcon,
        Dashboard: ChartIcon,
        Shipments: ShipmentsIcon,
        Settings: SettingsIcon,
        ContactSupport: HelpIcon,
        FAQs: FaqIcon,
        LogOut: LogoutIcon
    },
    component: {
        Locations: LocationManagementWrapper
    },

    getBearerToken(): Promise<any> {
        appConfig.authServiceProvider = new ProfileJwtAuthService(appConfig.authHostname, appConfig.productKey, appConfig.authClientId);
        return appConfig.authServiceProvider.getUserToken()
            .then((apiResponse: any) => apiResponse.json())
            .then((json: any) => {
                if (json && json.token && json.token !== '') {
                    appConfig.bearerToken = json.token;
                }
            })
            .catch((err) => {
                console.error('Failed to fetch Bearer token');
                appConfig.initializationError = true;
            });
    },

    getUserApplications(): Promise<any> {
        return OrganizationsDataApi.getUserApplications()
            .then((appData) => {
                if (appData !== null && appData.data.length > 0) {
                    appConfig.userApplications = appData.data[0];
                }
            })
            .catch((err) => {
                console.error('User applications failed to fetch');
                appConfig.initializationError = true;
            });
    },

    buildNavObj() {
        if (appConfig.userApplications !== null && appConfig.userApplications.userApplicationModels.length > 0) {
            let appRoutes = [];
            let appList = [];
            let moreAppList = [];
            let legacyAppList = [];

            appConfig.userApplications.userApplicationModels.forEach((app) => {
                if (app.isAllowed) {
                    //lookup imported icon from appConfig object
                    const iconComponent = appConfig.iconComponent[app.resourceKey] || null;

                    if ([
                        'ShipmentsOverview',
                        'ShipmentListActive',
                        'ShipmentsCalendar',
                        'ShipmentListHistory',
                        'Orders',
                        'OrdersListActive',
                        'CustomNotifications',
                        'Locations',
                        'Preferences',
                        'Dashboard'
                    ].includes(app.resourceKey) === true) {
                        // this is the list to create the react-router routes in the App.tsx
                        if (app.resourceKey === 'Locations') {
                            appRoutes.push({
                                id: app.resourceKey,
                                exact: true,
                                private: false,
                                label: app.resourceName,
                                showInMenu: app.isAllowed,
                                path: '/locations',
                                menuPath: app.menuPath,
                                component: LocationManagementWrapper
                            });
                        }
                        appList.push({
                            productKey: app.resourceKey,
                            label: app.resourceName,
                            path: app.resourcePath,
                            applicationResources: app.applicationResources,
                            iconComponent,
                            showInMenu: app.isAllowed
                        });
                    } else if (['Settings', 'ContactSupport', 'FAQs', 'LogOut'].includes(app.resourceKey) === true) {
                        moreAppList.push({
                            productKey: app.resourceKey,
                            label: app.resourceName,
                            path: app.resourcePath,
                            iconComponent,
                            showInMenu: app.isAllowed
                        });
                    } else if (['Shipments'].includes(app.resourceKey) === true) {
                        legacyAppList.push({
                            productKey: app.resourceKey,
                            label: app.resourceName,
                            path: app.resourcePath,
                            iconComponent,
                            showInMenu: app.isAllowed
                        });
                    }
                }
            });

            //build the obj for the available applications
            if (appList.length > 0) {
                appConfig.nav = {
                    appRoutes,
                    appNavLinks: {
                        Pages: appList,
                        ...(legacyAppList.length > 0) && { "Legacy Pages": legacyAppList },
                        ...(moreAppList.length > 0) && { More: moreAppList }
                    }
                };
            } else {
                console.error('No active apps found');
                appConfig.initializationError = true;
            }
        } else {
            console.error('Failed to fetch navigation config');
            appConfig.initializationError = true;
        }
    },

    async initialize() {

        let authPromise = null;
        let locationMgmtApiPromise = null;
        let organizationsApiPromise = null;

        if (appConfig.env !== 'development') {
            const locator = new LocatorService();

            authPromise = locator.getServiceUrl(ServiceTypes.AuthService)
                .then((resourceUrl: string) => appConfig.authHostname = resourceUrl);

            locationMgmtApiPromise = locator.getServiceUrl(ServiceTypes.LocationManagementApi)
                .then((resourceUrl: string) => appConfig.locationMgmtApiHostname = resourceUrl);

            organizationsApiPromise = locator.getServiceUrl(ServiceTypes.OrganizationsApi)
                .then((resourceUrl: string) => appConfig.organizationsApiHostname = resourceUrl);

        } else {
            authPromise = Promise.resolve(appConfig.authHostname);
            locationMgmtApiPromise = Promise.resolve(appConfig.locationMgmtApiHostname);
            organizationsApiPromise = Promise.resolve(appConfig.organizationsApiHostname);
        }
        //Call the services required to bootstrap the app
        //First we need to get the data and endpts from the locatorService
        await Promise.all([authPromise, locationMgmtApiPromise, organizationsApiPromise]);
        await this.getBearerToken();
        await this.getUserApplications();
        await this.buildNavObj();
    }
};

export default appConfig;
