const defaultServiceLocatorUrl = 'https://services.10-4.com';

enum ServiceTypes {
    DashboardsApi = 'DashboardsApi',
    AuthService = 'AuthService',
    LocationManagementApi = 'LocationManagementApi',
    FreightPortalAdminApi = 'FreightPortalAdminApi',
    DataViewApi = 'DataViewApi',
    MasterData = 'MasterData',
    OrganizationsApi = 'OrganizationsApi',
    CarrierPortalApi = 'CarrierPortalApi',
    DataSharingApi = 'DataSharingApi',
    CarrierPortalAuthDirectory = 'CarrierPortalAuthDirectory',
    ShipmentsApi = 'ShipmentsApi',
    TransportationUI = 'TransportationUI'
}

enum ContentTypes {
    JSON = 'application/json' as any
}

interface Resource {
    resourceType: ServiceTypes;
    resourceUrl: string;
}

class LocatorService {
    constructor(serviceLocatorUrl?: string) {
        this.serviceLocatorUrl = serviceLocatorUrl || defaultServiceLocatorUrl;
    }

    serviceLocatorUrl: string;

    getServiceUrl(serviceType: ServiceTypes): Promise<any> {
        const locatorUri = `${this.serviceLocatorUrl}/${serviceType}`;

        const requestParams: any = {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: ContentTypes.JSON
            },
            credentials: 'same-origin'
        };

        return fetch(locatorUri, requestParams)
                .then((apiResponse: Response) => apiResponse.json())
                .then((resource: Resource) => resource.resourceUrl);
    }
}

export {
    ServiceTypes, LocatorService
};
