import React from 'react';
import {
    IconButton,
    FormControl,
    InputAdornment,
    TextField
} from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import { Search, Close } from '@material-ui/icons';
import { isUndefined } from 'lodash';

interface ISearchInputProps {
    /**
     * The input name
     */
    name: string;
    /**
     * The input label
     */
    label: string;
    /**
     * The current search term (used to initialize the input). Passing a value does
     * not trigger a search, it simply sets the value of the input.
     */
    value: string;
    /**
     * A callback function called when the user hits enter or clicks the search icon
     */
    onRequestSearch: Function;
    /**
     * A callback function that can be used to notify the wrapper component that
     * the value has changes
     */
    onChange?: Function;
    /**
     * A callback function that can be used to notify the wrapper component that
     * the this input has received focus.
     */
    onFocus?: Function;
    /**
     * A callback function that can be used to notify the wrapper component that
     * the this input has lost focus.
     */
    onBlur?: Function;
    /**
     * A callback function that can be used to notify the wrapper component that
     * the this input's search term has been cleared.
     */
    onClear?: Function;
    /**
     * Any extra classes that need to be applied to the FormControl
     */
    formControlClass?: string;
    /**
     * Any extra classes that need to be applied to the TextField which is wrapped inside the FormControl
     */
    textfieldClass?: string;
    //Props below here are automatically injected by MUI & Formsy
    disabled?: boolean;
    placeholder?: string;
    changeValue?: Function;
    setValue?: Function;
    getValue?: Function;
    isPristine?: Function;
    classes?: any;
}

interface ISearchInputState {
    focus: boolean;
    showSearchIcon: boolean;
}

/**
 * This input is a general purpose search input styled to our standards. It toggles
 * icons and border colors as need to indicate search readiness.
 */
class SearchInput extends React.Component<ISearchInputProps, ISearchInputState> {
    constructor(props: ISearchInputProps) {
        super(props);

        this.state = {
            focus: false,
            showSearchIcon: false
        };
    }

    componentDidMount() {
        if (this.props.getValue() === '') {
            this.setShowSearchIcon(true);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.getValue() === '') {
            this.setShowSearchIcon(true);
        } else if (this.props.getValue() === nextProps.value) {
            this.setShowSearchIcon(false);
        }
    }

    setShowSearchIcon(showSearchIcon: boolean) {
        this.setState({ showSearchIcon });
    }

    /**
     * Called when the input receives focus
     */
    handleFocus = () => {
        this.setState({ focus: true });
    }

    /**
     * Called when the input loses focus
     */
    handleBlur = () => {
        this.setState({ focus: false });
    }

    /**
     * Called when the input receives focus
     */
    handleClear = () => {
        this.props.setValue('');
        this.props.onRequestSearch('');
        this.setShowSearchIcon(true);
    }

    /**
     * Called when the user clicks the search icon or hits enter
     */
    handleSearchRequest = () => {
        this.setShowSearchIcon(false);
        this.props.onRequestSearch(this.props.getValue());
    }

    /**
     * Called when the input receives a keydown event. Used to trigger
     * search from the enter key.
     */
    handleKeyPressed = (event) => {
        if (event.keyCode === 13) {
            this.handleSearchRequest();
        }
    }

    /**
     * Called when the input value is changed
     */
    handleChangeValue = (event) => {
        this.setShowSearchIcon(true);
        const newValue = event.currentTarget.value;
        this.props.setValue(newValue);

        if (!isUndefined(this.props.onChange)) {
            this.props.onChange(newValue);
        }
    }

    /**
     * Performs the proper click action based on current state
     */
    handleSearchInputAdornmentClick = () => {
        if (this.state.showSearchIcon === true) {
            this.handleSearchRequest();
        } else {
            this.handleClear();
        }
    }

    /**
     * Renders the search icon or the close icon with appropriate click handler
     * based on the current search term state.
     */
    renderSearchIcon() {
        return (
            <InputAdornment position='end'>
                <IconButton
                    disabled={!isUndefined(this.props.disabled) ? this.props.disabled : false}
                    onClick={this.handleSearchInputAdornmentClick}
                >
                    {this.state.showSearchIcon ? <Search /> : <Close />}
                </IconButton>
            </InputAdornment>
        );
    }

    render() {
        return (
            <FormControl
                className={this.props.formControlClass || null}
                disabled={!isUndefined(this.props.disabled) ? this.props.disabled : false}
                //fullWidth={true}
                //fullWidth ends up being slightly longer than normal when an endAdornment is given so
                //pull it back a touch here.
                style={{ width: 'calc(100% - 2px)', display: 'flex' }} //display: flex needed for ie11
            >
                <TextField
                    className={this.props.textfieldClass || null}
                    label={this.props.label}
                    name={this.props.name}
                    autoComplete='off'
                    disabled={!isUndefined(this.props.disabled) ? this.props.disabled : false}
                    onKeyDown={this.handleKeyPressed}
                    onChange={this.handleChangeValue}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    //Often we get NULLs back from the db and try to restore them into form fields,
                    //but the TextField control does not support null so convert if needed.
                    value={(this.props.value === null) ? '' : this.props.getValue()}
                    placeholder={!isUndefined(this.props.placeholder) ? this.props.placeholder : ''}
                    fullWidth={true}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: this.renderSearchIcon()
                    }}
                    InputLabelProps={{
                        disableAnimation: true,
                        shrink: true
                    }}
                />
            </FormControl>
        );
    }
}

export default withFormsy(SearchInput);
