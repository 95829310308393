import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide
} from '@material-ui/core';

function Transition(props) {
    return <Slide direction='up' {...props} />;
}

interface DeleteDialogProps {
    dialogIsOpen: boolean;
    onCancelDelete: Function;
    onConfirmDelete: Function;
}

const DeleteDialog = (props: DeleteDialogProps): JSX.Element => {
    return (
        <Dialog
            open={props.dialogIsOpen}
            TransitionComponent={Transition}
            onClose={() => { props.onCancelDelete(); }}
            className='deleteDialog--container--QA'
        >
            <DialogTitle>Delete Fixed Location?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span style={{ marginTop: 16, display: 'inline-block' }}>Are you sure you want to delete this fixed location?</span>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { props.onCancelDelete(); }} className='action--cancel--QA'>Cancel</Button>
                <Button onClick={() => { props.onConfirmDelete(); }} color='secondary' className='action--confirm--QA'>Delete</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
